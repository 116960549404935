import React from 'react';
import PropTypes from 'prop-types';
import { LoadingContent } from 'components';
import Check from '../permission/Check';

export const TeamListing = ({ users, roleList, toggleEdit, togglePrompt }) => {
  const renderTableData = () => {
    const userListing = users.map(user => {
      const {
        id,
        name,
        phoneNumber,
        email,
        roleId,
        isDeactivated,
        userType
      } = user;

      return (
        <tr className={isDeactivated ? 'disabled' : 'enabled'} key={id}>
          <td>{name}</td>
          <td>{email}</td>
          <td>{phoneNumber}</td>
          <td>{roleList[roleId]}</td>
          <td>
            <Check permission="deactivate-staff">
              <button
                className="button button-transparent button-icon text-capitalize underline"
                data-user={id}
                data-deactivated={isDeactivated}
                data-usertype={userType}
                data-name={name}
                onClick={togglePrompt}
              >
                {isDeactivated ? 'Activate' : 'Deactivate'}
              </button>
            </Check>
          </td>
          <td>
            <Check permission="edit-staff">
              <button
                className="button button-transparent button-icon"
                data-user={id}
                onClick={toggleEdit}
                disabled={isDeactivated}
                title="Edit"
              >
                <i className="iconmoon icon-edit"></i>
              </button>
            </Check>
          </td>
        </tr>
      );
    });

    return userListing;
  };

  return (
    <div className="section table-responsive">
      <table className="table table-borderless">
        <thead className="table-header">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email Address</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Role</th>
            <th scope="col">Action</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody className="table-content">
          {users && users.length > 0 ? (
            renderTableData()
          ) : (
            <tr>
              <td colSpan="6">
                {users === undefined ? <LoadingContent /> : 'No users'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

TeamListing.propTypes = {
  toggleEdit: PropTypes.func.isRequired,
  togglePrompt: PropTypes.func.isRequired,
  users: PropTypes.array,
  roleList: PropTypes.object
};
