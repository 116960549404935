import axios from 'axios';
import { residentsConstants } from 'store/constants';
import { toast } from 'sonner';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const exportResident = `${uri}/residents/`;

export const getResidentsSuccess = (payload) => ({
  type: residentsConstants.GET_RESIDENTS_SUCCESS,
  payload
});

export const getResidentsFailure = (error) => ({
  type: residentsConstants.GET_RESIDENTS_FAILURE,
  payload: error
});

export const getResidents = (estateId) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .get(`/estates/${estateId}/residents`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getResidentsSuccess(response.data.data));
        dispatch(endApiCall());
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getResidentsFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const editResidentSuccess = (users) => ({
  type: residentsConstants.EDIT_RESIDENT_SUCCESS,
  payload: users
});

export const editResidentFailure = (error) => ({
  type: residentsConstants.EDIT_RESIDENT_FAILURE,
  payload: error
});

export const editResident = (resident, userId) => (dispatch) => {
  dispatch(beginApiCall());

  axios
    .patch(`/users/edit/${userId}?type=${resident.userType}`, resident)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(editResidentSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editResidentFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const reassignResident = (options) => (dispatch) => {
  const url = `?type=${options.userType}`;
  const reassign = {
    oldMeterNo: options.meterNo,
    newMeterNo: options.newMeterNo
  };

  dispatch(beginApiCall());
  axios
    .patch(`/users/edit/${options.id}${url}`, reassign)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(editResidentSuccess(response.data));

        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editResidentFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const changeStatusSuccess = (users) => ({
  type: residentsConstants.RESIDENT_STATUS_SUCCESS,
  payload: users
});

export const changeStatusFailure = (error) => ({
  type: residentsConstants.RESIDENT_STATUS_FAILURE,
  payload: error
});

export const changeStatus = (action, entity) => (dispatch) => {
  const event = action.isDeactivated ? `deactivated` : `activated`;

  axios
    .patch(
      `/resident/action/${entity.residentId}?meterNo=${entity.meter}&action=${event}`
    )
    .then((response) => {
      if (response.data.status) {
        if (Object.keys(action)[0] === 'isDeactivated') {
          const status = action.isDeactivated ? 'deactivated' : 'activated';
          toast.success(
            `Success : ${entity.name} has been ${status} successfully`
          );
        } else {
          toast.success(`Success : ${response.data.message}`);
        }

        dispatch(editResidentSuccess(response.data));
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editResidentFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const uploadResidentSuccess = (users) => ({
  type: residentsConstants.UPLOAD_RESIDENT_SUCCESS,
  payload: users
});

export const uploadResidentFailure = (error) => ({
  type: residentsConstants.UPLOAD_RESIDENT_FAILURE,
  payload: error
});

export const uploadResident = (file, estateId) => (dispatch) => {
  const formData = new FormData();
  formData.append('sheet', file);

  const postConfig = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  axios
    .post(`/users/import?type=customer&eid=${estateId}`, formData, postConfig)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(uploadResidentSuccess(response.data));
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(uploadResidentFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const removeResidentsSuccess = (payload) => ({
  type: residentsConstants.REMOVE_RESIDENTS_SUCCESS,
  payload
});

export const removeResidents = () => (dispatch) => {
  dispatch(removeResidentsSuccess({}));
};
