import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { isObjectEmpty, validateUser } from 'utility';
import {
  useCreateUserMutation,
  useEditResidentMutation,
  useGetResidentsQuery
} from 'features/api';
import { PhoneNumberInput } from 'components/PhoneNumberInput';
import { consume } from 'permission/Check';

export const SaveResident = ({ ...props }) => {
  const dispatch = useDispatch();
  const initialResident = {
    name: '',
    email: '',
    phoneNumber: '',
    userType: 'customer',
    meterNo: '',
    newMeterNo: '',
    estateId: ''
  };

  const { close, entityId, isEdit, entity } = props;
  const { userId, meter, estateId } = entity;
  const { data } = useGetResidentsQuery(estateId, {
    skip: !consume('view-resident')
  });

  const estateDetails = data || [];
  const residents = estateDetails.residents || [];

  const residentEdit = () => {
    if (userId !== 0 && !Number.isNaN(userId)) {
      const residentInfo =
        residents.find(
          (user) => user.id === userId && user.meterNo === meter
        ) || {};

      return {
        ...initialResident,
        ...residentInfo,
        oldMeterNo: residentInfo.meterNo,
        newMeterNo: residentInfo.isMeterActive ? '' : residentInfo.meterNo,
        estateId: entityId
      };
    }
  };

  const residentDetails = userId === 0 ? initialResident : residentEdit;

  const [resident, setResident] = useState(residentDetails || initialResident);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createUser, { isLoading: isCreating, isSuccess }] =
    useCreateUserMutation();

  const [editResident, { isLoading: isEditing, isSuccess: isEdited }] =
    useEditResidentMutation();
  const saveResident = useCallback(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const {
        newMeterNo,
        isDeactivated,
        isMeterActive,
        oldMeterNo,
        meterName,
        meterNo,
        id,
        ...rest
      } = resident;

      if (isEdit) {
        editResident(rest, id);

        return;
      }

      delete resident.newMeterNo;
      createUser(resident);
    }
  }, [isEdit, errors, dispatch, isSubmitting, resident]);

  useEffect(() => {
    saveResident();
    return () => {
      setIsSubmitting(false);
    };
  }, [saveResident]);

  useEffect(() => {
    if (isSuccess || isEdited) {
      close();
    }
  }, [isSuccess, isEdited]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateUser(resident, isEdit);
    setErrors(errors);
    const isEmpty = isObjectEmpty(errors);
    if (isEmpty) {
      setIsSubmitting(true);
    }
  };

  const handleChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;

    setResident((prevResident) => ({
      ...prevResident,
      [name]: value,
      estateId: entityId
    }));
  };

  const handlePhone = (value) => {
    setResident((prevResident) => ({
      ...prevResident,
      phoneNumber: value,
      estateId: entityId
    }));
  };

  const handleCancel = (event) => {
    setResident(initialResident);
    close(event);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h4 className="heading header-title">
            {isEdit ? 'Edit Resident' : 'Add Resident'}
          </h4>
        </div>
        <div className="form__content row gap-3">
          <div className="form-group">
            <label>
              Name <span className="form-error">{errors.name}</span>
            </label>
            <input
              type="text"
              name="name"
              value={resident.name}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Email <span className="form-error">{errors.email}</span>
            </label>
            <input
              type="email"
              name="email"
              value={resident.email}
              className="form-control"
              onChange={handleChange}
              required
              // disabled={isEdit}
            />
          </div>

          <div className="form-group">
            <label>
              Phone Number{' '}
              <span className="form-error">{errors.phoneNumber}</span>
            </label>
            <PhoneNumberInput
              placeholder="7045637890"
              value={resident.phoneNumber}
              onChange={handlePhone}
            />
          </div>
          <div className="form-group">
            <label>
              Meter Number <span className="form-error">{errors.meterNo}</span>
            </label>
            <input
              type="text"
              name={'meterNo'}
              value={resident.meterNo}
              className="form-control"
              onChange={handleChange}
              required
              disabled={isEdit}
            />
          </div>
        </div>

        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group "
              role="group"
              aria-label="Button group example"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="button button-save ms-2"
                disabled={isCreating || isEditing}
              >
                {(isCreating || isEditing) && (
                  <Spinner className="me-3" color="light" />
                )}{' '}
                {isEdit ? 'Save Changes' : 'Save Resident'}
                {/* {!resident.isMeterActive ? 'Reactivate Resident' : 'Save'} */}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

SaveResident.propTypes = {
  close: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  entity: PropTypes.object.isRequired
};
