import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'features/baseQuery';
import { toast } from 'sonner';
import environment from 'environment';
import axios from 'axios';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const exportUsers = `${uri}/users/data/export?token=${localStorage.token}`;

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery,
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (userDetails) => ({
        url: '/users',
        method: 'POST',
        body: userDetails
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ['User', 'Residents']
    }),
    getUsers: builder.query({
      query: (userType) => `/users?role=${userType}`,
      transformResponse: (response) => response.data,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'User', id })), 'User']
          : ['User']
    }),
    getUser: builder.query({
      query: (userId) => `/users/${userId}`,
      providesTags: ['User']
    }),
    editUser: builder.mutation({
      query: ({ editOptions, editDetails }) => {
        // eslint-disable-next-line no-unused-vars
        const { meterNo, isMeterActive, ...meter } = editOptions;
        const meterEdit = { ...meter, newMeterNo: meter.meterNo };
        const editUrl =
          editDetails.userType === 'customer'
            ? editDetails.status
              ? `?type=${editDetails.userType}&meterNo=${editDetails.meterNo}`
              : `?type=${editDetails.userType}`
            : '';
        return {
          url: `/users/edit/${editDetails.userId}${editUrl}`,
          method: 'PATCH',
          body: meterEdit
        };
      },
      async onQueryStarted({ editDetails, editOptions }, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          if (Object.keys(editOptions)[0] === 'isDeactivated') {
            const status = editOptions.isDeactivated
              ? 'deactivated'
              : 'activated';
            toast.success(
              `Success : ${editDetails.name}  has been ${status} successfully`
            );
          } else {
            toast.success(`Success : ${response.data.message}`);
          }
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'User', id: arg.id }]
    }),
    uploadUsers: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append('sheet', file);
        return {
          url: '/users/import/',
          method: 'POST',
          body: formData
        };
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ['User']
    }),
    getUserByMeterId: builder.query({
      query: (meterNumber) => `/users/?meterNo=${meterNumber}`,
      transformResponse: (response) => response.data,
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      providesTags: ['User']
    })
  })
});

export const {
  useCreateUserMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useEditUserMutation,
  useUploadUsersMutation,
  useGetUserByMeterIdQuery
} = usersApi;
