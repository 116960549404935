import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Loader } from 'components';
import { useSelector } from 'react-redux';
import UseRoutes from '../hooks/UseRoutes';

const NoAuthRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const { routes } = UseRoutes();
  const { loggedIn } = useSelector(state => state.auth);

  if (loggedIn) {
    if (routes.length > 0) {
      return <Navigate to={`/app${routes[0].url}`} />;
    }
  }

  return (
    <Suspense fallback={<Loader show />}>
      <Layout>
        <Component {...rest} />
      </Layout>
    </Suspense>
  );
};

NoAuthRoute.propTypes = {
  component: PropTypes.object.isRequired,
  layout: PropTypes.func.isRequired,
  location: PropTypes.object
};

export default NoAuthRoute;
