import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  editAlias,
  fetchSubscriptionByMeterNo,
  resetPlans,
  resetSubscriptions
} from 'store/actions';
import { validateMeter } from 'utility';
import { Subscriptions } from './Subscriptions';

export const SaveAlias = ({ ...props }) => {
  const dispatch = useDispatch();
  const { close, entityId = '' } = props;

  const inProgress = useSelector((state) => state.apiCalls);
  const userId = useSelector((state) => state.profile.id);
  const selectedMeter = useSelector((state) =>
    state.profile.meters.find((meter) => meter.meterNo === entityId)
  );

  const dispatchSubscription = useCallback(() => {
    dispatch(fetchSubscriptionByMeterNo(entityId));
  }, [dispatch, entityId]);

  useEffect(() => {
    dispatchSubscription();
  }, [dispatchSubscription]);

  const meterDefault = {
    meterName: '',
    meterNo: '',
    userId: ''
  };

  const meterDetails = entityId ? selectedMeter : meterDefault;

  const [meter, setMeterAlias] = useState(meterDetails);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  // TODO
  const saveAlias = useCallback(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      dispatch(editAlias(meter));
    }
  }, [errors, dispatch, isSubmitting, meter]);

  useEffect(() => {
    saveAlias();
    return () => {
      setIsSubmitting(false);
    };
  }, [saveAlias]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validateMeter(meter));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setMeterAlias((prevAlias) => ({
      ...prevAlias,
      [name]: value,
      userId
    }));
  };

  const handleCancel = (event) => {
    setMeterAlias(meterDefault);
    dispatch(resetPlans());
    dispatch(resetSubscriptions());
    close(event);
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__header">
        <h4 className="heading header-title">Edit Meter name</h4>
        <p>Fill to customize your meter name</p>
      </div>
      <div className="form__content row gap-3">
        <div className="form-group">
          <label>
            Meter Number <span className="form-error">{errors.meterNo}</span>
          </label>
          <input
            type="text"
            name="meterNo"
            value={meter.meterNo}
            className="form-control"
            disabled
            required
          />
        </div>
        <div className="form-group">
          <label>
            Meter Name <span className="form-error">{errors.meterName}</span>
          </label>
          <input
            type="text"
            name="meterName"
            value={meter.meterName}
            className="form-control"
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <hr />

      <div className="form__content">
        <Subscriptions />
      </div>

      <div className="form__footer">
        <div className="form__footer-buttons d-flex justify-content-end">
          <div
            className="btn-group "
            role="group"
            aria-label="Button group example"
          >
            <button
              type="button"
              className="button button-primary button--outline"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="button button-save ms-2"
              onClick={handleSubmit}
              disabled={inProgress}
            >
              {inProgress && <Spinner className="me-3" color="light" />} Save
              Alias
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

SaveAlias.propTypes = {
  close: PropTypes.func.isRequired,
  entity: PropTypes.array,
  entityId: PropTypes.string
};
