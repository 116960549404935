import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { toast } from 'sonner';
import {
  authConstants,
  profileConstants,
  roleConstants
} from 'store/constants';
import { setAuthHeader } from 'utility';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const getRolesSuccess = (roles) => ({
  type: roleConstants.GET_ROLES_SUCCESS,
  payload: roles
});

export const getRolesFailure = (error) => ({
  type: roleConstants.GET_ROLES_FAILURE,
  payload: error
});

export const getProfileSuccess = (details) => ({
  type: profileConstants.GET_PROFILE_SUCCESS,
  payload: details
});

export const getProfileFailure = (error) => ({
  type: profileConstants.GET_PROFILE_FAILURE,
  payload: error
});

export const loginSuccess = (details) => ({
  type: authConstants.LOGIN_SUCCESS,
  payload: details
});

export const loginFailure = (error) => ({
  type: authConstants.LOGIN_FAILURE,
  payload: error
});

export const loginUser = (userDetails) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .post('/login', userDetails)
    .then((response) => {
      const { data } = response.data;
      localStorage.setItem('token', data.token);
      setAuthHeader(data.token);
      if (response.data.status) {
        axios
          .all([
            axios.get(`/users/${response.data.data.id}`),
            axios.get(`/roles`)
          ])
          .then((responses) => {
            const [profile, role] = responses;
            if (profile.data.status) {
              dispatch(getProfileSuccess(profile.data));
            }
            if (role.data.status) {
              dispatch(getRolesSuccess(role.data));
            }
          });
        toast.success(`Success : ${response.data.message}`);
        dispatch(loginSuccess(response.data));
        dispatch(endApiCall());
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(loginFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const resetRequestSuccess = (details) => ({
  type: authConstants.RESET_REQUEST_SUCCESS,
  payload: details
});

export const resetRequestFailure = (error) => ({
  type: authConstants.RESET_REQUEST_FAILURE,
  payload: error
});

export const resetRequest = (email, navigate) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .post('/users/forgot', email)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(resetRequestSuccess(response.data));
        dispatch(endApiCall());
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(resetRequestFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const setPasswordSuccess = (details) => ({
  type: authConstants.SET_PASSWORD_SUCCESS,
  payload: details
});

export const setPasswordFailure = (error) => ({
  type: authConstants.SET_PASSWORD_FAILURE,
  payload: error
});

export const setPassword = (password, token, navigate) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .patch(`/users/set-password/${token}`, password)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(setPasswordSuccess(response.data));
        dispatch(endApiCall());
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(setPasswordFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const resetPasswordSuccess = (details) => ({
  type: authConstants.RESET_PASSWORD_SUCCESS,
  payload: details
});

export const resetPasswordFailure = (error) => ({
  type: authConstants.RESET_PASSWORD_FAILURE,
  payload: error
});

export const resetPassword = (password, token, navigate) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .patch(`/users/reset/${token}`, password)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(resetPasswordSuccess(response.data));
        dispatch(endApiCall());
        setTimeout(() => {
          navigate('/login');
        }, 5000);
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(resetPasswordFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const changePasswordSuccess = (details) => ({
  type: authConstants.CHANGE_PASSWORD_SUCCESS,
  payload: details
});

export const changePasswordFailure = (error) => ({
  type: authConstants.CHANGE_PASSWORD_FAILURE,
  payload: error
});

export const changePassword = (credentials, navigate) => (dispatch) => {
  const user = jwtDecode(localStorage.token);
  dispatch(beginApiCall());
  axios
    .patch(`/users/password/${user.id}`, credentials)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(changePasswordSuccess(response.data));
        dispatch(endApiCall());

        localStorage.removeItem('token');
        localStorage.removeItem('persist:auth');

        navigate('/login');
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(changePasswordFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const logoutSuccess = (payload) => ({
  type: authConstants.LOGOUT_SUCCESS,
  payload
});

export const logoutFailure = () => ({
  type: authConstants.LOGOUT_FAILURE,
  payload: false
});

export const logoutUser = () => (dispatch) => {
  localStorage.clear();
  const token = localStorage.getItem('token');
  const persist = localStorage.getItem('persist:auth');

  if (!token && !persist) {
    const logoutStatus = {
      message: 'User Successfully Logged out',
      token: null
    };
    toast.success(`Success : ${logoutStatus.message}`);
    dispatch(logoutSuccess(logoutStatus));
    window.location.reload();
  } else {
    dispatch(logoutFailure());
  }
};
