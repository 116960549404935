import React from 'react';
import PropTypes from 'prop-types';

export const NoContent = ({ icon, title, caption }) => {
  return (
    <>
      <div className="no-content text-center">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-md-4 col-sm-12">
            <div className="text-center">
              <div className="no-content__content-icon d-flex justify-content-center align-items-center">
                <i className={`iconmoon icon-${icon}`}></i>
              </div>
            </div>
          </div>
        </div>
        <h4 className="heading">{title}</h4>
        <p className="paragraph onboarding__text">{caption}</p>
      </div>
    </>
  );
};

NoContent.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  caption: PropTypes.string
};
