import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environment from '../../environment';

const {
  payment: { seckey }
} = environment;

const baseQuery = fetchBaseQuery({ baseUrl: 'https://api.paystack.co' });

export const paystackApi = createApi({
  reducerPath: 'paystackApi',
  baseQuery,
  tagTypes: ['Paystack'],
  endpoints: builder => ({
    createPlan: builder.mutation({
      query: plan => ({
        url: '/plan',
        method: 'POST',
        body: plan,
        headers: {
          Authorization: `Bearer ${seckey}`
        }
      }),
      invalidatesTags: ['Paystack']
    }),
    fetchPlans: builder.query({
      query: () => '/plan',
      providesTags: ['Paystack']
    }),
    initializePayment: builder.mutation({
      query: payment => ({
        url: '/transaction/initialize',
        method: 'POST',
        body: payment,
        headers: {
          Authorization: `Bearer ${seckey}`
        }
      })
    }),
    fetchPlanByCustomer: builder.query({
      query: planCode => `/plan/${planCode}`,
      providesTags: ['Paystack']
    }),
    cancelSubscription: builder.mutation({
      query: (subscriptionId, subscription) => ({
        url: `/subscriptions/${subscriptionId}/cancel`,
        method: 'POST',
        body: subscription,
        headers: {
          Authorization: `Bearer ${seckey}`
        }
      })
    })
  })
});

export const {
  useCreatePlanMutation,
  useFetchPlansQuery,
  useInitializePaymentMutation,
  useFetchPlanByCustomerQuery,
  useCancelSubscriptionMutation
} = paystackApi;
