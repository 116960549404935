import axios from 'axios';
import { estateConstants } from 'store/constants';
import { toast } from 'sonner';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const exportEstates = `${uri}/estates/export`;

export const getEstateSuccess = (roles) => ({
  type: estateConstants.GET_ESTATE_SUCCESS,
  payload: roles
});

export const getEstateFailure = (error) => ({
  type: estateConstants.GET_ESTATE_FAILURE,
  payload: error
});

export const getEstate = (estateId) => (dispatch) => {
  axios
    .get(`/estates/${estateId}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getEstateSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getEstateFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const getEstatesSuccess = (payload) => ({
  type: estateConstants.GET_ESTATES_SUCCESS,
  payload
});

export const getEstatesFailure = (error) => ({
  type: estateConstants.GET_ESTATES_FAILURE,
  payload: error
});

export const getEstates = (filter) => (dispatch) => {
  dispatch(beginApiCall());

  const url = filter ? `/estates?name=${filter}` : `/estates`;
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) {
        dispatch(getEstatesSuccess(response.data.data));
        dispatch(endApiCall());
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getEstatesFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const saveEstateSuccess = (payload) => ({
  type: estateConstants.ADD_ESTATE_SUCCESS,
  payload
});

export const saveEstateFailure = (payload) => ({
  type: estateConstants.ADD_ESTATE_FAILURE,
  payload
});

export const saveEstate = (estate) => (dispatch) => {
  const contactPerson = {
    name: estate.manager,
    email: estate.email,
    phoneNumber: estate.phoneNumber
  };

  const estateDetails = {
    cityId: estate.cityId,
    discoId: estate.discoId,
    dosCharge: estate.dosCharge,
    dosTariff: estate.dosTariff,
    enaroTariff: estate.enaroTariff,
    name: estate.name,
    street: estate.street,
    businessUnitId: estate.businessUnitId,
    contactPerson,
    estateTariff: estate.estateTariff
  };

  dispatch(beginApiCall());
  axios
    .post(`/estates`, estateDetails)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(saveEstateSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getEstatesFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const editEstateSuccess = (payload) => ({
  type: estateConstants.EDIT_ESTATE_SUCCESS,
  payload
});

export const editEstateFailure = (payload) => ({
  type: estateConstants.EDIT_ESTATE_FAILURE,
  payload
});

export const editEstate = (estate, estateId) => (dispatch) => {
  const contactPerson = {
    name: estate.manager,
    email: estate.email,
    phoneNumber: estate.phoneNumber
  };

  const estateDetails = {
    cityId: estate.cityId,
    discoId: estate.discoId,
    dosCharge: estate.dosCharge,
    dosTariff: estate.dosTariff,
    enaroTariff: estate.enaroTariff,
    name: estate.name,
    street: estate.street,
    businessUnitId: estate.businessUnitId,
    contactPerson: JSON.stringify(contactPerson),
    estateTariff: estate.estateTariff
  };

  dispatch(beginApiCall());
  axios
    .patch(`/estates/${estateId}`, estateDetails)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(editEstateSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editEstateFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const deactivateEstateSuccess = (payload) => ({
  type: estateConstants.DEACTIVATE_ESTATE_SUCCESS,
  payload
});

export const deactivateEstateFailure = (payload) => ({
  type: estateConstants.DEACTIVATE_ESTATE_FAILURE,
  payload
});

export const deactivateEstate = (estateId, status) => (dispatch) => {
  const type = !status ? 'deactivate' : 'activate';

  dispatch(beginApiCall());
  axios
    .patch(`/estates/${type}/${estateId}`)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(deactivateEstateSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(deactivateEstateFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const uploadEstatesSuccess = (payload) => ({
  type: estateConstants.UPLOAD_ESTATES_SUCCESS,
  payload
});

export const uploadEstatesFailure = (payload) => ({
  type: estateConstants.UPLOAD_ESTATES_FAILURE,
  payload
});

export const uploadEstates = (file) => (dispatch) => {
  const formData = new FormData();
  formData.append('sheet', file);

  const postConfig = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  axios
    .post(`/estates/import`, formData, postConfig)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(uploadEstatesSuccess(response.data));
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(uploadEstatesFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

const getEstatesRevenueSuccess = (payload) => ({
  type: estateConstants.GET_ESTATES_REVENUE_SUCCESS,
  payload
});

const getEstatesRevenueFailure = (error) => ({
  type: estateConstants.GET_ESTATES_REVENUE_FAILURE,
  payload: error
});

export const getEstatesRevenue = () => (dispatch) => {
  axios
    .get('/estates/overview/revenue')
    .then((response) => {
      if (response.status) {
        dispatch(getEstatesRevenueSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error.response.message);
        dispatch(getEstatesRevenueFailure());
      } else {
        throw new Error(error);
      }
    });
};
