import axios from 'axios';
import { paymentConstants } from 'store/constants';
import { urlFormatter } from 'utility';

import { toast } from 'sonner';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const addPaymentSuccess = (payload) => ({
  type: paymentConstants.ADD_PAYMENT_SUCCESS,
  payload
});

export const addPaymentFailure = (error) => ({
  type: paymentConstants.ADD_PAYMENT_FAILURE,
  payload: error
});

export const getPaymentsSuccess = (payload, userType) => ({
  type: paymentConstants.GET_PAYMENTS_SUCCESS,
  payload,
  userType
});

export const getPaymentsFailure = (payload) => ({
  type: paymentConstants.GET_PAYMENTS_SUCCESS,
  payload
});

export const getPaymentSuccess = (payload) => ({
  type: paymentConstants.GET_PAYMENT_SUCCESS,
  payload
});

export const getPaymentFailure = (payload) => ({
  type: paymentConstants.GET_PAYMENT_SUCCESS,
  payload
});

export const getPayments =
  ({ userType, userId }, page, limit, filterOptions) =>
  (dispatch) => {
    const endpoint =
      userType === 'customer'
        ? `/payments/user/${userId}/?page=${page}&limit=${limit}`
        : `/payments?page=${page}&limit=${limit}`;
    const url = urlFormatter(endpoint, filterOptions);

    axios
      .get(url)
      .then((response) => {
        if (response.data.status) {
          dispatch(getPaymentsSuccess(response.data.data));
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(`Error : ${error.response.data.message}`);
          dispatch(getPaymentsFailure(error.response.data));
        } else {
          throw error;
        }
      });
  };

export const getPaymentsAggregates = () => (dispatch) => {
  axios
    .get(`/payments`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getPaymentsSuccess(response.data.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getPaymentsFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const getPayment = (paymentId) => (dispatch) => {
  axios
    .get(`/payments/${paymentId}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getPaymentSuccess(response));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getPaymentFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const addPayment = (payment) => (dispatch) => {
  axios
    .post('/payments', payment)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Payment Added `);
        dispatch(addPaymentSuccess(response.data));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(addPaymentFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const updateSubscriptionStatus = (id, status) => {
  axios
    .patch(`/subscriptions/${id}/subscriptionStatus`, {
      isSubscriptionActive: status
    })
    .then((response) => {
      if (response.status) {
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.message) {
        toast.error(`Error : ${error.message}`);
      } else {
        throw error;
      }
    });
};
