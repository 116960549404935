import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addComment } from 'store/actions';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { validateComment } from 'utility';

export const SaveComment = ({ ...props }) => {
  const dispatch = useDispatch();

  const inProgress = useSelector((state) => state.apiCalls);

  const { close, entity } = props;

  const ticketId = entity?.id || '';
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveComment = useCallback(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      dispatch(addComment(ticketId, comment));
    }
  }, [errors, isSubmitting, comment, dispatch, ticketId]);

  useEffect(() => {
    saveComment();
    return () => {
      setIsSubmitting(false);
    };
  }, [saveComment]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validateComment(comment));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setComment((prevComment) => ({
      ...prevComment,
      [name]: value
    }));
  };

  const handleCancel = (event) => {
    setComment('');
    close(event);
  };

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="form__header">
        <h4 className="heading header-title">Enter your comment</h4>
      </div>
      <div className="form__content">
        <div className="form-group">
          <label>
            Your comment <span className="form-error">{errors.message}</span>
          </label>
          <textarea
            name="message"
            value={comment.message}
            className="form-control"
            onChange={handleChange}
            maxLength="180"
            rows="4"
            required
          />
        </div>
      </div>

      <div className="form__footer">
        <div className="form__footer-buttons d-flex justify-content-end">
          <div
            className="btn-group "
            role="group"
            aria-label="Button group example"
          >
            <button
              type="button"
              className="button button-primary button--outline"
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="button button-save ms-2"
              onClick={handleSubmit}
              disabled={inProgress}
            >
              {inProgress && comment && (
                <Spinner className="me-3" color="light" />
              )}{' '}
              Save Comment
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

SaveComment.propTypes = {
  close: PropTypes.func.isRequired
};
