import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Popover, PopoverBody } from 'reactstrap';
import { NotificationItem } from 'components';
import { useGetNotificationsQuery } from 'features/api';

export const BadgeNotification = () => {
  const { socket } = useSelector((state) => state.notifications);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const { data } = useGetNotificationsQuery();
  const items = data || [];

  const checkIsRead = (notificationListing) => {
    const notRead = notificationListing.filter((item) => item.isRead === false);

    return notRead.length;
  };

  return (
    <>
      <small
        className="notification notification-cursor position-relative float-end me-2"
        id="notification"
      >
        <i className="iconmoon icon-notif notif-spot"></i>
        {items && checkIsRead(items) > 0 && (
          <div className="notif-badge">{checkIsRead(items)}</div>
        )}
      </small>

      <Popover
        className="notification"
        placement="bottom"
        target="notification"
        toggle={toggle}
        isOpen={popoverOpen}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements: ['bottom']
            }
          }
        ]}
      >
        <PopoverBody
          className={` ${classnames({
            'notification__body-height': socket.length > 3
          })}  notification__body`}
        >
          <div className="notification__header my-3 text-center">
            <b>Notifications</b>{' '}
            <button
              className="button button-transparent button-nopadding modal-view__close"
              data-logid="0"
              onClick={toggle}
            >
              &times;
            </button>
          </div>
          <div className="notification-list">
            <NotificationItem items={items} />
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};
