import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';

export const BuyUnitsPrompt = ({
  modal,
  toggle,
  addPayment,
  paymentParams,
  name,
  amount,
  meterNo,
  isSubmiting
}) => {
  const closePrompt = (event) => {
    toggle(event);
  };

  const submitPayment = (event) => {
    event.preventDefault();
    addPayment(paymentParams);
  };

  return (
    <Modal className="prompt" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody>
        <div className="p-4 text-center">
          <h5>Make Payment</h5>
          <p>
            You are about to pay <b>₦ {amount}</b> to meter <b>{meterNo}</b> for
            customer <b>{name}</b>. Do you want to continue?
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <button
          type="button"
          className="button button-primary"
          onClick={closePrompt}
        >
          Cancel
        </button>

        <button
          type="button"
          className="button button-secondary ms-2"
          onClick={submitPayment}
        >
          Yes, add payment
          {isSubmiting && <Spinner className="me-3" color="light" />}
        </button>
      </ModalFooter>
    </Modal>
  );
};

BuyUnitsPrompt.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  addPayment: PropTypes.func.isRequired,
  status: PropTypes.bool,
  name: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  meterNo: PropTypes.string.isRequired,
  paymentParams: PropTypes.object.isRequired
};
