import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Loader } from 'components';
import { persistor, store } from 'store';
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'react-phone-number-input/style.css';
import 'assets/sass/main.scss';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
