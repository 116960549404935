import React, { useState } from 'react';
import {
  PageHeader,
  PowerGraph,
  StaffAggPayments,
  StaffAggTickets,
  StaffBuckets,
  Switcher
} from 'components';
import { filterEstates } from 'utility';
import { useGetEstatesQuery } from 'features/api';

export const StaffMetrics = () => {
  const [selected, setSelected] = useState();
  const { data } = useGetEstatesQuery();
  const estates = data?.estates || [];

  const getEstateDetails = (estateOption) => {
    let option;
    if (estateOption !== undefined) {
      option = estates.find((estate) => estate.id === estateOption.value);
    }

    setSelected(option);
  };

  return (
    <>
      <div className="dashboard">
        <PageHeader
          title={
            `Estate: ${(selected && selected.name) || 'NA'}` || `Welcome Back`
          }
          switcher={
            <Switcher
              entityType="Estate"
              entityHandler={getEstateDetails}
              options={filterEstates(estates)}
            />
          }
        />

        <div className="section">
          <div className="container-fluid">
            <div className="component">
              <StaffBuckets
                discoName={selected && selected.disco.name}
                tariff={selected && selected.currentAmount}
                businessUnit={selected && selected.businessUnit}
                residentTotal={selected && selected.residentCount}
              />
              <div className="dashboard-powergraph mt-4">
                <PowerGraph />
              </div>
            </div>
            <div className="component">
              <div className="row">
                <div className="col-md-6 dashboard-payments">
                  <StaffAggPayments />
                </div>
                <div className="col-md-6 dashboard-tickets">
                  <StaffAggTickets />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
