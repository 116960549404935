import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environment from 'environment';

const {
  api: { uri }
} = environment;
export const baseQuery = fetchBaseQuery({
  baseUrl: `${uri}`,
  prepareHeaders: headers => {
    const { token } = localStorage;
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  }
});
