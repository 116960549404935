import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { deleteCity } from 'store/actions';
import { Confirm, SaveCity, UseModal } from 'components';

export const LocationDetails = ({ stateId, cities }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [selectedCity, setselectedCity] = useState({});
  const [confirm, setConfirm] = useState(false);

  const toggle = () => setModal(!modal);

  const toggleEdit = (event) => {
    event.preventDefault();

    if (Object.values(event.currentTarget.dataset).length > 0) {
      const { cityid, city } = event.currentTarget.dataset;

      setModal(!modal);
      setEdit(!isEdit);
      setselectedCity({ city, cityId: cityid });
    } else if (isEdit) {
      setModal(!modal);
      setEdit(!isEdit);
    }
  };

  const toggleConfirm = (event) => {
    setConfirm(!confirm);
    if (Object.values(event.currentTarget.dataset).length > 0) {
      const { cityid, city } = event.currentTarget.dataset;

      setselectedCity({ city, cityId: cityid });
    }
  };

  const handleDelete = () => {
    dispatch(deleteCity(selectedCity.cityId, stateId));
  };

  return (
    <>
      <div className="location__details">
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <h6>Cities</h6>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="create-control float-end me-3">
              <button
                className="button button-small button-primary text-capitalize"
                type="button"
                onClick={toggle}
              >
                <i className="iconmoon icon-add"></i> Add New City
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="location__city-details mb-5">
              <ListGroup flush>
                {cities &&
                  cities.map((city) => {
                    return (
                      <ListGroupItem key={city.id}>
                        <span className="me-5">{city.name}</span>{' '}
                        <span className="float-end">
                          <button
                            className="button button-small button-transparent"
                            type="button"
                            data-cityid={city.id}
                            data-city={city.name}
                            onClick={toggleConfirm}
                          >
                            <i className="iconmoon icon-trash me-2"></i>
                          </button>
                          <button
                            className="button button-small button-transparent"
                            type="button"
                            data-cityid={city.id}
                            data-city={city.name}
                            onClick={toggleEdit}
                          >
                            <i className="iconmoon icon-edit"></i>
                          </button>
                        </span>
                      </ListGroupItem>
                    );
                  })}
              </ListGroup>
            </div>
          </div>
        </div>
      </div>
      <UseModal
        toggle={isEdit ? toggleEdit : toggle}
        modal={modal}
        isEdit={isEdit}
        entityId={stateId}
        entity={selectedCity}
        component={SaveCity}
        backdrop={'static'}
      />
      <Confirm
        toggle={toggleConfirm}
        entity={selectedCity.city}
        modal={confirm}
        actionHandler={handleDelete}
      />
    </>
  );
};

LocationDetails.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  cities: PropTypes.array,
  stateId: PropTypes.number
};
