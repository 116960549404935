import React, { useState } from 'react';
import { KoboToNaira, planStatus } from 'utility';
import { ToggleButton } from './ToggleButton';
import CancelSubscription from './CancelSubscription';
import PropTypes from 'prop-types';

export const Subscriptions = ({ subscription, getSubscription }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
    if (modal) {
      getSubscription(subscription.meterNo);
    }
  };

  return (
    <>
      {subscription ? (
        <>
          <label>Recurring Payment</label>
          <div className="row justify-content-between align-items-center m-0 mb-3 p-1 border border-light border-right-0">
            <label htmlFor={subscription.interval}>
              <span>
                {subscription.interval} <br />
                <b>₦ {KoboToNaira(subscription.amount)} </b>
              </span>
            </label>
            <ToggleButton
              id={subscription.interval}
              checked={planStatus(subscription.status)}
              toggleChecked={toggleModal}
            />
            <CancelSubscription
              modal={modal}
              toggle={toggleModal}
              amount={KoboToNaira(subscription.amount)}
              frequency={subscription.interval}
              meterNo={subscription.meterNo}
              subscriptionId={subscription.id}
            />
          </div>
        </>
      ) : (
        <div className="text-center">You have no active subscriptions</div>
      )}
    </>
  );
};

Subscriptions.propTypes = {
  subscription: PropTypes.array.isRequired
};
