import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  comparer,
  filterPermissions,
  getPermissions,
  getPermissionsById,
  removeDuplicates,
  validateRole
} from 'utility';
import { useAddRoleMutation, useEditRoleMutation } from 'features/api';

export const SaveRole = ({ ...props }) => {
  const initialRole = {
    title: '',
    description: '',
    permissions: []
  };

  const permissions = useSelector((state) => state.permissions);

  const { close, entity, isEdit } = props;
  const roleDetails = entity === undefined ? initialRole : entity;
  const rolePermissions =
    filterPermissions(removeDuplicates(roleDetails.permissions)) || [];
  const [addRole, { isLoading, isSuccess }] = useAddRoleMutation();
  const [editRole, { isLoading: isSubmitting, isSuccess: hasSubmitted }] =
    useEditRoleMutation();

  const [role, setRole] = useState(roleDetails);
  const [selected, setSelected] = useState(rolePermissions);
  const [newlySelected, setNewlySelected] = useState([]);
  const [unselected, setUnSelected] = useState([]);
  const [errors, setErrors] = useState({});

  const saveRole = (payload) => {
    if (Object.keys(errors).length === 0 && (!isLoading || !isSubmitting)) {
      if (isEdit) {
        editRole(payload);
        return;
      }

      addRole(payload);
    }
  };

  useEffect(() => {
    if (hasSubmitted || isSuccess) {
      close();
    }
  }, [hasSubmitted, isSuccess]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validateRole(role));
    let payload = {};
    if (selected.length < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        permissions: 'Please select at least one permission'
      }));
    } else {
      payload = {
        ...role,
        permissions: getPermissionsById(selected)
      };
      saveRole(payload);
    }
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setErrors(validateRole(role));
    let payload = {};

    if (selected.length < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        permissions: 'Please select at least one permission'
      }));
    } else {
      payload = {
        ...role,
        currentPermissions: getPermissions(permissions, selected),
        addPermissions: getPermissionsById(newlySelected),
        removePermissions: getPermissionsById(unselected)
      };
      saveRole(payload);
    }
  };

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setRole((prevRole) => ({
      ...prevRole,
      [name]: value
    }));
  };

  const handleSelect = (option) => {
    setSelected(option);
    setNewlySelected(option);
    if (option !== null) {
      setUnSelected(rolePermissions.filter(comparer(option)));
    }
  };

  const handleCancel = (event) => {
    setRole(initialRole);
    props.close(event);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h4 className="heading header-title">
            {isEdit ? 'Edit an existing role' : 'Create a Custom Role'}
          </h4>
          <p className="paragraph header-text">
            You can {isEdit ? 'edit existing roles' : 'create new roles'} to
            allow user access only the assets they need
          </p>
        </div>
        <div className="form__content row gap-3">
          <div className="form-group">
            <label>
              Role Name <span className="form-error">{errors.title}</span>
            </label>
            <input
              type="text"
              name="title"
              value={role.title}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Description{' '}
              <span className="form-error">{errors.description}</span>
            </label>
            <textarea
              name="description"
              value={role.description}
              className="form-control"
              onChange={handleChange}
              maxLength="180"
              rows="4"
              required
            />
          </div>
          <div className="form-group">
            <label>
              Permissions{' '}
              <span className="form-error">{errors.permissions}</span>
            </label>
            <Select
              closeMenuOnSelect={false}
              onChange={handleSelect}
              options={filterPermissions(permissions)}
              value={selected}
              name="permissions"
              isMulti
              isSearchable
              required
            />
          </div>
        </div>

        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group "
              role="group"
              aria-label="Button group example"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={handleCancel}
              >
                Cancel
              </button>

              {isEdit ? (
                <button
                  type="button"
                  className="button button-save ms-2"
                  onClick={handleEdit}
                  disabled={isSubmitting}
                >
                  {isSubmitting && <Spinner className="me-3" color="light" />}{' '}
                  Save Changes
                </button>
              ) : (
                <button
                  type="button"
                  className="button button-save ms-2"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading && <Spinner className="me-3" color="light" />} Save
                  Role
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

SaveRole.propTypes = {
  close: PropTypes.func.isRequired,
  entity: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isEdit: PropTypes.bool.isRequired
};
