import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetTicketsQuery } from 'features/api';

export const StaffAggTickets = () => {
  const { userType } = useSelector((state) => state.profile);

  const { data: all } = useGetTicketsQuery(
    { category: null, userType },
    {
      skip: !userType
    }
  );

  return (
    <section className="ticket__listing corner-tables">
      <table className="table table-borderless">
        <thead className="table-header">
          <tr>
            <th>
              <div className="row">
                <div className="col-md-12">
                  <h6>All Tickets</h6>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="table-content">
          <tr>
            <td>
              <span>Payments</span>
              <span className="float-end">
                {all && all.metadata.categoryAggregates.paymentCount}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Technicals</span>
              <span className="float-end">
                {all && all.metadata.categoryAggregates.technicalCount}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Others</span>
              <span className="float-end">
                {all && all.metadata.categoryAggregates.othersCount}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <Link className="float-end" to={`../ticket`}>
                <small>View More</small>
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
