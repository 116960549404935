import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getPaymentsAggregates } from 'store/actions';

export const StaffAggPayments = () => {
  const dispatch = useDispatch();
  const { metadata } = useSelector(state => state.payments);
  const { id, userType } = useSelector(state => state.profile);

  useEffect(() => {
    dispatch(getPaymentsAggregates());
    return () => {};
  }, [dispatch, userType, id]);

  return (
    <section className="ticket__listing corner-tables">
      <table className="table table-borderless">
        <thead className="table-header">
          <tr>
            <th>
              <div className="row">
                <div className="col-md-12">
                  <h6>All Payments</h6>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="table-content">
          <tr>
            <td>
              <span>Online</span>
              <span className="float-end">
                {metadata && metadata.sourceAggregates.onlineCount}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Offline</span>
              <span className="float-end">
                {metadata && metadata.sourceAggregates.offlineCount}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <Link className="float-end" to={`../payment`}>
                <small>View More</small>
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
