import axios from 'axios';
import { userConstants } from 'store/constants';
import { toast } from 'sonner';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const exportUsers = `${uri}/users/data/export?token=${localStorage.token}`;

export const createUserSuccess = (user) => ({
  type: userConstants.CREATE_USER_SUCCESS,
  payload: user
});

export const createUserFailure = (error) => ({
  type: userConstants.CREATE_USER_FAILURE,
  payload: error
});

export const createUser = (userDetails) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .post('/users', userDetails)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(createUserSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(createUserFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const getUsersSuccess = (users) => ({
  type: userConstants.GET_USERS_SUCCESS,
  payload: users
});

export const getUsersFailure = (error) => ({
  type: userConstants.GET_USERS_FAILURE,
  payload: error
});

export const getUsers = (userType) => (dispatch) => {
  axios
    .get(`/users?role=${userType}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getUsersSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(getUsersFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const getUserSuccess = (users) => ({
  type: userConstants.GET_USER_SUCCESS,
  payload: users
});

export const getUserFailure = (error) => ({
  type: userConstants.GET_USER_FAILURE,
  payload: error
});

export const getUser = (userId) => (dispatch) => {
  axios
    .get(`/users/${userId}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getUserSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(getUserFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const editUserSuccess = (users) => ({
  type: userConstants.EDIT_USER_SUCCESS,
  payload: users
});

export const editUserFailure = (error) => ({
  type: userConstants.EDIT_USER_FAILURE,
  payload: error
});

export const editUser = (editOptions, editDetails) => (dispatch) => {
  const { meterNo, isMeterActive, ...meter } = editOptions;

  const meterEdit = { ...meter, newMeterNo: meter.meterNo };

  const editUrl =
    editDetails.userType === 'customer'
      ? editDetails.status
        ? `?type=${editDetails.userType}&meterNo=${editDetails.meterNo}`
        : `?type=${editDetails.userType}`
      : '';

  dispatch(beginApiCall());
  axios
    .patch(`/users/edit/${editDetails.userId}${editUrl}`, meterEdit)
    .then((response) => {
      if (response.data.status) {
        if (Object.keys(editOptions)[0] === 'isDeactivated') {
          const status = editOptions.isDeactivated
            ? 'deactivated'
            : 'activated';
          toast.success(
            `Success : ${editDetails.name}  has been ${status} successfully`
          );
        } else {
          toast.success(`Success : ${response.data.message}`);
        }

        dispatch(editUserSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editUserFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const uploadUsersSuccess = (users) => ({
  type: userConstants.UPLOAD_USERS_SUCCESS,
  payload: users
});

export const uploadUsersFailure = (error) => ({
  type: userConstants.UPLOAD_USERS_FAILURE,
  payload: error
});

export const uploadUsers = (file) => (dispatch) => {
  const formData = new FormData();
  formData.append('sheet', file);

  const postConfig = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  axios
    .post(`/users/import/`, formData, postConfig)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(uploadUsersFailure(response.data));
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(uploadUsersFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const getUserByMeterIdSuccess = (users) => ({
  type: userConstants.GET_USER_BY_METER_SUCCESS,
  payload: users
});

export const getUserByMeterIdFailure = (error) => ({
  type: userConstants.GET_USER_BY_METER_FAILURE,
  payload: error
});

export const getUserByMeterId = (meterNumber) => (dispatch) => {
  axios
    .get(`/users/?meterNo=${meterNumber}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getUserByMeterIdSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getUserByMeterIdFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const meterResetSuccess = (payload) => ({
  type: userConstants.METER_RESET_SUCCESS,
  payload
});

export const meterReset = () => (dispatch) => {
  dispatch(meterResetSuccess({}));
};
