import { createApi } from '@reduxjs/toolkit/query/react';
import { toast } from 'sonner';
import { baseQuery } from 'features/baseQuery';

export const residentsApi = createApi({
  reducerPath: 'residentsApi',
  baseQuery,
  tagTypes: ['Residents'],
  endpoints: (builder) => ({
    getResidents: builder.query({
      query: (estateId) => `/estates/${estateId}/residents`,
      providesTags: ['Residents'],
      transformResponse: (response) => response.data
    }),
    editResident: builder.mutation({
      query: ({ resident, userId }) => ({
        url: `/users/edit/${userId}?type=${resident.userType}`,
        method: 'PATCH',
        body: resident
      }),
      invalidatesTags: ['Residents']
    }),
    reassignResident: builder.mutation({
      query: (options) => {
        const { id, userType, meterNo, newMeterNo } = options;
        return {
          url: `/users/edit/${id}?type=${userType}`,
          method: 'PATCH',
          body: {
            oldMeterNo: meterNo,
            newMeterNo
          }
        };
      },
      invalidatesTags: ['Residents']
    }),
    changeStatus: builder.mutation({
      query: ({ action, entity }) => {
        return {
          url: `/resident/action/${entity.residentId}?meterNo=${
            entity.meter
          }&action=${action.isDeactivated ? 'deactivated' : 'activated'}`,
          method: 'PATCH'
        };
      },
      invalidatesTags: ['Residents']
    }),
    uploadResident: builder.mutation({
      query: (file, estateId) => {
        const formData = new FormData();
        formData.append('sheet', file);

        return {
          url: `/users/import?type=customer&eid=${estateId}`,
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Residents'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      }
    })
  })
});

export const {
  useGetResidentsQuery,
  useEditResidentMutation,
  useReassignResidentMutation,
  useChangeStatusMutation,
  useUploadResidentMutation
} = residentsApi;
