export default {
  auth: {
    email: '',
    id: '',
    name: '',
    token: '',
    loggedIn: false
  },
  apiCalls: false,
  users: {},
  profile: {},
  roles: {},
  permissions: [],
  estates: [],
  locations: {},
  residents: {},
  payments: {},
  units: {},
  paystack: {},
  subscription: {},
  tickets: {},
  approvals: {},
  notifications: {
    socket: []
  },
  exports: {}
};
