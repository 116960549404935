import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';

export const StaffList = ({ entity, close }) => {
  const { staffs, discoName } = entity;
  const parsedStaffs = staffs ? JSON.parse(staffs) : [];

  return (
    <div>
      <div className="modal-view__header py-3">
        <h4 className="heading header-title">
          Disco - {discoName && discoName}
        </h4>
        <button
          className="button button-transparent button-nopadding modal-view__close"
          onClick={close}
        >
          &times;
        </button>
      </div>
      <div className="modal-view__body">
        <div className="content__area">
          <ListGroup>
            {parsedStaffs && parsedStaffs.length > 0 ? (
              parsedStaffs.map(staff => {
                return (
                  <ListGroupItem key={staff.id}>
                    <span className="text-capitalize">{staff.name}</span> -{' '}
                    <span className="font-weight-bolder">{staff.email}</span>
                  </ListGroupItem>
                );
              })
            ) : (
              <ListGroupItem>No staff assigned</ListGroupItem>
            )}
          </ListGroup>
        </div>
      </div>
    </div>
  );
};

StaffList.propTypes = {
  entity: PropTypes.object.isRequired,
  close: PropTypes.func
};
