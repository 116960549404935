import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { generatePaymentFilter } from 'store/actions';
import { _formatDate, addOneDay, generateFilterParam } from 'utility';

export const PaymentFilter = ({
  filterHandler,
  filterSet,
  metadata,
  customerId,
  setFilterData
}) => {
  const filterDefaults = {
    estate: '',
    meterNo: ''
  };

  const { id, userType, meters } = useSelector((state) => state.profile);
  const { currentPage, limit } = metadata;

  const dispatch = useDispatch();

  const [option, setOption] = useState(filterDefaults);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const filterOptions = useCallback(() => {
    if (isSubmitting) {
      let filterDetails = {
        ...option,
        ...(startDate && { from: _formatDate(startDate) }),
        ...(endDate && { to: _formatDate(addOneDay(endDate)) }),
        ...(customerId && { customerId })
      };

      filterDetails = generateFilterParam(filterDetails);

      dispatch(generatePaymentFilter({ userType, userId: id }, filterDetails));
      setFilterData(filterDetails);
      filterHandler({
        userType,
        page: currentPage,
        limit,
        filterOptions: { ...filterDetails }
      });
    }
  }, [
    dispatch,
    option,
    isSubmitting,
    startDate,
    endDate,
    id,
    currentPage,
    limit,
    userType,
    filterHandler
  ]);

  useEffect(() => {
    filterOptions();
    return () => {
      setIsSubmitting(false);
    };
  }, [filterOptions]);

  const handleSubmit = (event) => {
    event.preventDefault();
    filterSet?.('filtered');
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setOption((prevOption) => ({
      ...prevOption,
      [name]: value
    }));
  };

  const handleCancel = () => {
    filterSet?.('reset');
    setOption(filterDefaults);
    setStartDate();
    setEndDate();
    setIsSubmitting(true);
    setFilterData({});
    dispatch(generatePaymentFilter({ userType, userId: id }, {}));
  };

  return (
    <form className="form row gap-2">
      {userType === 'staff' && (
        <>
          <div className="form-group">
            <label>Estate</label>
            <input
              type="text"
              name="estate"
              value={option.estate}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Meter Number</label>
            <input
              type="text"
              name="meterNo"
              value={option.meterNo}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </>
      )}

      {userType === 'customer' && meters && (
        <div className="form-group">
          <label>Meter No</label>
          <select
            name="meterNo"
            value={option.meterNo}
            className="form-control"
            onChange={handleChange}
          >
            <option value="" defaultValue>
              Choose meter
            </option>
            {meters &&
              meters.map(
                (meter) =>
                  meter.isMeterActive && (
                    <option key={meter.meterNo} value={meter.meterNo}>
                      {meter.meterName || meter.meterNo}
                    </option>
                  )
              )}
          </select>
        </div>
      )}

      <div className="col-12">
        <div className="row">
          <label className="col-md-12 mt-2">Date Range</label>
          <div className="col-md-6">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showMonthDropdown
              showYearDropdown
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="col-md-6">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showMonthDropdown
              showYearDropdown
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="button button-primary button--outline button-small"
          onClick={handleCancel}
        >
          Reset
        </button>

        <button
          type="button"
          className="button button-save button-small"
          onClick={handleSubmit}
        >
          Filter
        </button>
      </div>
    </form>
  );
};

PaymentFilter.propTypes = {
  filterHandler: PropTypes.func.isRequired,
  filterSet: PropTypes.func.isRequired,
  customerId: PropTypes.string
};
