import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'features/baseQuery';

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery,
  tagTypes: ['Role'],
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => '/roles',
      transformResponse: (response) => response.data,
      providesTags: ['Roles']
    }),
    addRole: builder.mutation({
      query: (role) => ({
        url: '/roles',
        method: 'POST',
        body: role
      }),
      invalidatesTags: ['Roles']
    }),
    getRole: builder.query({
      query: (roleId) => `/roles/${roleId}`,
      transformResponse: (response) => response.data,
      providesTags: ['Role']
    }),
    editRole: builder.mutation({
      query: (role) => ({
        url: `/roles/${role.id}`,
        method: 'PATCH',
        body: {
          title: role.title,
          description: role.description,
          addPermissions: role.addPermissions,
          removePermissions: role.removePermissions
        }
      }),
      invalidatesTags: ['Roles']
    }),
    deleteRole: builder.mutation({
      query: (roleId) => ({
        url: `/roles/${roleId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Roles']
    })
  })
});

export const {
  useGetRolesQuery,
  useAddRoleMutation,
  useGetRoleQuery,
  useEditRoleMutation,
  useDeleteRoleMutation
} = rolesApi;
