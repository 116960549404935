import React from 'react';
import PropTypes from 'prop-types';

const renderContent = content => {
  return content.map(item => {
    return item;
  });
};

export const Table = ({ columns, dataSource, className, noContent }) => {
  const content =
    dataSource && dataSource.length > 0 ? (
      renderContent(dataSource)
    ) : (
      <tr>
        <td colSpan={columns.length}>{noContent || `No Content`}</td>
      </tr>
    );

  return (
    <div className="table-responsive">
      <table className={`table table-borderless ${className}`}>
        <thead className="table-header">
          <tr className="text-uppercase">
            {columns.map((name, index) => {
              return (
                <th scope="col" key={index}>
                  {name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="table-content">{content}</tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired,
  noContent: PropTypes.object,
  dataSource: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
