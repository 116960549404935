import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Buckets,
  BuyUnitsCustomer,
  ConsumptionGraph,
  CustomerPayments,
  PageHeader,
  UseModal
} from 'components';
import { formatCurrency } from 'utility';
import { getMeters } from 'store/actions';

const Dashboard = () => {
  const dispatch = useDispatch();
  const meters = useSelector((state) => state.profile.meters);
  const [modal, setModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const { id, name } = useSelector((state) => state.profile);
  const [currentMeter, setCurrentMeter] = useState();
  // TODO const { data: meters } = useGetMetersQuery(id);
  useEffect(() => {
    dispatch(getMeters(id));
  }, [id]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const onMeterClick = (meter) => {
    setShowOptions(false);
    setCurrentMeter(meter);
  };

  const estateTariff = currentMeter
    ? currentMeter.estate.estateTariff
    : meters && meters[0].estate.estateTariff;

  const tariffApproved = currentMeter
    ? currentMeter.estate.isTariffApproved
    : meters && meters[0].estate.isTariffApproved;

  return (
    <>
      <div className="dashboard">
        <PageHeader title={`Welcome, ${name}`} />
        <div className="section">
          <div className="container-fluid">
            <div className="component">
              <div className="d-flex justify-content-between">
                <div className="dashboard__subtitle">
                  Meter Estate:{' '}
                  {currentMeter
                    ? currentMeter.estate.name
                    : meters && meters[0].estate.name}
                  <small className="mx-2">
                    - Meter Tariff:{' '}
                    {tariffApproved
                      ? `${formatCurrency(estateTariff)} / kWh`
                      : 'Not Approved'}
                  </small>
                </div>

                <button
                  className="button button-primary button-lowcase"
                  onClick={toggleModal}
                >
                  BUY UNITS
                </button>
              </div>
            </div>
            <div className="component">
              <div className="row dashboard-buckets dashboard-customer">
                {meters && (
                  <Buckets
                    showOptions={showOptions}
                    setShowOptions={setShowOptions}
                    meter={currentMeter || meters[0]}
                    meters={meters}
                    onMeterClick={onMeterClick}
                  />
                )}
              </div>
            </div>

            <div className="component">
              <div className="dashboard-powergraph mt-4">
                <ConsumptionGraph />
              </div>
            </div>

            <div className="component">
              <CustomerPayments />
            </div>
          </div>
        </div>
      </div>
      <UseModal
        modal={modal}
        toggle={toggleModal}
        component={BuyUnitsCustomer}
      />
    </>
  );
};
Dashboard.propTypes = {};

const CustomerDashboard = Dashboard;

export { CustomerDashboard };
