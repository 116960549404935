import { paystackConstants } from '../constants';
import initialState from './initialState';

const raveReducer = (state = initialState.paystack, action) => {
  switch (action.type) {
    case paystackConstants.GET_PAYMENT_PLANS_SUCCESS:
      return { ...state, paymentPlans: action.payload.data };
    case paystackConstants.GET_PAYMENTS_PLANS_FAILURE:
      return { ...state };

    case paystackConstants.GET_TRANSACTIONS_SUCCESS:
      return { ...state, transactions: action.payload.data };
    case paystackConstants.GET_TRANSACTIONS_FAILURE:
      return { ...state };

    case paystackConstants.GET_TRANSACTION_SUCCESS:
      return { ...state, transaction: action.payload };
    case paystackConstants.GET_TRANSACTION_FAILURE:
      return { ...state };

    case paystackConstants.CREATE_PLAN_SUCCESS:
      return { ...state, plan: action.payload };
    case paystackConstants.CREATE_PLAN_FAILURE:
      return { ...state };

    case paystackConstants.FETCH_PLAN_BY_CUSTOMER_SUCCESS:
      return { ...state, activePlan: action.payload.data };
    case paystackConstants.FETCH_PLAN_BY_CUSTOMER_FAILURE:
      return { ...state };

    case paystackConstants.RESET_PLANS_SUCCESS:
      return { ...state, activePlan: null };

    default:
      return { ...state };
  }
};

export default raveReducer;
