import { ticketConstants } from '../constants';
import initialState from './initialState';

const ticketReducer = (state = initialState.tickets, action) => {
  switch (action.type) {
    case ticketConstants.GET_TICKETS_FAILURE:
      return { ...state };
    case ticketConstants.GET_TICKETS_SUCCESS:
      return { ...state, [action.category]: action.payload.data };
    case ticketConstants.GET_TICKET_FAILURE:
      return { ...state };
    case ticketConstants.GET_TICKET_SUCCESS:
      return { ...state, [action.current]: action.payload.data };
    case ticketConstants.REMOVE_LOG_SUCCESS:
      return Object.fromEntries(
        Object.entries(state).filter(([key]) => {
          return key !== action.current;
        })
      );
    case ticketConstants.UPDATE_TICKET_FAILURE:
      return { ...state };
    case ticketConstants.UPDATE_TICKET_SUCCESS:
      return { ...state };
    case ticketConstants.ADD_TICKET_COMMENT_FAILURE:
      return { ...state };
    case ticketConstants.ADD_TICKET_COMMENT_SUCCESS:
      return { ...state };
    default:
      return state;
  }
};

export default ticketReducer;
