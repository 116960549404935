import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { SaveUnits, UseModal } from 'components';
import { isObjectEmpty, validateFields } from 'utility';
import {
  useCreateEstateMutation,
  useEditEstateMutation,
  useGetDiscosQuery,
  useGetEstateQuery,
  useGetUnitsQuery
} from 'features/api';
import { PhoneNumberInput } from 'components/PhoneNumberInput';

export const SaveEstate = ({ ...props }) => {
  const initialEstate = {
    name: '',
    street: '',
    cityId: '',
    stateId: '',
    manager: '',
    email: '',
    phoneNumber: '',
    enaroTariff: '',
    estateTariff: '',
    dosCharge: '',
    dosTariff: '',
    discoId: '',
    businessUnitId: '',
    isTariffApproved: false
  };

  const { close, isEdit, estateId = 0 } = props;
  const dispatch = useDispatch();

  const { states } = useSelector((state) => state.locations || []);

  const { data } = useGetDiscosQuery();
  const discos = data || [];

  const { data: estateInfo } = useGetEstateQuery(estateId, {
    skip: !estateId
  });
  const { data: unitsData } = useGetUnitsQuery();
  const units = unitsData || [];

  const [editEstate, { isLoading: isEditing, isSuccess: isEdited }] =
    useEditEstateMutation();
  const [createEstate, { isLoading: isCreating, isSuccess: isCreated }] =
    useCreateEstateMutation();

  const [estate, setEstate] = useState(initialEstate);
  const [errors, setErrors] = useState({});
  const [districts, setDistricts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (estateInfo?.id) {
      setEstate((prevState) => ({
        ...prevState,
        ...estateInfo,
        manager: estateInfo?.contactPerson?.name,
        email: estateInfo?.contactPerson?.email,
        phoneNumber: estateInfo?.contactPerson?.phoneNumber,
        stateId: estateInfo?.city?.state?.id,
        cityId: estateInfo?.city?.id,
        businessUnitId: estateInfo?.businessUnit?.id,
        discoId: estateInfo?.disco?.id
      }));
    }
  }, [estateInfo?.id]);

  const addEstate = useCallback(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (isEdit) {
        editEstate({ estate, estateId });
        return;
      }
      createEstate(estate);
    }
  }, [dispatch, errors, isEdit, isSubmitting, estate, estateId, close]);

  const retrieveCities = useCallback(
    (value) => {
      const cityList = states.filter((state) => {
        return state.id === value;
      });

      setDistricts(cityList[0]?.cities);
    },
    [states]
  );

  useEffect(() => {
    addEstate();

    return () => {
      setIsSubmitting(false);
    };
  }, [addEstate]);

  useEffect(() => {
    if (isCreated || isEdited) {
      close();
    }
  }, [isCreated, isEdited]);

  useEffect(() => {
    if (estate.stateId) {
      retrieveCities(estate.stateId);
    }
  }, [estate.stateId, retrieveCities]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateFields(estate);
    setErrors(errors);
    const isEmpty = isObjectEmpty(errors);
    if (isEmpty) {
      setIsSubmitting(true);
    }
  };

  const handleChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;

    if (name === 'phoneNumber') {
      value = value.replace(/\D/g, '');
    }

    if (name === 'stateId') {
      retrieveCities(value);
      setEstate((prevEstate) => {
        return {
          ...prevEstate,
          cityId: ''
        };
      });
    }

    setEstate((prevEstate) => {
      return {
        ...prevEstate,
        [name]: value
      };
    });
  };

  const handlePhone = (value) => {
    setEstate((prevEstate) => {
      return {
        ...prevEstate,
        phoneNumber: value
      };
    });
  };

  const handleCancel = (event) => {
    setEstate(initialEstate);
    close(event);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h4 className="heading header-title">
            {isEdit ? 'Edit' : 'Add'} Estate
          </h4>
          <p className="paragraph header-text">
            Please enter the required information about your estate
          </p>
        </div>
        <div className="form__content container">
          <div className="row g-3">
            <div className="col-12">
              <label>
                Name of the Estate{' '}
                <span className="form-error">{errors.name}</span>
              </label>
              <input
                type="text"
                name="name"
                value={estate.name}
                className="form-control"
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-12">
              <label>
                Street <span className="form-error">{errors.street}</span>
              </label>
              <input
                type="text"
                name="street"
                value={estate.street}
                className="form-control"
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-12 col-md-6">
              <label>
                State <span className="form-error">{errors.stateId}</span>
              </label>
              <select
                name="stateId"
                value={estate.stateId}
                className="form-control"
                onChange={handleChange}
                required
              >
                <option value="0">Select State</option>
                {states &&
                  states.map((state) => {
                    return (
                      <option key={state.id} value={state.id}>
                        {`${state.name}`}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-12 col-md-6">
              <label>
                City <span className="form-error">{errors.cityId}</span>
              </label>
              <select
                name="cityId"
                value={estate.cityId}
                className="form-control"
                onChange={handleChange}
                required
              >
                <option>Select City</option>
                {(districts || []).map((district) => {
                  return (
                    <option key={district.id} value={district.id}>
                      {`${district.name}`}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className=""></div>
            <div className="col-12 col-md-6">
              <label>
                Disco <span className="form-error">{errors.discoId}</span>
              </label>
              <select
                name="discoId"
                value={estate.discoId}
                className="form-control"
                onChange={handleChange}
                required
              >
                <option value="0">Select Disco</option>
                {discos &&
                  discos.map((disco) => {
                    return (
                      <option key={disco.id} value={disco.id}>
                        {`${disco.name}`}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-12 col-md-6 field__relative">
              <label>
                Undertaken{' '}
                <span className="form-error">{errors.businessUnitId}</span>
              </label>
              <select
                name="businessUnitId"
                value={estate.businessUnitId}
                className="form-control"
                onChange={handleChange}
                required
              >
                <option value="0">Select Undertaken</option>
                {units &&
                  units.map((unit) => {
                    return (
                      <option key={unit.id} value={unit.id}>
                        {`${unit.name}`}
                      </option>
                    );
                  })}
              </select>

              <button
                className="button button-primary button-small field__absolute-tr"
                onClick={toggle}
                type="button"
              >
                <i className="iconmoon icon-add"></i>
              </button>
            </div>

            <div className="col-12 col-md-6">
              <label>
                DUOS Charge
                <span className="form-error">{errors.dosCharge}</span>
              </label>
              <input
                type="text"
                name="dosCharge"
                value={estate.dosCharge}
                className="form-control"
                onChange={handleChange}
                disabled={isEdit && !estate.isTariffApproved}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <label>
                Disco Tariff{' '}
                <span className="form-error">{errors.dosTariff}</span>
              </label>
              <input
                type="text"
                name="dosTariff"
                value={estate.dosTariff}
                className="form-control"
                onChange={handleChange}
                disabled={isEdit && !estate.isTariffApproved}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <label>
                Enaro Tariff{' '}
                <span className="form-error">{errors.enaroTariff}</span>
              </label>
              <input
                type="text"
                name="enaroTariff"
                value={estate.enaroTariff}
                className="form-control"
                onChange={handleChange}
                disabled={isEdit && !estate.isTariffApproved}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <label>
                Estate Tariff{' '}
                <span className="form-error">{errors.estateTariff}</span>
              </label>
              <input
                type="text"
                name="estateTariff"
                value={estate.estateTariff}
                className="form-control"
                onChange={handleChange}
                disabled={isEdit && !estate.isTariffApproved}
                required
              />
            </div>

            <div className="col-12">
              <label>
                Name of Manager{' '}
                <span className="form-error">{errors.manager}</span>
              </label>
              <input
                type="text"
                name="manager"
                value={estate.manager}
                className="form-control"
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <label>
                Email <span className="form-error">{errors.email}</span>
              </label>
              <input
                type="email"
                name="email"
                value={estate.email}
                className="form-control"
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <label>
                Phone Number{' '}
                <span className="form-error">{errors.phoneNumber}</span>
              </label>
              <PhoneNumberInput
                placeholder="7045637890"
                value={estate.phoneNumber}
                onChange={handlePhone}
                required
              />
            </div>
          </div>
        </div>
        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group"
              role="group"
              aria-label="button controls group"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="button button-save ms-2"
                onClick={handleSubmit}
                disabled={isCreating || isEditing}
              >
                {(isCreating || isEditing) && (
                  <Spinner className="me-3" color="light" />
                )}{' '}
                {isEdit ? 'Save Changes' : 'Save Estate'}
              </button>
            </div>
          </div>
        </div>
      </form>
      <UseModal
        toggle={toggle}
        modal={modal}
        component={SaveUnits}
        backdrop={'static'}
      />
    </>
  );
};

SaveEstate.propTypes = {
  close: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  estateId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
