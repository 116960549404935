import { io } from 'socket.io-client';

import nameSpaces from './nameSpaces';
import environment from '../environment';

const {
  api: { socket }
} = environment;

const init = (rootURL) => {
  return Object.entries(nameSpaces).map((space) => {
    const socketIO = io(`${rootURL}/${space[0]}`, {
      transports: ['websocket']
    });
    return socketIO;
  });
};

export const sockets = init(socket);
