import { roleConstants } from '../constants';
import initialState from './initialState';

const roleReducer = (state = initialState.roles, action) => {
  switch (action.type) {
    case roleConstants.GET_ROLES_FAILURE:
      return { ...state };
    case roleConstants.GET_ROLES_SUCCESS:
      return action.payload.data;
    case roleConstants.ADD_ROLE_FAILURE:
      return { ...state };
    case roleConstants.ADD_ROLE_SUCCESS:
      return { ...state };
    case roleConstants.DELETE_ROLE_FAILURE:
      return { ...state };
    case roleConstants.DELETE_ROLE_SUCCESS:
      return Object.values(state).filter(role => role.id !== action.payload);
    case roleConstants.EDIT_ROLE_FAILURE:
      return { ...state };
    case roleConstants.EDIT_ROLE_SUCCESS:
      return Object.values(state).map(role => {
        const roleItem =
          role.id !== action.payload.id
            ? role
            : { ...role, permissions: action.payload.currentPermissions };

        return roleItem;
      });
    case roleConstants.GET_ROLE_FAILURE:
      return { ...state };
    case roleConstants.GET_ROLE_SUCCESS:
      return {
        ...state,
        permissions: action.payload.data
      };
    default:
      return state;
  }
};

export default roleReducer;
