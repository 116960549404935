import axios from 'axios';
import { profileConstants } from 'store/constants';
import { toast } from 'sonner';
import environment from '../../environment';
import { beginApiCall, endApiCall } from './apiActions';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const getMetersSuccess = (users) => ({
  type: profileConstants.GET_METERS_SUCCESS,
  payload: users
});

export const getMetersFailure = (error) => ({
  type: profileConstants.GET_METERS_FAILURE,
  payload: error
});

export const getMeters = (userId) => (dispatch) => {
  axios
    .get(`/resident/${userId}/meters`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getMetersSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getMetersFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const editAliasSuccess = (payload, meterDetails) => ({
  type: profileConstants.EDIT_METER_ALIAS_SUCCESS,
  payload,
  meterDetails
});

export const editAliasFailure = (error) => ({
  type: profileConstants.EDIT_METER_ALIAS_FAILURE,
  payload: error
});

export const editAlias = (meterDetails) => (dispatch) => {
  dispatch(beginApiCall());

  const { meterName, meterNo, userId } = meterDetails;
  axios
    .patch(`/resident/${userId}/meters/?meterNo=${meterNo}`, { meterName })
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(editAliasSuccess(response.data, meterDetails));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editAliasFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};
