import React from 'react';
import PropTypes from 'prop-types';
import { dateFormater } from 'utility';

export const Buckets = ({
  meters,
  meter,
  onMeterClick,
  setShowOptions,
  showOptions
}) => {
  const { isMeterActive, meterNo, meterName } = meter;

  return (
    meter && (
      <>
        <div className="col-md-3">
          <div className="bucket mb-2">
            <p className="bucket__title">
              METER
              <span
                className={`meter-status ${
                  isMeterActive ? 'active' : 'inactive'
                }`}
              >
                {isMeterActive ? 'Active' : 'Inactive'} &#8226;
              </span>
            </p>
            <p className="bucket__figure"> {meterName || meterNo} </p>
            {meters && meters.length > 1 && (
              <>
                <span
                  className="meter-options__caption"
                  onMouseOver={() => setShowOptions(true)}
                >
                  Switch Meter
                </span>
                {showOptions && (
                  <div
                    className="meter-options__dropdown"
                    onMouseLeave={() => setShowOptions(false)}
                  >
                    <h5 className="meter-options__dropdown-header">
                      Choose meter
                    </h5>
                    {meters.map((userMeter, meterIndex) => {
                      return (
                        <span
                          key={meterIndex}
                          className="meter-options__dropdown-items"
                          onClick={() => onMeterClick(userMeter)}
                        >
                          {userMeter.meterName || userMeter.meterNo}
                        </span>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="col-md-3">
          <div className="bucket mb-2">
            <p className="bucket__title">UNITS AVAILABLE</p>
            <p className="bucket__figure"> NA </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="bucket mb-2">
            <p className="bucket__title">MONTHLY UNITS CONSUMED</p>
            <p className="bucket__figure"> NA </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="bucket mb-2">
            <p className="bucket__title">LAST RECHARGE DATE</p>
            <p className="bucket__figure">
              {(meter.lastRechargeDate.length > 0 &&
                dateFormater(meter.lastRechargeDate[0].createdAt, 'time')) ||
                'Not recharged'}
            </p>
          </div>
        </div>
      </>
    )
  );
};

Buckets.propTypes = {
  meter: PropTypes.object.isRequired,
  meters: PropTypes.array.isRequired,
  setShowOptions: PropTypes.func.isRequired,
  showOptions: PropTypes.bool.isRequired
};
