import { profileConstants } from '../constants';
import initialState from './initialState';

const profileReducer = (state = initialState.profile, action) => {
  switch (action.type) {
    case profileConstants.GET_PROFILE_FAILURE:
      return {
        ...state
      };
    case profileConstants.GET_PROFILE_SUCCESS:
      return action.payload.data;
    case profileConstants.GET_METERS_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message
      };
    case profileConstants.GET_METERS_SUCCESS:
      return {
        ...state,
        meters: [...action.payload.data]
      };
    case profileConstants.EDIT_METER_ALIAS_FAILURE:
      return { ...state };
    case profileConstants.EDIT_METER_ALIAS_SUCCESS:
      return { ...state };
    default:
      return state;
  }
};

export default profileReducer;
