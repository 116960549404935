const env = process.env.NODE_ENV;

const development = {
  api: {
    uri: process.env.REACT_APP_API_DEV,
    socket: process.env.REACT_APP_SOCKET_API_DEV
  },
  payment: {
    url: process.env.REACT_APP_PAYSTACK_API_DEV,
    pubkey: process.env.REACT_APP_PAYSTACK_DEV_PUB_KEY,
    seckey: process.env.REACT_APP_PAYSTACK_DEV_SEC_KEY,
    verifyUrl: process.env.REACT_APP_VERIFY_URL_DEV,
    paymentRedirect: process.env.REACT_APP_PAYMENT_CALLBACK_URL_DEV
  },
  VAT: process.env.REACT_APP_CALCULATED_VAT,
  TARIFF: process.env.REACT_APP_CALCULATED_TARIFF
};
const test = {
  api: {
    uri: process.env.REACT_APP_API_DEV,
    socket: process.env.REACT_APP_SOCKET_API_TEST
  },
  payment: {
    url: process.env.REACT_APP_PAYSTACK_API_DEV,
    pubkey: process.env.REACT_APP_PAYSTACK_DEV_PUB_KEY,
    seckey: process.env.REACT_APP_PAYSTACK_DEV_SEC_KEY,
    verifyUrl: process.env.REACT_APP_VERIFY_URL_TEST,
    paymentRedirect: process.env.REACT_APP_PAYMENT_CALLBACK_URL_TEST
  },
  VAT: process.env.REACT_APP_CALCULATED_VAT,
  TARIFF: process.env.REACT_APP_CALCULATED_TARIFF
};
const production = {
  api: {
    uri: process.env.REACT_APP_API_PROD,
    socket: process.env.REACT_APP_SOCKET_API_PROD
  },
  payment: {
    url: process.env.REACT_APP_PAYSTACK_API_PROD,
    pubkey: process.env.REACT_APP_PAYSTACK_PROD_PUB_KEY,
    seckey: process.env.REACT_APP_PAYSTACK_PROD_SEC_KEY,
    verifyUrl: process.env.REACT_APP_VERIFY_URL_PROD,
    paymentRedirect: process.env.REACT_APP_PAYMENT_CALLBACK_URL_PROD
  },
  VAT: process.env.REACT_APP_CALCULATED_VAT,
  TARIFF: process.env.REACT_APP_CALCULATED_TARIFF
};

const environment = {
  development,
  test,
  production
};

module.exports = environment[env];
