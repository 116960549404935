import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BuyUnitsCustomer,
  FilterPopover,
  LoadingContent,
  NoContent,
  PaymentFilter,
  Table,
  UseModal,
  ViewTransaction
} from 'components';

import { dateFormater, formatCurrency } from 'utility';
import { CopyText } from 'components/CopyText';
import { paymentApi } from 'features/api';
import { useMatch } from 'react-router-dom';

export const CustomerPayments = () => {
  const header = [
    'Transaction ID',
    'Token NO',
    'Meter ID',
    'Amount',
    'Channel',
    'Date',
    'Status'
  ];
  const match = useMatch('app/payment/:id');
  const {
    params: { id: paystackRefId }
  } = match || { params: {} };
  const { id, userType } = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const [transactionId, setTransactioId] = useState(0);
  const [modal, setModal] = useState(false);
  const [buyUnitsModal, setBuyUnitsModal] = useState(false);
  const [filterData, setFilterData] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [getPayments, { data }] =
    paymentApi.endpoints.getPayments.useLazyQuery();
  const { payments, metadata } = data || {};

  const toggle = (transid) => {
    if (transid) {
      setTransactioId(transid);
    }
    setModal(!modal);
  };

  const unToggle = () => {
    setTransactioId('');
    setModal(!modal);
  };

  useEffect(() => {
    getPayments({
      userType,
      page: currentPage,
      limit,
      filterOptions: { customerId: id, ...filterData }
    });
  }, [dispatch, userType, id, currentPage, limit, paystackRefId]);

  const nextPage = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
  };

  const previousPage = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage - 1);
  };

  const rowCountChange = (event) => {
    const { value } = event.target;
    setLimit(parseInt(value, 10));
  };

  const filterPayments = () => {
    return payments.map((payment) => {
      return (
        <tr key={payment.id}>
          <td>
            <button
              className="button button-transparent button-nopadding text-capitalize"
              title="Click to view"
              onClick={() => toggle(payment.id)}
            >
              {payment.transactionReference || payment.transactionId}
            </button>
          </td>
          <td className="text-capitalize">
            <CopyText textValue={payment.utilityToken} />
          </td>
          <td>{payment.meterName || payment.meterNo}</td>
          <td>
            {formatCurrency(payment.amount / 100)}
            <br />
            <small>{payment.unitsBought} units</small>
          </td>
          <td className="text-capitalize">{payment.channel}</td>
          <td>{dateFormater(payment.createdAt)}</td>
          <td>
            <span
              className={
                payment.status &&
                `bubble__status bubble--${payment.status.split(' ').join('-')}`
              }
            >
              {payment.status || 'NA'}
            </span>
          </td>
        </tr>
      );
    });
  };

  const paymentListing =
    payments && payments.length > 0 ? filterPayments() : {};

  return (
    <>
      <div className="transactions">
        <section className="square-tables">
          {payments ? (
            <>
              <table className="table table-borderless mb-0 noborder--bottom">
                <thead>
                  <tr>
                    <th colSpan={header.length}>
                      <div className="row">
                        <div className="col-md-7">
                          <h3 className="medium-heading">
                            All Payments
                            <span> - {payments && payments.length} </span>
                          </h3>
                        </div>
                        <div className="col-md-5 button-controls">
                          <FilterPopover entity="payment">
                            <PaymentFilter
                              metadata={metadata}
                              filterHandler={getPayments}
                              customerId={id}
                              filterSet={() => null}
                              setFilterData={setFilterData}
                            />
                          </FilterPopover>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              <Table
                className="noborder--top"
                columns={header}
                dataSource={paymentListing}
                noContent={
                  <>
                    <NoContent
                      icon="house"
                      title="No payments have been created yet"
                      caption="All payments will be displayed here"
                    />
                    <div className="text-center  mb-5 pb-5">
                      <button
                        className="button button-primary button-lowcase align-center"
                        onClick={() => {
                          setBuyUnitsModal(!buyUnitsModal);
                        }}
                      >
                        <i className="iconmoon icon-add"></i>
                        <span>Buy Units</span>
                      </button>
                    </div>
                  </>
                }
              />
              <div className="pagination d-flex justify-content-between">
                <div className="pagination__controls-selectbox">
                  <label className="me-2 mt-1">Rows per page</label>
                  <select onChange={rowCountChange} value={limit}>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="25">25</option>
                  </select>
                </div>

                <div className="pagination__controls">
                  <label>{`showing page ${metadata.currentPage} of ${metadata.totalPages}`}</label>
                  <button
                    className="button button-small button-transparent pagination__navigation"
                    disabled={metadata.previousPage === null}
                    onClick={previousPage}
                  >
                    <i className="iconmoon icon-angle-right"></i>
                  </button>
                  <button
                    className="button button-small button-transparent pagination__navigation"
                    disabled={metadata.nextPage === null}
                    onClick={nextPage}
                  >
                    <i className="iconmoon icon-angle-left"></i>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <LoadingContent />
          )}
        </section>
      </div>
      <UseModal
        toggle={unToggle}
        entityId={transactionId}
        modal={modal}
        component={ViewTransaction}
        backdrop={true}
      />
      <UseModal
        modal={buyUnitsModal}
        toggle={() => setBuyUnitsModal(!buyUnitsModal)}
        component={BuyUnitsCustomer}
      />
    </>
  );
};
