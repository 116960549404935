import { lazy } from 'react';

const Dashboard = lazy(() => import('pages/Dashboard'));
const Estate = lazy(() => import('pages/Estate'));
const EstateDetails = lazy(() => import('pages/EstateDetails'));
const Settings = lazy(() => import('pages/Settings'));
const Contact = lazy(() => import('pages/Contact'));
const Approval = lazy(() => import('pages/Approval'));
const Payments = lazy(() => import('pages/Payments'));
const Tickets = lazy(() => import('pages/Tickets'));
const Complaints = lazy(() => import('pages/Complaints'));
const TicketView = lazy(() => import('pages/TicketView'));
// const Disco = lazy(() => import('pages/Disco'));
const Notifications = lazy(() => import('pages/Notifications'));
const Overview = lazy(() => import('pages/Overview'));
const OverviewEstate = lazy(() => import('pages/OverviewEstate'));
const OverviewLog = lazy(() => import('pages/OverviewLog'));
const RealTime = lazy(() => import('pages/RealTime'));
const RealTimeEstate = lazy(() => import('pages/RealTimeEstate'));
const NotFound = lazy(() => import('pages/NotFound'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));

export {
  Dashboard,
  Estate,
  Settings,
  Approval,
  Payments,
  Complaints,
  // Disco,
  Notifications,
  NotFound,
  PrivacyPolicy,
  EstateDetails,
  Tickets,
  TicketView,
  Overview,
  OverviewEstate,
  OverviewLog,
  RealTime,
  Contact,
  RealTimeEstate
};
