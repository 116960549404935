import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { validateCity } from 'utility';
import { addCity, editCity } from 'store/actions';

export const SaveCity = ({ ...props }) => {
  const dispatch = useDispatch();
  const inProgress = useSelector(state => state.apiCalls);

  const cityDefault = {
    name: '',
    stateId: 0
  };

  const { entity, entityId, close, isEdit } = props;

  const cityDetails = isEdit
    ? { name: entity.city, stateId: entityId }
    : cityDefault;

  const [city, setCity] = useState(cityDetails);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveCity = useCallback(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (isEdit) {
        dispatch(editCity(city, entity.cityId));
        return;
      }

      dispatch(addCity(city));
    }
  }, [city, entity.cityId, isEdit, dispatch, errors, isSubmitting]);

  useEffect(() => {
    saveCity();
    return () => {
      setIsSubmitting(false);
    };
  }, [saveCity]);

  const handleSubmit = event => {
    event.preventDefault();

    setErrors(validateCity(city));
    setCity(prevCity => ({
      ...prevCity,
      stateId: entityId
    }));
    setIsSubmitting(true);
  };

  const handleChange = event => {
    const { name } = event.target;
    const { value } = event.target;

    setCity(prevCity => ({
      ...prevCity,
      [name]: value
    }));
  };

  const handleCancel = event => {
    setCity(cityDefault);
    close(event);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h4 className="heading header-title">
            {isEdit ? 'Edit' : 'Add'} City
          </h4>
          <p>{isEdit ? 'Modify' : 'Enter'} the name of the city</p>
        </div>
        <div className="form__content">
          <div className="form-group">
            <label>
              Name <span className="form-error">{errors.name}</span>
            </label>
            <input
              type="text"
              name="name"
              value={city.name}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group "
              role="group"
              aria-label="Button group example"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="button button-save ms-2"
                onClick={handleSubmit}
                disabled={inProgress}
              >
                {inProgress && <Spinner className="me-3" color="light" />}{' '}
                {isEdit ? 'Save Changes' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

SaveCity.propTypes = {
  close: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  entityId: PropTypes.number,
  entity: PropTypes.object
};
