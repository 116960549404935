import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { formatUnit, formatCurrency } from 'utility';

export const EstateDetailsHeader = ({ details, count, isOpen }) => {
  const person = details.contactPerson || {};

  return (
    <>
      <Collapse className="col-md-12 mt-3" isOpen={isOpen}>
        <div className="row">
          <div className="col-md-6">
            <div className="row residents__buckets">
              <div className="col-md-6">
                <span className="residents__buckets--card">
                  Disco Name:{' '}
                  <span
                    className="residents__buckets--detail"
                    title={details.disco && details.disco.name}
                  >
                    {details.disco && details.disco.name}
                  </span>
                </span>
              </div>

              <div className="col-md-6">
                <span className="residents__buckets--card">
                  Undertaken:{' '}
                  <span className="residents__buckets--detail">
                    {details.businessUnit && details.businessUnit.name}
                  </span>
                </span>
              </div>

              <div className="col-md-6">
                <span className="residents__buckets--card">
                  DUOS Charge:{' '}
                  <span
                    className="residents__buckets--detail"
                    title={details.dosCharge}
                  >
                    {details.dosCharge && formatCurrency(details.dosCharge)}
                  </span>
                </span>
              </div>
              <div className="col-md-6">
                <span className="residents__buckets--card">
                  Disco Tariff:{' '}
                  <span
                    className="residents__buckets--detail"
                    title={details.dosTariff}
                  >
                    {details.dosTariff && formatUnit(details.dosTariff)}
                  </span>
                </span>
              </div>
              <div className="col-md-6">
                <span className="residents__buckets--card">
                  Enaro Tariff:{' '}
                  <span
                    className="residents__buckets--detail"
                    title={details.enaroTariff}
                  >
                    {details.enaroTariff && formatUnit(details.enaroTariff)}
                  </span>
                </span>
              </div>
              <div className="col-md-6">
                <span className="residents__buckets--card">
                  Estate Tariff:{' '}
                  <span className="residents__buckets--detail">
                    {details.estateTariff && formatUnit(details.estateTariff)}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row residents__buckets">
              <div className="col-md-6">
                <span className="residents__buckets--card">
                  Contact Person:{' '}
                  <span
                    className="residents__buckets--detail"
                    title={person.name}
                  >
                    {person.name}
                  </span>
                </span>
              </div>
              <div className="col-md-6">
                <span className="residents__buckets--card">
                  Email Address:{' '}
                  <span
                    className="residents__buckets--detail"
                    title={person.email}
                  >
                    {person.email}
                  </span>
                </span>
              </div>
              <div className="col-md-6">
                <span className="residents__buckets--card">
                  Phone number:{' '}
                  <span
                    className="residents__buckets--detail"
                    title={person.phoneNumber}
                  >
                    {person.phoneNumber}
                  </span>
                </span>
              </div>
              <div className="col-md-6">
                <span className="residents__buckets--card">
                  Residents Count:{' '}
                  <span
                    className="residents__buckets--detail"
                    title={`${count} Residents`}
                  >
                    {count} Residents
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
};

EstateDetailsHeader.propTypes = {
  details: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired
};
