import axios from 'axios';
import { roleConstants } from 'store/constants';
import { toast } from 'sonner';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const fetchRolesSuccess = (roles) => ({
  type: roleConstants.GET_ROLES_SUCCESS,
  payload: roles
});

export const fetchRolesFailure = (error) => ({
  type: roleConstants.GET_ROLES_FAILURE,
  payload: error
});

export const getRoles = () => (dispatch) => {
  axios
    .get(`/roles`)
    .then((response) => {
      if (response.data.status) {
        dispatch(fetchRolesSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(fetchRolesFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const addRoleSuccess = (payload) => ({
  type: roleConstants.ADD_ROLE_SUCCESS,
  payload
});

export const addRoleFailure = (error) => ({
  type: roleConstants.ADD_ROLE_FAILURE,
  payload: error
});

export const addRole = (role, closeModal) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .post(`/roles`, role)
    .then((response) => {
      if (response.data.status) {
        dispatch(addRoleSuccess(response.data));
        dispatch(endApiCall());
        closeModal();
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(addRoleFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const getRoleSuccess = (payload) => ({
  type: roleConstants.GET_ROLE_SUCCESS,
  payload
});

export const getRoleFailure = (error) => ({
  type: roleConstants.GET_ROLE_FAILURE,
  payload: error
});

export const getRole = (roleId) => (dispatch) => {
  axios
    .get(`/roles/${roleId}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getRoleSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getRoleFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const editRoleSuccess = (payload) => ({
  type: roleConstants.EDIT_ROLE_SUCCESS,
  payload
});

export const editRoleFailure = (error) => ({
  type: roleConstants.EDIT_ROLE_FAILURE,
  payload: error
});

export const editRole = (role, closeModal) => (dispatch) => {
  const roleDetails = {
    title: role.title,
    description: role.description,
    addPermissions: role.addPermissions,
    removePermissions: role.removePermissions
  };
  dispatch(beginApiCall());
  axios
    .patch(`/roles/${role.id}`, roleDetails)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(editRoleSuccess(role.currentPermissions));
        dispatch(endApiCall());
        closeModal();
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editRoleFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const deleteRoleSuccess = (payload) => ({
  type: roleConstants.DELETE_ROLE_SUCCESS,
  payload
});

export const deleteRoleFailure = (error) => ({
  type: roleConstants.DELETE_ROLE_FAILURE,
  payload: error
});

export const deleteRole = (roleId) => (dispatch) => {
  axios
    .delete(`/roles/${roleId}`)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(deleteRoleSuccess(roleId));
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(deleteRoleFailure(error.response.data));
      } else {
        throw error;
      }
    });
};
