import { userConstants } from '../constants';
import initialState from './initialState';

const userReducer = (state = initialState.users, action) => {
  switch (action.type) {
    case userConstants.CREATE_USER_FAILURE:
      return { ...state };
    case userConstants.CREATE_USER_SUCCESS:
      return { ...state, item: action.payload.data };
    case userConstants.GET_USER_FAILURE:
      return { ...state };
    case userConstants.GET_USER_SUCCESS:
      return { ...state, item: action.payload.data };
    case userConstants.GET_USERS_FAILURE:
      return { ...state };
    case userConstants.GET_USERS_SUCCESS:
      return { ...state, items: action.payload.data };
    case userConstants.EDIT_USER_FAILURE:
      return { ...state };
    case userConstants.EDIT_USER_SUCCESS:
      return { ...state };
    case userConstants.UPLOAD_USERS_FAILURE:
      return { ...state };
    case userConstants.UPLOAD_USERS_SUCCESS:
      return { ...state };
    case userConstants.GET_USER_BY_METER_FAILURE:
      return { ...state, item: { estate: { name: '' }, user: { name: '' } } };
    case userConstants.GET_USER_BY_METER_SUCCESS:
      return { ...state, item: action.payload.data };
    case userConstants.METER_RESET_SUCCESS:
      return { ...state, item: { estate: { name: '' }, user: { name: '' } } };
    case userConstants.GET_METERS_FAILURE:
      return { ...state, meters: [] };
    case userConstants.GET_METERS_SUCCESS:
      return { ...state, meters: [...action.payload.data] };
    default:
      return state;
  }
};

export default userReducer;
