import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const ConsumptionGraph = () => {
  const options = {
    chart: {
      type: 'areaspline'
    },
    title: {
      text: 'Monthly Average Power Consumption'
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    yAxis: {
      title: {
        text: 'NGN Amount'
      }
    },
    tooltip: {
      shared: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.1
      }
    },
    series: [
      {
        data: [
          3400,
          4000,
          8800,
          5000,
          4500,
          3200,
          3500,
          3500,
          4400,
          3500,
          1200,
          4500
        ],
        name: 'Months'
      }
    ]
  };

  return (
    <div className="graph">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
