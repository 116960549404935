import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const DashboardBuckets = ({ data, url }) => {
  return (
    <div className="bucket mb-2">
      <p className="bucket__title">{data && data.title}</p>
      <p className="bucket__figure">{data && data.value}</p>
      {data.link && (
        <Link to={`${url}`}>
          <span className="bucket__link">{data.link}</span>
        </Link>
      )}
    </div>
  );
};

DashboardBuckets.propTypes = {
  data: PropTypes.object.isRequired,
  url: PropTypes.string
};
