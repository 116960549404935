import { createApi } from '@reduxjs/toolkit/query/react';
import { getOptionsId } from 'utility';
import { baseQuery } from 'features/baseQuery';

const formatValues = (editOptions) => {
  // eslint-disable-next-line no-unused-vars
  const { discoStaff, isDeactivated, staffIds, ...options } = editOptions;

  return {
    ...options,
    assignStaff: getOptionsId(options.assignStaff),
    unassignStaff: getOptionsId(options.unassignStaff)
  };
};

export const discoApi = createApi({
  reducerPath: 'discoApi',
  baseQuery,
  endpoints: (builder) => ({
    getDiscos: builder.query({
      query: () => `/discos`,
      transformResponse: (response) => response.data,
      providesTags: ['Disco']
    }),
    getDisco: builder.query({
      query: (discoId) => `/discos/${discoId}`,
      transformResponse: (response) => {
        if (response.status) {
          return response;
        }
        throw new Error('Failed to fetch disco');
      },
      providesTags: ['Disco']
    }),
    addDisco: builder.mutation({
      query: (disco) => ({
        url: '/discos',
        method: 'POST',
        body: {
          ...disco,
          staffIds: getOptionsId(disco.staffIds)
        }
      }),
      invalidatesTags: ['Disco']
    }),
    editDisco: builder.mutation({
      query: ({ editOptions, discoId }) => ({
        url: `/discos/${discoId}`,
        method: 'PATCH',
        body: formatValues(editOptions)
      }),
      invalidatesTags: ['Disco']
    }),
    deactivateDisco: builder.mutation({
      query: ({ action, entityId }) => ({
        url: `/discos/${entityId}/action`,
        method: 'POST',
        body: { action: action.isDeactivated ? 'deactivated' : 'activated' }
      }),
      invalidatesTags: ['Disco']
    }),
    getDiscoEstates: builder.query({
      query: (discoId) => `/discos/${discoId}/estates`,
      transformResponse: (response) => {
        if (response.status) {
          return response;
        }
        throw new Error('Failed to fetch disco estates');
      },
      providesTags: ['DiscoEstates']
    }),
    deleteDisco: builder.mutation({
      query: (discoId) => ({
        url: `/discos/${discoId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Discos', 'Disco']
    })
  })
});

export const {
  useGetDiscosQuery,
  useGetDiscoQuery,
  useAddDiscoMutation,
  useEditDiscoMutation,
  useDeactivateDiscoMutation,
  useGetDiscoEstatesQuery,
  useDeleteDiscoMutation
} = discoApi;
