import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { validateCity } from 'utility';
import { useAddUnitMutation } from 'features/api';

export const SaveUnits = ({ ...props }) => {
  const { close } = props;
  const [addUnit, { isLoading, isSuccess }] = useAddUnitMutation();
  const [unit, setUnit] = useState({ name: '' });
  const [errors, setErrors] = useState({});
  const SaveUnit = useCallback(() => {
    if (Object.keys(errors).length === 0) {
      addUnit(unit);
    }
  }, [unit]);

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  const handleSubmit = (event) => {
    event.preventDefault();

    setErrors(validateCity(unit));
    SaveUnit();
  };

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setUnit((prevCity) => ({
      ...prevCity,
      [name]: value
    }));
  };

  const handleCancel = (event) => {
    setUnit({ name: '' });
    close(event);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h4 className="heading header-title">Add Undertaken</h4>
          <p>Enter the name of the unit</p>
        </div>
        <div className="form__content">
          <div className="form-group">
            <label>
              Name <span className="form-error">{errors.name}</span>
            </label>
            <input
              type="text"
              name="name"
              value={unit.name}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group "
              role="group"
              aria-label="Button group example"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="button button-save ms-2"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading && <Spinner className="me-3" color="light" />} Save
                Unit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

SaveUnits.propTypes = {
  close: PropTypes.func.isRequired,
  entityId: PropTypes.number,
  entity: PropTypes.object
};
