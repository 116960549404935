export default {
  'view-dashboard': {
    component: 'Dashboard',
    url: '/dashboard',
    title: 'Dashboard',
    icon: 'icon-pie',
    order: 1,
    nav: true
  },
  'view-approval-log': {
    component: 'Approval',
    url: '/approval',
    title: 'Approval Log',
    icon: 'icon-log',
    order: 2,
    nav: true
  },
  'view-estate': {
    component: 'Estate',
    url: '/estate',
    title: 'Estate Management',
    icon: 'icon-estate',
    order: 3,
    nav: true
  },
  overview: {
    component: 'Overview',
    url: '/overview',
    title: 'Estate Overview',
    icon: 'icon-utility',
    order: 4,
    nav: true
  },
  'view-complaint': {
    component: 'Tickets',
    url: '/complaint',
    title: 'Complaint',
    icon: 'icon-ticket',
    order: 5,
    nav: true
  },
  'view-ticket': {
    component: 'Tickets',
    url: '/ticket',
    title: 'Tickets',
    icon: 'icon-ticket',
    order: 6,
    nav: true
  },
  'view-notification': {
    component: 'Notifications',
    url: '/notification',
    title: 'Notifications',
    icon: 'icon-notif',
    order: 7,
    nav: true
  },
  'view-payment': {
    component: 'Payments',
    url: '/payment',
    title: 'Payments',
    icon: 'icon-payment',
    order: 8,
    nav: true
  },
  'view-realtime': {
    component: 'RealTime',
    url: '/realtime',
    title: 'RealTime',
    icon: 'icon-realtime',
    nav: true,
    order: 9
  },
  'overview-source': {
    component: 'OverviewEstate',
    url: '/overview/estate',
    title: 'Utility',
    nav: false
  },
  'total-revenue-overview': {
    component: 'OverviewLog',
    url: '/overview/log',
    title: 'Utility Log',
    nav: false
  },
  'view-profile': {
    component: 'Settings',
    url: '/setting',
    title: 'Settings',
    icon: 'icon-setting',
    order: 11,
    nav: true
  },
  'view-meter': {
    component: 'Contact',
    url: '/contact-us',
    title: 'Contact Us',
    order: 12,
    icon: 'icon-overview',
    nav: true
  },
  'view-resident': {
    component: 'EstateDetails',
    url: '/estate/:id',
    title: 'Estate Management',
    nav: false
  },
  'disco-real-time': {
    component: 'RealTimeEstate',
    url: '/realtime/estate',
    title: 'Real Time',
    icon: 'icon-overview'
  }
};
