import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useDeactivateEstateMutation } from 'features/api';
import DeactivateUser from './DeactivateUser';

export const EstatePrompt = ({ modal, toggle, status, entityId, name }) => {
  const [deactivateEstate, { isLoading, isSuccess }] =
    useDeactivateEstateMutation();
  const closePrompt = (event) => {
    toggle(event);
  };

  useEffect(() => {
    if (isSuccess) {
      toggle();
    }
  }, [isSuccess]);

  const deactivateUser = (event) => {
    event.preventDefault();
    deactivateEstate({ estateId: entityId, status });
  };

  return (
    <Modal className="prompt" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody>
        <DeactivateUser status={status} name={name} />
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <button
          type="button"
          className="button button-primary"
          onClick={closePrompt}
        >
          Cancel
        </button>

        <button
          type="button"
          className="button button-secondary ms-2"
          onClick={deactivateUser}
          disabled={isLoading}
        >
          {isLoading && <Spinner className="me-3" color="light" />} Yes,{' '}
          {status ? 'Re-activate' : 'Deactivate'}
        </button>
      </ModalFooter>
    </Modal>
  );
};

EstatePrompt.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  status: PropTypes.bool,
  name: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
