import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'features/baseQuery';
import { getSerializedParams } from 'utility';
import { toast } from 'sonner';

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery,
  tagTypes: ['Payment'],
  endpoints: (builder) => ({
    getPayments: builder.query({
      query: ({ userType, page, limit, filterOptions }) => {
        const endpoint =
          userType === 'customer'
            ? `/payments/?page=${page}&limit=${limit}`
            : `/payments?page=${page}&limit=${limit}`;

        const filters = getSerializedParams(filterOptions);
        const url = `${endpoint}${filters ? `&${filters}` : ''}`;

        return { url };
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      transformResponse: (response) => response.data,
      providesTags: ['Payment']
    }),
    getPaymentsAggregates: builder.query({
      query: () => '/payments',
      providesTags: ['Payment']
    }),
    getPayment: builder.query({
      query: (paymentId) => `/payments/${paymentId}`,
      transformResponse: (response) => response.data,
      providesTags: ['Payment']
    }),
    getBreakdown: builder.query({
      query: (filter) => {
        filter = {
          ...filter
        };

        const queryParams = getSerializedParams(filter);
        return `/payments/breakdown${queryParams ? `?${queryParams}` : ''}`;
      },
      transformResponse: (response) => response.data,
      providesTags: ['Payment']
    }),
    addPayment: builder.mutation({
      query: (payment) => ({
        url: '/payments',
        method: 'POST',
        body: payment
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ['Payment']
    }),
    initializeCustomerPayment: builder.mutation({
      query: (payment) => {
        return {
          url: '/payments/initialize',
          method: 'POST',
          body: payment
        };
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          toast.success('Please wait to input card details.');
          window.location = response.data.data.authUrl;
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ['Payment']
    }),
    updateSubscriptionStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `/subscriptions/${id}/subscriptionStatus`,
        method: 'PATCH',
        body: { isSubscriptionActive: status }
      })
    })
  })
});

export const {
  useGetPaymentsQuery,
  useGetPaymentsAggregatesQuery,
  useGetPaymentQuery,
  useGetBreakdownQuery,
  useAddPaymentMutation,
  useInitializeCustomerPaymentMutation,
  useUpdateSubscriptionStatusMutation
} = paymentApi;
