import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buildRoutes } from 'utility';
import { getPermission } from 'store/actions';
import config from '../permission/config';
import { rolesApi } from 'features/api';

const UseRoutes = () => {
  const dispatch = useDispatch();

  const [routes, setRoutes] = useState([]);
  const { roleId } = useSelector((state) => state.profile);
  const { loggedIn } = useSelector((state) => state.auth);
  const [getRole, result] = rolesApi.endpoints.getRole.useLazyQuery();
  const userRole = result.data || {};

  useEffect(() => {
    if (loggedIn && roleId) {
      getRole(roleId);
      dispatch(getPermission());
    }
  }, [dispatch, loggedIn, roleId]);

  useEffect(() => {
    const allowedRoutes = [];

    if (userRole !== undefined) {
      if (userRole?.permissions !== undefined) {
        const userRoutes = buildRoutes(userRole, config);
        setRoutes(userRoutes);
      }
    }

    allowedRoutes.filter((route) => {
      return route !== null;
    });

    return () => {};
  }, [userRole?.id, roleId]);

  return { routes };
};

export default UseRoutes;
