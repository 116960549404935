import { exportConstants } from '../constants';
import initialState from './initialState';

const exportReducer = (state = initialState.exports, action) => {
  switch (action.type) {
    case exportConstants.EXPORT_PAYMENT_FAILURE:
      return { ...state };
    case exportConstants.EXPORT_PAYMENT_SUCCESS:
      return { ...state, [action.payload.option]: action.payload.url };
    case exportConstants.EXPORT_TICKETS_FAILURE:
      return { ...state };
    case exportConstants.EXPORT_TICKETS_SUCCESS:
      return { ...state, [action.payload.option]: action.payload.url };
    default:
      return state;
  }
};

export default exportReducer;
