import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({ baseUrl: '/api' }); // Assuming your API base URL is '/api'

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery,
  tagTypes: ['Subscriptions'],
  endpoints: builder => ({
    fetchSubscriptionByMeterNo: builder.query({
      query: meterNo => `/subscriptions?meterNo=${meterNo}`,
      providesTags: ['Subscriptions']
    }),
    updateSubscription: builder.mutation({
      query: ({ id, meterNo }) => ({
        url: `/subscriptions/${id}`,
        method: 'PATCH',
        body: {
          isActive: false,
          meterNo
        }
      }),
      invalidatesTags: ['Subscriptions']
    })
  })
});

export const {
  useFetchSubscriptionByMeterNoQuery,
  useUpdateSubscriptionMutation
} = subscriptionApi;
