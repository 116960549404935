import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FilterPopover, NoContent, TicketsFilter } from 'components';
import { dateFormater } from 'utility';
import Check from '../permission/Check';

export const StaffTickets = ({
  tickets,
  metadata,
  category,
  exportLink,
  openModal,
  filterHandler,
  isFilter
}) => {
  const { getAllTickets, filterSet } = filterHandler;
  const aggreateString = `${category}Count`;

  const renderTableHeader = () => (
    <thead className="table-header">
      <tr>
        <th colSpan="6">
          <div className="row">
            <div className="col-md-7">
              {isFilter !== 'filtered' ? (
                <span className="section-heading">
                  All tickets on{' '}
                  <span>
                    {category} -{' '}
                    {metadata && metadata.categoryAggregates[aggreateString]}
                  </span>
                </span>
              ) : (
                <span className="section-heading">
                  Filtered tickets on{' '}
                  <span>
                    {category} -{' '}
                    {metadata && metadata.categoryAggregates[aggreateString]}
                  </span>
                </span>
              )}
            </div>
            <div className="col-md-5 button-controls">
              <Check permission="create-ticket">
                <button
                  className="button button-primary button-small button-lowcase mx-1"
                  onClick={openModal}
                >
                  <i className="iconmoon icon-add"></i>
                  <span>New Ticket</span>
                </button>
              </Check>

              <a
                className="button button-primary button-small text-capitalize mx-1"
                download
                target="_blank"
                href={exportLink}
                rel="noreferrer"
              >
                <i className="iconmoon icon-download"></i> Export
              </a>

              <FilterPopover entity={category}>
                <TicketsFilter
                  category={category}
                  filterHandler={getAllTickets}
                  filterSet={filterSet}
                />
              </FilterPopover>
            </div>
          </div>
        </th>
      </tr>
      <tr>
        <th className="text-uppercase">TicketID</th>
        <th className="text-uppercase">Estate/Resident</th>
        <th className="text-uppercase">Meter No</th>
        <th className="text-uppercase">Title</th>
        <th className="text-uppercase">Created</th>
        <th className="text-uppercase">Status</th>
      </tr>
    </thead>
  );

  const noContent = () => (
    <>
      <NoContent
        icon="no-ticket"
        title={
          isFilter !== 'filtered'
            ? 'No Tickets have been created yet'
            : 'No matching results. Reset filter to see all tickets'
        }
        caption="All tickets will be displayed here"
      />
      <div className="text-center pb-5 mb-5">
        <Check permission="create-ticket">
          <button
            className="button button-primary button-lowcase align-center"
            onClick={openModal}
          >
            <i className="iconmoon icon-add"></i>
            <span>New Ticket</span>
          </button>
        </Check>
      </div>
    </>
  );

  const ticketListing = () =>
    tickets.map((ticket, index) => {
      const {
        id,
        title,
        ticketId,
        status,
        meterNo,
        // eslint-disable-next-line camelcase
        estate_user,
        createdAt
      } = ticket;

      return (
        <tr key={index}>
          <td className="text-capitalize">
            <Link title="Click to view" to={`/app/ticket/${id}`}>
              {ticketId}
            </Link>
          </td>
          <td>
            <Link to={`${id}`}>
              {estate_user.estate.name}
              <br />
              <small>{estate_user.user.name}</small>
            </Link>
          </td>
          <td>{meterNo}</td>
          <td>
            <Link to={`${id}`}>{title}</Link>
          </td>
          <td>{dateFormater(createdAt)}</td>
          <td>
            <span
              className={`bubble__status bubble--${status
                .split(' ')
                .join('-')}`}
            >
              {status}
            </span>
          </td>
        </tr>
      );
    });

  const renderTableData = () => {
    const tableContent =
      tickets.length > 0 ? (
        ticketListing()
      ) : (
        <tr>
          <td colSpan="6">{noContent()}</td>
        </tr>
      );
    return tableContent;
  };

  return (
    <section className="ticket__listing square-tables table-responsive">
      <table className="table table-borderless">
        {renderTableHeader()}
        <tbody className="table-content">{renderTableData()}</tbody>
      </table>
    </section>
  );
};

StaffTickets.propTypes = {
  category: PropTypes.string.isRequired,
  // results: PropTypes.object.isRequired,
  exportLink: PropTypes.string.isRequired,
  filterHandler: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFilter: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  metadata: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tickets: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
