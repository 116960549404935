import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { comparer, filterOptions, isObjectEmpty, validateDisco } from 'utility';
import {
  useAddDiscoMutation,
  useEditDiscoMutation,
  useGetDiscosQuery,
  useGetRolesQuery,
  useGetUsersQuery
} from 'features/api';
import { PhoneNumberInput } from 'components/PhoneNumberInput';

export const SaveDisco = ({ ...props }) => {
  const discoDefault = {
    name: '',
    email: '',
    phoneNumber: '',
    address: '',
    staffIds: []
  };

  const { close, isEdit, entityId } = props;

  const { data: staffList } = useGetUsersQuery('staff');
  const { data } = useGetRolesQuery();
  const roles = data || [];
  const discoRole = roles.find((role) => role.title === 'Disco') || {};

  const [addDisco, { isSuccess: isCreated, isLoading: isCreating }] =
    useAddDiscoMutation();
  const [editDisco, { isSuccess: isEdited, isLoading: isEditing }] =
    useEditDiscoMutation();

  const staffs =
    staffList.filter((person) => person.roleId === discoRole?.id || '') || [];

  const { data: discoList = [] } = useGetDiscosQuery();

  const [disco, setDisco] = useState(discoDefault);
  const discoStaffList = disco.staffIds || [];
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState(discoStaffList);

  useEffect(() => {
    if (entityId && discoList.length > 0) {
      const discoInfo = discoList.find((disco) => disco.id === entityId) || {};
      setDisco((prevState) => ({
        ...prevState,
        ...discoInfo,
        staffIds: filterOptions(discoInfo.discoStaff)
      }));
    }
  }, [entityId, discoList]);

  const saveDisco = (disco) => {
    if (Object.keys(errors).length === 0) {
      delete disco.id;
      delete disco.createdAt;
      delete disco.updatedAt;

      if (isEdit) {
        editDisco({
          editOptions: disco,
          discoId: entityId
        });
        return;
      }

      addDisco(disco, close);
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (isCreated || isEdited) {
      close?.();
    }
  }, [isCreated, isEdited]);
  console.log(isCreating || isEditing, 'dfl');
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateDisco(disco);
    setErrors(errors);
    const isEmpty = isObjectEmpty(errors);
    if (isEmpty) {
      saveDisco(disco);
    }
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setErrors(validateDisco(disco));

    if (selected.length < 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        staffIds: 'Please select at least one staff'
      }));
    } else {
      saveDisco({
        ...disco,
        assignStaff: disco.staffIds,
        unassignStaff: filterOptions(disco.discoStaff).filter(
          comparer(disco.staffIds)
        )
      });
    }
  };

  const handleChange = (event) => {
    let { value, name } = event.target;

    setDisco((prevDisco) => ({
      ...prevDisco,
      [name]: value
    }));
  };

  const handlePhone = (value) => {
    setDisco((prevDisco) => ({
      ...prevDisco,
      phoneNumber: value
    }));
  };

  const handleSelect = (option) => {
    setSelected(option);
    setDisco((prevDisco) => ({
      ...prevDisco,
      staffIds: option
    }));
  };

  const handleCancel = (event) => {
    setDisco(discoDefault);
    close(event);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h4 className="heading header-title">
            {isEdit ? 'Edit' : 'Add'} Disco
          </h4>
          <p className="paragraph header-text">
            {isEdit ? 'Edit' : 'Enter'} Disco details and save
          </p>
        </div>
        <div className="form__content row gap-3">
          <div className="form-group">
            <label>
              Name <span className="form-error">{errors.name}</span>
            </label>
            <input
              type="text"
              name="name"
              value={disco.name}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>
              Email <span className="form-error">{errors.email}</span>
            </label>
            <input
              type="text"
              name="email"
              value={disco.email}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Phone Number{' '}
              <span className="form-error">{errors.phoneNumber}</span>
            </label>
            <PhoneNumberInput
              placeholder="7045637890"
              value={disco.phoneNumber}
              onChange={handlePhone}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Address <span className="form-error">{errors.address}</span>
            </label>
            <input
              type="text"
              name="address"
              value={disco.address}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Select Disco Staff(s){' '}
              <span className="form-error">{errors.staffIds}</span>
            </label>
            <Select
              closeMenuOnSelect={false}
              name="staffIds"
              value={selected}
              onChange={handleSelect}
              options={filterOptions(staffs)}
              isMulti
              isSearchable
              required
            />
          </div>
        </div>

        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group "
              role="group"
              aria-label="Button group example"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="button button-save ms-2"
                onClick={isEdit ? handleEdit : handleSubmit}
                disabled={isCreating || isEditing}
              >
                {isCreating ||
                  (isEditing && <Spinner className="me-3" color="light" />)}
                {isEdit ? 'Save Changes' : 'Save Disco'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

SaveDisco.propTypes = {
  close: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  data: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
