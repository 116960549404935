import React from 'react';
import PropTypes from 'prop-types';

export const BackButton = ({ navigate }) => {
  return (
    <button
      className="button button-transparent button-lowcase button-nopadding"
      onClick={() => navigate(-1)}
    >
      <i className="iconmoon icon-back-arrow border-fill"></i>
      <span>Back</span>
    </button>
  );
};

BackButton.propTypes = {
  navigate: PropTypes.func.isRequired
};
