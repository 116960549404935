import { createApi } from '@reduxjs/toolkit/query/react';
import { urlFormatter } from 'utility';
import { baseQuery } from 'features/baseQuery';
import { toast } from 'sonner';

export const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery,
  tagTypes: ['Tickets'],
  endpoints: (builder) => ({
    addTicket: builder.mutation({
      query: (ticket) => ({
        url: '/tickets',
        method: 'POST',
        body: ticket
      }),
      invalidatesTags: ['Tickets'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      }
    }),
    getTickets: builder.query({
      // eslint-disable-next-line no-unused-vars
      query: ({ category, userType, userId, filterOptions }) => {
        const endpoint = userType === 'customer' ? `/tickets?` : `/tickets?`;
        return urlFormatter(endpoint, filterOptions, category);
      },
      transformResponse: (response) => response.data,
      providesTags: (result, error, { category }) => [
        { type: 'Tickets', id: category || 'all' }
      ]
    }),
    getTicket: builder.query({
      query: (ticketId) => `/tickets/${ticketId}`,
      transformResponse: (response) => response.data
    }),
    updateTicket: builder.mutation({
      query: ({ ticketId, action }) => ({
        url: `/tickets/${ticketId}/status`,
        method: 'PATCH',
        body: { status: action }
      }),
      invalidatesTags: ['Tickets'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      }
    }),
    addComment: builder.mutation({
      query: (ticketId, comment) => ({
        url: `/tickets/${ticketId}`,
        method: 'POST',
        body: { comment: comment.message }
      }),
      invalidatesTags: ['Tickets'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      }
    })
  })
});

export const {
  useAddTicketMutation,
  useGetTicketsQuery,
  useGetTicketQuery,
  useUpdateTicketMutation,
  useAddCommentMutation
} = ticketsApi;
