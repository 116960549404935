export const stringToBoolean = (value) => {
  const options = {
    true: true,
    false: false
  };
  return options[value];
};

export const removeDuplicates = (arr) => {
  if (!Array.isArray(arr)) {
    return [];
  }
  return arr.filter(
    (obj, index, self) => index === self.findIndex((t) => t.id === obj.id)
  );
};

export const filterPermissions = (permissions) => {
  const options = permissions.map((permission) => {
    return { label: permission.displayTitle, value: permission.id };
  });
  return options;
};

export const filterEstates = (estates) => {
  const options = estates.map((estate) => {
    return { label: estate.name, value: estate.id };
  });

  return options;
};

export const filterOptions = (items) => {
  if (items !== undefined) {
    const options = items.map((item) => {
      return {
        label: item.displayTitle || item.name,
        value: item.id,
        title: item.title || item.name
      };
    });

    return options;
  }
};

export const getOptionsId = (options) => {
  return options !== undefined ? options.map((option) => option.value) : [];
};

export const parseEstate = () => {
  const details = {
    buckets: [
      { title: 'Total Revenue Stream', value: 173 },
      { title: 'Average Revenue ', value: 213 },
      { title: 'Total Units', value: 100 },
      { title: 'Resident Count', value: 50 }
    ]
  };

  return details;
};

export const getPermissions = (permissionsList, selectedPermissions) => {
  const perms = [];

  permissionsList.forEach((permission) => {
    selectedPermissions.forEach((permLabel) => {
      if (permission.id === permLabel.value) {
        perms.push(permission);
      }
    });
  });

  return perms;
};

export const comparePermission = (original) => {
  return (current) => {
    return (
      original.filter((other) => {
        return other.value === current.value && other.label === current.label;
      }).length === 0
    );
  };
};

export const comparer = (original) => {
  return (current) => {
    return (
      original.filter((other) => {
        return other.value === current.value && other.label === current.label;
      }).length === 0
    );
  };
};

export const getPermissionsById = (permOption) => {
  return permOption !== undefined
    ? permOption.map((option) => option.value)
    : [];
};

export const getRolePermission = (roles = [], roleId) => {
  return Object.values(roles).filter((role) => {
    return role.id === roleId;
  });
};

export const getPermissionTitle = (permissions) => {
  return permissions.map((permission) => {
    return permission.title;
  });
};

export const buildRoutes = (userRole, config) => {
  const routes = [];

  userRole.permissions.forEach((permission) => {
    if (config[permission.title] && config[permission.title].nav) {
      routes[config[permission.title].order] = config[permission.title];
    }
  });

  const filtered = routes.filter((route) => {
    return route !== '';
  });

  return filtered;
};

export const getNameSpaces = (nameSpaces, userRole = { permissions: [] }) => {
  const permissions = userRole.permissions.map((permission) => {
    return permission.title;
  });

  const spaces = Object.entries(nameSpaces).map((space) => {
    return space[0];
  });

  return permissions.filter((permission) => {
    return spaces.includes(permission);
  });
};

export const parseMenuTitle = (title, userType) => {
  let linkTitle = title;
  if (title === 'Settings' && userType === 'customer') {
    linkTitle = 'Profile';
  }

  if (title === 'Payments' && userType === 'customer') {
    linkTitle = 'Transactions';
  }

  return linkTitle;
};

export const getRoleTitle = (roles) => {
  const rolesList = {};
  roles.forEach((role) => {
    rolesList[role.id] = role.title;
  });

  return rolesList;
};

export const dateFormater = (date, format) => {
  if (date) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    const formattedDate = new Date(date);

    const year = formattedDate.getFullYear();
    const month = months[formattedDate.getMonth()];
    const digimonth = (formattedDate.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');
    let hours = formattedDate.getHours();
    let minutes = formattedDate.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${hours}:${minutes}${ampm}`;

    switch (format) {
      case 'time':
        return `${day}-${month}-${year} ${time}`;
      case 'reverse':
        return `${year}-${digimonth}-${day}`;
      default:
        return `${day}/${digimonth}/${year} - ${time}`;
    }
  }
};

function timeConvert(num) {
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return `${rhours}hours, ${rminutes} mins ago`;
}

export const timeDifference = (time) => {
  const today = new Date().getTime();
  const then = new Date(time).getTime();

  const minus = (today - then) / (1000 * 60);
  const diff = Math.abs(Math.round(minus));

  let timeDiff = '';

  if (diff < 121) {
    timeDiff = `${diff} mins ago`;
  } else {
    timeDiff = timeConvert(diff);
  }

  return timeDiff;
};

export const statusParser = (status, userType) => {
  switch (true) {
    case status === 'pending' && userType === 'staff':
      return [{ title: 'Start', value: 'in progress' }];
    case status === 'in progress' && userType === 'staff':
      return [{ title: 'Resolve', value: 'resolved' }];
    case status !== 'closed' && userType === 'customer':
      return [{ title: 'Close', value: 'closed' }];
    case 'closed':
      return [];
    default:
  }
};

export const urlFormatter = (endpoint, options, category) => {
  let url = `${endpoint}`;

  const from =
    options && options.startDate !== undefined && options.startDate !== null
      ? options.startDate
      : '';

  const to =
    options && options.endDate !== undefined && options.endDate !== null
      ? options.endDate
      : '';

  if (options && options.estate && options.estate.length > 0) {
    url = `${url}&estate=${options.estate}`;
  }
  if (options && options.meterNo && options.meterNo.length > 0) {
    url = `${url}&meterNo=${options.meterNo}`;
  }
  if (options && options.status && options.status.length > 0) {
    url = `${url}&status=${options.status}`;
  }
  if (options && from) {
    url = `${url}&from=${from}`;
  }
  if (options && to) {
    url = `${url}&to=${to}`;
  }

  if (category) {
    url = `${url}&category=${category}`;
  }

  return url;
};

export const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatEnergy = (num) => {
  return `${num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}kWh`;
};

export const formatCurrency = (num) => {
  return `₦${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatUnit = (num) => {
  if (!num) {
    return '';
  }
  return `₦${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}/kWh`;
};

export const numberFormatter = (number) => {
  return new Intl.NumberFormat('en-US').format(number);
};

export const tariffTotal = (tariffs) => {
  const total = tariffs.reduce((first, next) => {
    return first + next;
  });

  const tariff = !Number.isNaN(total) ? formatUnit(total) : 0;
  return tariff;
};

export const revenueTotal = (revenues) => {
  const total = revenues.reduce((first, next) => {
    return first + next;
  });

  const revenue = !Number.isNaN(total) ? formatCurrency(total) : 0;
  return revenue;
};

export const parseOverviewBuckets = (data) => {
  const { residentsCount, energySupplied, energyConsumed } = data;
  const energyLoss = parseInt(energySupplied - energyConsumed, 10);
  const buckets = [
    { title: 'Number of Residents', value: residentsCount },
    { title: 'Total Energy Supplied', value: formatEnergy(energySupplied) },
    { title: 'Total Energy Consumed', value: formatEnergy(energyConsumed) },
    { title: 'Total Energy Loss', value: formatEnergy(energyLoss) }
  ];
  return buckets;
};

export const includeScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const getQueryParams = (location, params) => {
  const search = new URLSearchParams(location.search);
  return search.get(params);
};

export const calculateEstateUnits = (amount, tariff) => {
  return amount / tariff;
};

export const checkPaymentValues = (parameters) => {
  const { name, value, tariff, user, handleTariff, resetForm, setUnit } =
    parameters;

  if (name === 'meterno' && value !== '') {
    handleTariff(value);
    // setDisableAmount(false);
  }
  if (name === 'meterno' && value === '') {
    resetForm();
  }
  if (user && name === 'amount') {
    setUnit(value / tariff);
  }
};

export const NairaToKobo = (amount) => {
  return amount * 100;
};
export const KoboToNaira = (amount) => {
  return amount / 100;
};

export const planStatus = (status) => {
  switch (status) {
    case 'complete':
      return false;
    case 'active':
      return true;

    default:
      return true;
  }
};

export const energyRevenue = (estate) =>
  formatCurrency(estate.energyConsumed * estate.dosTariff);
export const duosRevenue = (estate) =>
  formatCurrency(estate.energySupplied * estate.dosCharge);

export const getSerializedParams = (obj, indexed = false) => {
  return Object.entries(Object.assign({}, obj))
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');
};

export const _formatDate = (date, format) => {
  const _date = new Date(date);
  const options = {
    'MM-DD-YY': `${(_date.getMonth() + 1).toString().padStart(2, '0')}-${_date.getDate().toString().padStart(2, '0')}-${_date.getFullYear().toString().slice(2)}`,
    'MM/DD/YY': `${(_date.getMonth() + 1).toString().padStart(2, '0')}/${_date.getDate().toString().padStart(2, '0')}/${_date.getFullYear().toString().slice(2)}`,
    'DD/MM/YY': `${_date.getDate().toString().padStart(2, '0')}/${(_date.getMonth() + 1).toString().padStart(2, '0')}/${_date.getFullYear().toString().slice(2)}`,
    'YY/MM/DD': `${_date.getFullYear().toString().slice(2)}/${(_date.getMonth() + 1).toString().padStart(2, '0')}/${_date.getDate().toString().padStart(2, '0')}`,
    'DD-MM-YY': `${_date.getDate().toString().padStart(2, '0')}-${(_date.getMonth() + 1).toString().padStart(2, '0')}-${_date.getFullYear().toString().slice(2)}`,
    'YY-MM-DD': `${_date.getFullYear().toString().slice(2)}-${(_date.getMonth() + 1).toString().padStart(2, '0')}-${_date.getDate().toString().padStart(2, '0')}`
  };

  return options[format] || options['MM-DD-YY'];
};

export const isObjectEmpty = (obj) => {
  try {
    return Object.entries(obj).map(([key]) => key).length === 0;
  } catch (error) {
    return true;
  }
};

export const generateFilterParam = (obj) => {
  if (isObjectEmpty(obj)) {
    return {};
  }
  return Object.entries(obj).reduce((query, [key, value]) => {
    if (value) {
      query[key] = value;
    }
    return query;
  }, {});
};

export const addOneDay = (date) => {
  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
  const currentDate = new Date(date);
  const timestamp = currentDate.getTime();
  const tomorrow = new Date(timestamp + ONE_DAY_IN_MS);

  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
  const day = String(tomorrow.getDate()).padStart(2, '0');

  return [year, month, day].join('/');
};
