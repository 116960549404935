import { createPlan } from 'store/actions';
import { toast } from 'sonner';
import { NairaToKobo } from './utils';

const Payment = {
  closeForm: async (close, event, resetForm) => {
    resetForm();
    close(event);
  },

  submitForm: async (
    userType,
    event,
    setErrors,
    validateUnits,
    formValues,
    setIsSubmitting,
    isRecurring
  ) => {
    const validate =
      userType === 'customer'
        ? validateUnits(formValues, isRecurring)
        : validateUnits(formValues);
    event.preventDefault();
    setErrors(validate);
    setIsSubmitting(true);
  },

  fetchMeterEstate: (user, meterValue) => {
    const { meters } = user;
    const [{ estate }] = meters.filter((meter) => meter.meterNo === meterValue);
    return estate;
  },
  payWithPaystack: (user, formValues, unit, estateId) => {
    const { id, email, plan } = user;
    const { amount, meterno, paymentType } = formValues;

    const paymentData = {
      email,
      amount: NairaToKobo(amount),
      customerId: id,
      estateId,
      meterNo: meterno,
      type: paymentType
    };

    if (paymentType === 'recurring') {
      paymentData.plan = { nam: '', interval: plan };
    }

    // initializePayment(paymentData);
  },

  createPaymentPlan: (name, amount, interval) => {
    const plan = { name, amount, interval };
    createPlan(plan);
  },
  // eslint-disable-next-line no-unused-vars
  confirmResidentTariff: (resident, value) => {
    if (!resident.isTariffApproved) {
      toast.info(
        `You will not be able to buy units on meter ${value}. Tariff for ${resident.estateName} has not been approved. Kindly contact an Enaro staff for more details`
      );
    }
  },

  checkPaymentValues: (parameters) => {
    const {
      name,
      value,
      user,
      tariff,
      handleTariff,
      setIsModalTriggered,
      resetForm,
      setUnit
    } = parameters;

    if (name === 'meterno' && value !== '') {
      handleTariff(value);
    }
    if (name === 'meterno' && value === '') {
      resetForm();
    }
    if (user && name === 'amount') {
      setUnit(value / tariff);
    }
    if (name === 'plan' && value !== '') {
      setIsModalTriggered(true);
    }
  }
};

export default Payment;
