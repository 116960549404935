import React, { useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'store/actions';
import { UsePrompt } from 'components';

const UseIdle = () => {
  const dispatch = useDispatch();

  const timeOutRef = useRef(null);

  const [countDown] = useState(1000 * 60 * 5);
  const [modal, SetModal] = useState(false);

  const toggle = value => {
    SetModal(value);
  };

  const stayLoggedIn = () => {
    toggle(false);
    clearTimeout(timeOutRef.current);
  };

  const userLogout = () => {
    clearTimeout(timeOutRef.current);
    dispatch(logoutUser());
  };

  const onIdle = () => {
    toggle(true);
    timeOutRef.current = setTimeout(() => {
      userLogout();
    }, 1000 * 60 * 3);
  };

  useIdleTimer({
    onIdle,
    timeout: countDown,
    throttle: 250
  });

  return (
    <>
      <UsePrompt
        modal={modal}
        toggle={toggle}
        stayLoggedIn={stayLoggedIn}
        logout={userLogout}
      />
    </>
  );
};

export default UseIdle;
