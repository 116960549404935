import axios from 'axios';
import { locationConstants } from 'store/constants';
import { toast } from 'sonner';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const getCitySuccess = (cities) => ({
  type: locationConstants.GET_CITY_SUCCESS,
  payload: cities
});

export const getCityFailure = (error) => ({
  type: locationConstants.GET_CITY_FAILURE,
  payload: error
});

export const getCities = () => (dispatch) => {
  axios
    .get(`/cities`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getCitySuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(getCityFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const getStatesSuccess = (states) => ({
  type: locationConstants.GET_STATE_SUCCESS,
  payload: states
});

export const getStatesFailure = (error) => ({
  type: locationConstants.GET_CITY_FAILURE,
  payload: error
});

export const getStates = () => (dispatch) => {
  axios
    .get(`/states`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getStatesSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(getStatesFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const addCitySuccess = (payload, stateId) => ({
  type: locationConstants.ADD_CITY_SUCCESS,
  payload,
  stateId
});

export const addCityFailure = (error) => ({
  type: locationConstants.ADD_CITY_FAILURE,
  payload: error
});

export const addCity = (city) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .post(`/cities/`, { cityName: city.name, stateId: city.stateId })
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(addCitySuccess(response.data, city.stateId));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(addCityFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const editCitySuccess = (payload) => ({
  type: locationConstants.EDIT_CITY_SUCCESS,
  payload
});

export const editCityFailure = (error) => ({
  type: locationConstants.EDIT_CITY_FAILURE,
  payload: error
});

export const editCity = (city, cityId) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .patch(`/cities/${cityId}`, { cityName: city.name })
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(editCitySuccess({ city, cityId }));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editCityFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const deleteCitySuccess = (payload) => ({
  type: locationConstants.DELETE_CITY_SUCCESS,
  payload
});

export const deleteCityFailure = (error) => ({
  type: locationConstants.DELETE_CITY_FAILURE,
  payload: error
});

export const deleteCity = (cityId, stateId) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .delete(`/cities/${cityId}`)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(deleteCitySuccess({ cityId, stateId }));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(deleteCityFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};
