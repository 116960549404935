import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import { toast } from 'sonner';
import React from 'react';
import PropTypes from 'prop-types';

const CopyIcon = (props) => (
  <svg
    className="w-6 h-6 text-gray-800 dark:text-white"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M7 9v6a4 4 0 0 0 4 4h4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h1v2Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M13 3.054V7H9.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 13 3.054ZM15 3v4a2 2 0 0 1-2 2H9v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-3Z"
      clipRule="evenodd"
    />
  </svg>
);

export function CopyText({ textValue }) {
  return (
    <div className="position-relative d-flex align-items-center justify-content-between">
      <span>{textValue || 'NA'}</span>

      {textValue && (
        <CopyToClipboard
          text={textValue}
          onCopy={() =>
            toast.success('COPIED', {
              className: 'px-2 py-2 w-auto'
            })
          }
        >
          <CopyIcon
            className="cursor-pointer"
            style={{ color: `gray`, height: `18px`, width: `18px` }}
          />
        </CopyToClipboard>
      )}
    </div>
  );
}

CopyText.propTypes = {
  textValue: PropTypes.string
};
