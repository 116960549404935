import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Logo } from 'assets/svg/enaroEnergy.svg';

const AuthLayout = ({ children }) => (
  <main className="auth__container min-vh-100">
    <div className="auth__box container h-100">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-12 col-md-6 col-lg-4">
          <div className="auth__content">
            <Logo className="auth__logo-container" />
            {children}
          </div>
        </div>
      </div>
    </div>
  </main>
);

AuthLayout.propTypes = {
  children: PropTypes.shape({}).isRequired
};

export default AuthLayout;
