import React from 'react';
import PropTypes from 'prop-types';

export const ViewDieselData = ({ close }) => {
  const closeModal = event => {
    close(event);
  };
  return (
    <>
      <div className="modal-view__header py-4">
        <h4 className="heading header-title">Diesel Indicators for </h4>
        <p className="paragraph header-text m-0">
          {/* {selected && dateFormater(selected.createdAt, 'time')} */}
          September 30, 2019
        </p>
        <button
          className="button button-transparent button-nopadding modal-view__close"
          data-selectedid="0"
          onClick={closeModal}
        >
          &times;
        </button>
      </div>
      <div className="modal-view__body">
        <div className="content__area">
          <div className="modal-view__absolute"></div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-6">Engine Oil Level: </div>
            <div>{'90%'}</div>
          </div>
          <div className="row modal-view--details mb-3">
            <div className="header col-md-6">Fuel Level: </div>
            <div>{'20%'}</div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-6"> Water Level: </div>
            <div>{'10M'}</div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-6">Temperature:</div>
            <div> {'273'} &deg;C</div>
          </div>
          <div className="row modal-view--details  mb-3"></div>
        </div>
      </div>
    </>
  );
};

ViewDieselData.propTypes = {
  close: PropTypes.func
  // entityId: PropTypes.number
};
