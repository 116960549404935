import { discoConstants } from '../constants';
import initialState from './initialState';

const locationReducer = (state = initialState.locations, action) => {
  switch (action.type) {
    case discoConstants.GET_DISC0S_FAILURE:
      return { ...state };
    case discoConstants.GET_DISCOS_SUCCESS:
      return { ...state, items: action.payload.data };
    case discoConstants.ADD_DISCO_FAILURE:
      return { ...state };
    case discoConstants.ADD_DISCO_SUCCESS:
      return { ...state };
    case discoConstants.EDIT_DISCO_FAILURE:
      return { ...state };
    case discoConstants.EDIT_DISCO_SUCCESS:
      return { ...state };
    case discoConstants.GET_ESTATES_FAILURE:
      return { ...state };
    case discoConstants.GET_ESTATES_SUCCESS:
      return { ...state, estates: action.payload.data };
    default:
      return state;
  }
};

export default locationReducer;
