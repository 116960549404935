import { permissionsConstants } from '../constants';
import initialState from './initialState';

const permissionReducer = (state = initialState.permissions, action) => {
  switch (action.type) {
    case permissionsConstants.GET_PERMISSION_FAILURE:
      return {
        ...state
      };
    case permissionsConstants.GET_PERMISSION_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

export default permissionReducer;
