import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency, formatEnergy } from 'utility';
import { LoadingContent } from './LoadingContent';

export const ViewRevenueSummary = ({ entity, close }) => {
  const closeModal = (event) => {
    close(event);
  };

  return entity && entity.name ? (
    <>
      <div className="modal-view__header py-3">
        <h4 className="heading header-title">{entity && entity.name}</h4>
        <button
          className="button button-transparent button-nopadding modal-view__close"
          data-selectedid="0"
          onClick={closeModal}
        >
          &times;
        </button>
      </div>
      <div className="modal-view__body">
        <div className="content__area">
          <div className="row modal-view--details  mb-4">
            <div className="header col-md-6 col-sm-4">Energy Supplied</div>
            <div className="detail col-md-6 col-sm-8">{formatEnergy(200)}</div>
          </div>
          <div className="row modal-view--details mb-4">
            <div className="header col-md-6 col-sm-4">Energy Consumed:</div>
            <div className="detail col-md-6 col-sm-8">{formatEnergy(300)}</div>
          </div>
          <div className="row modal-view--details  mb-4">
            <div className="header col-md-6 col-sm-4">DUOS Fee</div>
            <div className="detail col-md-6 col-sm-8">
              {formatCurrency(entity.dosCharge || 0)}
            </div>
          </div>
          <div className="row modal-view--details  mb-4">
            <div className="header col-md-6 col-sm-4">Disco Fee:</div>
            <div className="detail col-md-6 col-sm-8">
              {formatCurrency(entity.dosTariff || 0)}
            </div>
          </div>

          <div className="row modal-view--details  mb-4">
            <div className="header col-md-6 col-sm-4">Estate Tariff:</div>
            <div className="detail col-md-6 col-sm-8">
              {entity.isTariffApproved
                ? formatCurrency(entity.estateTariff)
                : 'Unapproved'}
            </div>
          </div>
          <div className="row modal-view--details  mb-5">
            <div className="header col-md-6 col-sm-4">
              <b>Total Revenue: </b>
            </div>
            <div className="detail col-md-6 col-sm-8">
              <b>{formatCurrency(parseInt(entity.revenue, 10))}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoadingContent />
  );
};

ViewRevenueSummary.propTypes = {
  entity: PropTypes.object.isRequired,
  close: PropTypes.func
};
