import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';

export const RolesPermissions = props => {
  const permissions = props.permissions === undefined ? [] : props.permissions;

  const renderPermissions =
    permissions.length > 0 ? (
      permissions.map(permission => {
        return (
          <ListGroupItem key={permission.id}>
            {permission.displayTitle}
          </ListGroupItem>
        );
      })
    ) : (
      <ListGroupItem>Select Role to view permissions</ListGroupItem>
    );

  return (
    <>
      <div className="roles__permissions">
        <ListGroup>
          <ListGroupItem className="roles__permissions--header">
            What this role can access
          </ListGroupItem>
          {renderPermissions}
        </ListGroup>
      </div>
    </>
  );
};

RolesPermissions.propTypes = {
  permissions: PropTypes.array
};
