import React from 'react';
import { Table } from 'components';

export const PowerConsumptionListings = () => {
  const headers = ['METER ID', 'USAGE', 'TOTAL COST'];

  const tableData = [
    { meterId: '147852', usage: '200kWh', totalCost: '₦ 20,000' },
    { meterId: '147852', usage: '200kWh', totalCost: '₦ 20,000' },
    { meterId: '147852', usage: '200kWh', totalCost: '₦ 20,000' },
    { meterId: '147852', usage: '200kWh', totalCost: '₦ 20,000' },
    { meterId: '147852', usage: '200kWh', totalCost: '₦ 20,000' },
    { meterId: '147852', usage: '200kWh', totalCost: '₦ 20,000' },
    { meterId: '147852', usage: '200kWh', totalCost: '₦ 20,000' },
    { meterId: '147852', usage: '200kWh', totalCost: '₦ 20,000' },
    { meterId: '147852', usage: '200kWh', totalCost: '₦ 20,000' }
  ];

  const powerListings = () => {
    return tableData.map((data, i) => {
      return (
        <tr key={i}>
          <td> {data.meterId} </td>
          <td> {data.usage} </td>
          <td> {data.totalCost} </td>
        </tr>
      );
    });
  };
  const dataSource = tableData ? powerListings() : {};

  return (
    <>
      <div className="power">
        <section className="square-tables">
          <table className="table table-borderless mb-0 noborder--bottom">
            <thead>
              <tr>
                <th colSpan={headers.length}>
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h3 className="medium-heading">
                        <span> Power Consumption </span>
                      </h3>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>

          <Table
            title={'Power Consumption'}
            columns={headers}
            dataSource={dataSource}
            className="noborder--top"
          />
        </section>
      </div>
    </>
  );
};
