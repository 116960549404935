import { persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import {
  discoApi,
  estatesApi,
  notificationsApi,
  paymentApi,
  residentsApi,
  rolesApi,
  settingsApi,
  subscriptionApi,
  ticketsApi,
  unitsApi,
  usersApi
} from 'features/api';
import reducers from './reducers';

export const store = configureStore({
  reducer: {
    ...reducers,
    [discoApi.reducerPath]: discoApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [estatesApi.reducerPath]: estatesApi.reducer,
    [unitsApi.reducerPath]: unitsApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [residentsApi.reducerPath]: residentsApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat([
      discoApi.middleware,
      usersApi.middleware,
      estatesApi.middleware,
      unitsApi.middleware,
      ticketsApi.middleware,
      rolesApi.middleware,
      settingsApi.middleware,
      paymentApi.middleware,
      notificationsApi.middleware,
      residentsApi.middleware
    ])
});

export const persistor = persistStore(store);
