export const authConstants = {
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
  SET_PASSWORD_FAILURE: 'SET_PASSWORD_FAILURE',

  RESET_REQUEST_SUCCESS: 'RESET_REQUEST_SUCCESS',
  RESET_REQUEST_FAILURE: 'RESET_REQUEST_FAILURE',

  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE'
};

export const userConstants = {
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',

  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

  UPLOAD_USERS_SUCCESS: 'UPLOAD_USERS_SUCCESS',
  UPLOAD_USERS_FAILURE: 'UPLOAD_USERS_FAILURE',

  GET_USER_BY_METER_SUCCESS: 'GET_USER_BY_METER_SUCCESS',
  GET_USER_BY_METER_FAILURE: 'GET_USER_BY_METER_FAILURE',

  METER_RESET_SUCCESS: 'METER_RESET_SUCCESS'
};

export const profileConstants = {
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

  EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',
  EDIT_PROFILE_FAILURE: 'EDIT_PROFILE_FAILURE',

  GET_METERS_SUCCESS: 'GET_METERS_SUCCESS',
  GET_METERS_FAILURE: 'GET_METERS_FAILURE',

  EDIT_METER_ALIAS_SUCCESS: 'EDIT_METER_ALIAS_SUCCESS',
  EDIT_METER_ALIAS_FAILURE: 'EDIT_METER_ALIAS_FAILURE'
};

export const roleConstants = {
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

  ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
  ADD_ROLE_FAILURE: 'ADD_ROLE_FAILURE',

  GET_PERMISSION_SUCCESS: 'GET_PERMISSION_SUCCESS',
  GET_PERMISSION_FAILURE: 'GET_PERMISSION_FAILURE',

  GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
  GET_ROLE_FAILURE: 'GET_ROLE_FAILURE',

  EDIT_ROLE_SUCCESS: 'EDIT_ROLE_SUCCESS',
  EDIT_ROLE_FAILURE: 'EDIT_ROLE_FAILURE',

  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE'
};

export const permissionsConstants = {
  GET_PERMISSION_SUCCESS: 'GET_PERMISSION_SUCCESS',
  GET_PERMISSION_FAILURE: 'GET_PERMISSION_FAILURE'
};

export const estateConstants = {
  GET_ESTATE_SUCCESS: 'GET_ESTATE_SUCCESS',
  GET_ESTATE_FAILURE: 'GET_ESTATE_FAILURE',

  GET_ESTATES_SUCCESS: 'GET_ESTATES_SUCCESS',
  GET_ESTATES_FAILURE: 'GET_ESTATES_FAILURE',

  ADD_ESTATE_SUCCESS: 'ADD_ESTATE_SUCCESS',
  ADD_ESTATE_FAILURE: 'ADD_ESTATE_FAILURE',

  UPLOAD_ESTATES_SUCCESS: 'UPLOAD_ESTATES_SUCCESS',
  UPLOAD_ESTATES_FAILURE: 'UPLOAD_ESTATES_FAILURE',

  EDIT_ESTATE_SUCCESS: 'EDIT_ESTATE_SUCCESS',
  EDIT_ESTATE_FAILURE: 'EDIT_ESTATE_FAILURE',

  DEACTIVATE_ESTATE_SUCCESS: 'DEACTIVATE_ESTATE_SUCCESS',
  DEACTIVATE_ESTATE_FAILURE: 'DEACTIVATE_ESTATE_FAILURE',

  GET_ESTATES_REVENUE_SUCCESS: 'GET_ESTATES_REVENUE_SUCCESS',
  GET_ESTATES_REVENUE_FAILURE: 'GET_ESTATES_REVENUE_FAILURE',

  FILTER_ESTATES: 'FILTER_ESTATES'
};

export const locationConstants = {
  GET_CITY_SUCCESS: 'GET_CITY_SUCCESS',
  GET_CITY_FAILURE: 'GET_CITY_FAILURE',

  DELETE_CITY_SUCCESS: 'DELETE_CITY_SUCCESS',
  DELETE_CITY_FAILURE: 'DELETE_CITY_FAILURE',

  GET_STATE_SUCCESS: 'GET_STATE_SUCCESS',
  GET_STATE_FAILURE: 'GET_STATE_FAILURE',

  ADD_CITY_SUCCESS: 'ADD_CITY_SUCCESS',
  ADD_CITY_FAILURE: 'ADD_CITY_FAILURE',

  EDIT_CITY_SUCCESS: 'EDIT_CITY_SUCCESS',
  EDIT_CITY_FAILURE: 'EDIT_CITY_FAILURE'
};

export const discoConstants = {
  GET_DISCO_SUCCESS: 'GET_DISCO_SUCCESS',
  GET_DISCO_FAILURE: 'GET_DISCO_FAILURE',

  GET_ESTATES_SUCCESS: 'GET_ESTATES_SUCCESS',
  GET_ESTATES_FAILURE: 'GET_ESTATES_FAILURE',

  GET_DISCOS_SUCCESS: 'GET_DISCOS_SUCCESS',
  GET_DISCOS_FAILURE: 'GET_DISC0S_FAILURE',

  DELETE_DISCO_SUCCESS: 'DELETE_DISCO_SUCCESS',
  DELETE_DISCO_FAILURE: 'DELETE_DISCO_FAILURE',

  ADD_DISCO_SUCCESS: 'ADD_DISCO_SUCCESS',
  ADD_DISCO_FAILURE: 'ADD_DISCO_FAILURE',

  EDIT_DISCO_SUCCESS: 'EDIT_DISCO_SUCCESS',
  EDIT_DISCO_FAILURE: 'EDIT_DISCO_FAILURE'
};

export const residentsConstants = {
  GET_RESIDENTS_SUCCESS: 'GET_RESIDENTS_SUCCESS',
  GET_RESIDENTS_FAILURE: 'GET_RESIDENTS_FAILURE',

  UPLOAD_RESIDENT_SUCCESS: 'UPLOAD_RESIDENT_SUCCESS',
  UPLOAD_RESIDENT_FAILURE: 'UPLOAD_RESIDENT_FAILURE',

  EDIT_RESIDENT_SUCCESS: 'EDIT_RESIDENT_SUCCESS',
  EDIT_RESIDENT_FAILURE: 'EDIT_RESIDENT_FAILURE',

  RESIDENT_STATUS_SUCCESS: 'RESIDENT_STATUS_SUCCESS',
  RESIDENT_STATUS_FAILURE: 'RESIDENT_STATUS_FAILURE',

  REMOVE_RESIDENTS_SUCCESS: 'REMOVE_RESIDENTS_SUCCESS'
};

export const ticketConstants = {
  GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',
  GET_PAYMENTS_FAILURE: 'GET_PAYMENTS_FAILURE',

  GET_TICKETS_SUCCESS: 'GET_TICKETS_SUCCESS',
  GET_TICKETS_FAILURE: 'GET_TICKETS_FAILURE',

  GET_TICKET_SUCCESS: 'GET_TICKET_SUCCESS',
  GET_TICKET_FAILURE: 'GET_TICKET_FAILURE',

  ADD_TICKET_SUCCESS: 'ADD_TICKET_SUCCESS',
  ADD_TICKET_FAILURE: 'ADD_TICKET_FAILURE',

  UPDATE_TICKET_SUCCESS: 'UPDATE_TICKET_SUCCESS',
  UPDATE_TICKET_FAILURE: 'UPDATE_TICKET_FAILURE',

  ADD_TICKET_COMMENT_SUCCESS: 'ADD_TICKET_COMMENT_SUCCESS',
  ADD_TICKET_COMMENT_FAILURE: 'ADD_TICKET_COMMENT_FAILURE',

  REMOVE_LOG_SUCCESS: 'REMOVE_LOG_SUCCESS'
};

export const paymentConstants = {
  GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',
  GET_PAYMENTS_FAILURE: 'GET_PAYMENTS_FAILURE',

  GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
  GET_PAYMENT_FAILURE: 'GET_PAYMENT_FAILURE',

  ADD_PAYMENT_SUCCESS: 'ADD_PAYMENT_SUCCESS',
  ADD_PAYMENT_FAILURE: 'ADD_PAYMENT_FAILURE'
};

export const paystackConstants = {
  GET_PAYMENT_PLANS_SUCCESS: 'GET_PAYMENT_PLANS_SUCCESS',
  GET_PAYMENTS_PLANS_FAILURE: 'GET_PAYMENT_PLANS_SUCCESS',

  GET_TRANSACTION_SUCCESS: 'GET_TRANSACTION_SUCCESS',
  GET_TRANSACTION_FAILURE: 'GET_TRANSACTION_FAILURE',

  VERIFY_PAYMENT_SUCCESS: 'VERIFY_PAYMENT_SUCCESS',
  VERIFY_PAYMENT_FAILURE: 'VERIFY_PAYMENT_FAILURE',

  CREATE_PLAN_SUCCESS: 'CREATE_PLAN_SUCCESS',
  CREATE_PLAN_FAILURE: 'CREATE_PLAN_FAILURE',

  FETCH_PLAN_BY_CUSTOMER_SUCCESS: 'FETCH_PLAN_BY_CUSTOMER_SUCCESS',
  FETCH_PLAN_BY_CUSTOMER_FAILURE: 'FETCH_PLAN_BY_CUSTOMER_FAILURE',

  RESET_PLANS_SUCCESS: 'RESET_PLANS_SUCCESS'
};

export const subscriptionConstants = {
  FETCH_SUBSCRIPTION_BY_METER_SUCCESS: 'FETCH_SUBSCRIPTION_BY_METER_SUCCESS',
  FETCH_SUBSCRIPTION_BY_METER_FAILURE: 'FETCH_SUBSCRIPTION_BY_METER_SUCCESS',

  UPDATE_SUBSCRIPTION_STATUS_SUCCESS: 'UPDATE_SUBSCRIPTION_STATUS_SUCCESS',
  UPDATE_SUBSCRIPTION_STATUS_FAILURE: 'UPDATE_SUBSCRIPTION_STATUS_FAILURE',

  RESET_SUBSCRIPTIONS_SUCCESS: 'RESET_SUBSCRIPTIONS_SUCCESS'
};

export const logConstants = {
  GET_LOG_SUCCESS: 'GET_LOG_SUCCESS',
  GET_LOG_FAILURE: 'GET_LOG_FAILURE',

  GET_ALL_LOG_SUCCESS: 'GET_ALL_LOG_SUCCESS',
  GET_ALL_LOG_FAILURE: 'GET_ALL_LOG_FAILURE',

  REMOVE_LOG_SUCCESS: 'REMOVE_LOG_SUCCESS',
  REMOVE_LOG_FAILURE: 'REMOVE_LOG_FAILURE',

  APPROVAL_RESPONSE_SUCCESS: 'APPROVAL_RESPONSE_SUCCESS',
  APPROVAL_RESPONSE_FAILURE: 'APPROVAL_RESPONSE_FAILURE'
};

export const notifConstants = {
  GET_ALL_NOTIFS_SUCCESS: 'GET_ALL_NOTIFS_SUCCESS',
  GET_ALL_NOTIFS_FAILURE: 'GET_ALL_NOTIFS_FAILURE',

  ADD_NOTIFS_SUCCESS: 'ADD_NOTIFS_SUCCESS',
  ADD_NOTIFS_FAILURE: 'ADD_NOTIFS_FAILURE',

  NOTIFS_READ_SUCCESS: 'NOTIFS_READ_SUCCESS',
  NOTIFS_READ_FAILURE: 'NOTIFS_READ_FAILURE',

  GET_NOTIFICATION: 'GET_NOTIFICATION'
};

export const apiConstants = {
  BEGIN_API_CALL: 'BEGIN_API_CALL',
  END_API_CALL: 'END_API_CALL'
};

export const unitsConstants = {
  GET_ALL_UNITS_SUCCESS: 'GET_ALL_UNITS_SUCCESS',
  GET_ALL_UNITS_FAILURE: 'GET_ALL_UNITS_FAILURE',

  ADD_UNITS_SUCCESS: 'ADD_UNITS_SUCCESS',
  ADD_UNITS_FAILURE: 'ADD_UNITS_FAILURE'
};

export const exportConstants = {
  EXPORT_PAYMENT_SUCCESS: 'EXPORT_PAYMENT_SUCCESS',
  EXPORT_PAYMENT_FAILURE: 'EXPORT_PAYMENT_FAILURE',

  EXPORT_TICKETS_SUCCESS: 'EXPORT_TICKETS_SUCCESS',
  EXPORT_TICKETS_FAILURE: 'EXPORT_TICKETS_FAILURE'
};
