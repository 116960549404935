import React from 'react';
import { LoadingContent, Onboarding, StaffMetrics } from 'components';
import { useGetUsersQuery } from 'features/api';

export const StaffDashboard = () => {
  const { data: users, isLoading } = useGetUsersQuery('staff');

  let DisplayComponent = <LoadingContent />;

  if (!isLoading) {
    DisplayComponent =
      users !== undefined && users.length > 0 ? (
        <StaffMetrics />
      ) : (
        <Onboarding />
      );
  }

  return DisplayComponent;
};
