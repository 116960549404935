import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { isObjectEmpty, validateUser } from 'utility';
import {
  useCreateUserMutation,
  useEditUserMutation,
  useGetRolesQuery,
  useGetUsersQuery
} from 'features/api';
import { PhoneNumberInput } from 'components/PhoneNumberInput';

export const SaveStaff = ({ ...props }) => {
  const userDefault = {
    name: '',
    email: '',
    phoneNumber: '',
    roleId: '',
    userType: 'staff'
  };

  const { close, isEdit, entityId = 0 } = props;

  const dispatch = useDispatch();
  const { data: staffList } = useGetUsersQuery('staff');
  const [createUser, { isLoading: isCreating, isSuccess: isCreated }] =
    useCreateUserMutation();
  const [editUser, { isLoading: isUpdating, isSuccess }] =
    useEditUserMutation();

  const { data } = useGetRolesQuery();
  const roles = data || [];
  const [user, setUser] = useState(userDefault);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (entityId) {
      const userInfo = staffList.find((user) => user.id === entityId);

      setUser((prevState) => ({
        ...prevState,
        ...userInfo
      }));
    }

    return () => setUser(userDefault);
  }, [entityId]);

  const saveStaff = useCallback(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      delete user.id;
      delete user.discoId;

      if (isEdit) {
        const editOptions = user;
        const editDetails = { userId: entityId };
        editUser({ editOptions, editDetails });
        return;
      }
      createUser(user);
    }
  }, [isEdit, errors, dispatch, isSubmitting, user, entityId]);

  useEffect(() => {
    saveStaff();
    return () => {
      setIsSubmitting(false);
    };
  }, [saveStaff]);

  useEffect(() => {
    if (isSuccess || isCreated) {
      close();
    }
  }, [isSuccess]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateUser(user);
    setErrors(errors);
    const isEmpty = isObjectEmpty(errors);
    if (isEmpty) {
      setIsSubmitting(true);
    }
  };

  const handleChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handlePhone = (value) => {
    setUser((prevUser) => ({
      ...prevUser,
      phoneNumber: value
    }));
  };

  const handleCancel = (event) => {
    setUser(userDefault);
    close(event);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h4 className="heading header-title">
            {isEdit ? 'Edit' : 'Add'} team member
          </h4>
          <p className="paragraph header-text">
            Edit the details of user and save
          </p>
        </div>
        <div className="form__content row gap-3">
          <div className="form-group">
            <label>
              Name <span className="form-error">{errors.name}</span>
            </label>
            <input
              type="text"
              name="name"
              value={user.name}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Email <span className="form-error">{errors.email}</span>
            </label>
            <input
              type="text"
              name="email"
              value={user.email}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Phone Number{' '}
              <span className="form-error">{errors.phoneNumber}</span>
            </label>

            <PhoneNumberInput
              placeholder="7045637890"
              value={user.phoneNumber}
              onChange={handlePhone}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Role <span className="form-error">{errors.roleId}</span>
            </label>
            <select
              name="roleId"
              value={user.roleId}
              className="form-control"
              onChange={handleChange}
              required
            >
              <option value="0">Select Role</option>
              {roles
                .filter((role) => ['Customer'].indexOf(role.title) === -1)
                .map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.title}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group "
              role="group"
              aria-label="Button group example"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="button button-save ms-2"
                onClick={handleSubmit}
                disabled={isUpdating || isCreating}
              >
                {(isUpdating || isCreating) && (
                  <Spinner className="me-3" color="light" />
                )}{' '}
                {isEdit ? 'Save Changes' : 'Save Staff'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

SaveStaff.propTypes = {
  close: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  data: PropTypes.string,
  entityId: PropTypes.number
};
