import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

export const UseModal = ({
  modal,
  toggle,
  backdrop,
  component: Component,
  header,
  header: Header,
  isEdit,
  entityId,
  entity
}) => {
  const [backdropType] = useState(backdrop || true);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop={backdropType}>
        {header && Header}
        <ModalBody>
          <Component
            isEdit={isEdit}
            entityId={entityId}
            entity={entity}
            close={toggle}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

UseModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  roles: PropTypes.array,
  isEdit: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  entity: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number
  ]),
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};
