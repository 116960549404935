import React from 'react';

const DeactivateUser = ({ ...props }) => {
  return (
    <div className="p-4 text-center">
      <h5>
        {props.status ? 'Re-activate' : 'Deactivate'} {props.name}
      </h5>
      <p>
        {props.name} will {props.status ? 'now' : 'no longer'} be able to access
        Enaro. Do you want to continue?
      </p>
    </div>
  );
};

export default DeactivateUser;
