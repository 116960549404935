import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'features/baseQuery';
import { toast } from 'sonner';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery,
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: () => `/preferences`,
      transformResponse: (response) => response.data,
      providesTags: ['Setting']
    }),
    updateSettings: builder.mutation({
      query: (body) => {
        return {
          url: `/preferences`,
          method: 'PUT',
          body: body
        };
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Setting', id: arg.id }]
    })
  })
});

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;
