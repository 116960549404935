import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FilterPopover, NoContent, TicketsFilter } from 'components';
import { dateFormater } from 'utility';
import Check from '../permission/Check';

export const CustomerTickets = ({
  tickets,
  metadata,
  category,
  exportLink,
  openModal,
  filterHandler,
  isFilter
}) => {
  const { getAllTickets, filterSet } = filterHandler;

  const renderTableHeader = () => (
    <thead className="table-header">
      <tr>
        <th colSpan="5">
          <div className="row">
            <div className="col-md-7">
              {isFilter !== 'filtered' ? (
                <span className="section-heading">
                  All complaints on{' '}
                  <span>
                    {category} - {metadata && metadata.totalItems}
                  </span>
                </span>
              ) : (
                <span className="section-heading">
                  Filtered complaints on{' '}
                  <span>
                    {category} - {metadata && metadata.totalItems}
                  </span>
                </span>
              )}
            </div>
            <div className="col-md-5 button-controls">
              <Check permission="create-complaint">
                <button
                  className="button button-primary button-small button-lowcase mx-1"
                  onClick={openModal}
                >
                  <i className="iconmoon icon-add"></i>
                  <span>New Complaint</span>
                </button>
              </Check>
              <Check permission="export-complaint">
                <a
                  className="button button-primary button-small text-capitalize mx-1"
                  download
                  target="_blank"
                  href={exportLink}
                  rel="noreferrer"
                >
                  <i className="iconmoon icon-download"></i> Export
                </a>
              </Check>

              <FilterPopover entity={category}>
                <TicketsFilter
                  category={category}
                  filterHandler={getAllTickets}
                  filterSet={filterSet}
                />
              </FilterPopover>
            </div>
          </div>
        </th>
      </tr>
      <tr>
        <th>Complaint ID</th>
        <th>Meter No</th>
        <th>Title</th>
        <th>Created</th>
        <th>Status</th>
      </tr>
    </thead>
  );

  const noContent = () => (
    <>
      <NoContent
        icon="no-ticket"
        title={
          isFilter !== 'filtered'
            ? 'No complaints have been created yet'
            : 'No matching results. Reset filter to see all tickets'
        }
        caption="All complaints will be displayed here"
      />
      <div className="text-center pb-5 mb-5">
        <Check permission="create-complaint">
          <button
            className="button button-primary button-lowcase align-center"
            onClick={openModal}
          >
            <i className="iconmoon icon-add"></i>
            <span>New Complaint</span>
          </button>
        </Check>
      </div>
    </>
  );

  const ticketListing = () =>
    tickets.map((ticket, index) => {
      const { id, ticketId, title, status, meterNo, meterName, createdAt } =
        ticket;

      return (
        <tr key={index}>
          <td>
            <Link title="Click to view" to={`/app/complaint/${id}`}>
              {ticketId}
            </Link>
          </td>
          <td>
            <Link title="Click to view" to={`/app/complaint/${id}`}>
              {meterName || meterNo}
            </Link>
          </td>
          <td>
            <b>{title}</b>
          </td>
          <td>{dateFormater(createdAt)}</td>
          <td>
            <span
              className={`bubble__status bubble--${status
                .split(' ')
                .join('-')}`}
            >
              {status}
            </span>
          </td>
        </tr>
      );
    });

  const renderTableData = () => {
    const tableContent =
      tickets.length > 0 ? (
        ticketListing()
      ) : (
        <tr>
          <td colSpan="5">{noContent()}</td>
        </tr>
      );
    return tableContent;
  };

  return (
    <section className="ticket__listing square-tables table-responsive">
      <table className="table table-borderless">
        {renderTableHeader()}
        <tbody className="table-content">{renderTableData()}</tbody>
      </table>
    </section>
  );
};

CustomerTickets.propTypes = {
  category: PropTypes.string.isRequired,
  exportLink: PropTypes.string.isRequired,
  filterHandler: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFilter: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  metadata: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tickets: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
