import React from 'react';
import PropTypes from 'prop-types';
import { dateFormater } from 'utility';

export const Comment = ({ item }) => {
  return (
    <div className="comment">
      <div className="d-flex justify-content-between">
        <p className="comment-header">{item.creator}</p>
        <p className="comment-header">{dateFormater(item.createdAt, 'time')}</p>
      </div>
      <div>
        <p>{item.comment}</p>
      </div>
    </div>
  );
};

Comment.propTypes = {
  item: PropTypes.object.isRequired
};
