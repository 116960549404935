import axios from 'axios';
import { permissionsConstants } from 'store/constants';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const getPermissionSuccess = roles => ({
  type: permissionsConstants.GET_PERMISSION_SUCCESS,
  payload: roles
});

export const getPermissionFailure = error => ({
  type: permissionsConstants.GET_PERMISSION_FAILURE,
  payload: error
});

export const getPermission = () => dispatch => {
  axios
    .get(`/permissions`)
    .then(response => {
      if (response.data.status) {
        dispatch(getPermissionSuccess(response.data));
      }
    })
    .catch(error => {
      if (error.response) {
        dispatch(getPermissionFailure(error.response.data));
      } else {
        throw error;
      }
    });
};
