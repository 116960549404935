import React, { useState } from 'react';
import { Table, UseModal } from 'components';
import { ViewGasData } from './ViewGasData';
import { ViewDieselData } from './ViewDieselData';

export const StaffRealtimeListings = () => {
  const [gasModal, setGasModal] = useState(false);
  const [dieselModal, setDieselModal] = useState(false);
  const headers = ['ESTATE', 'SOLAR', 'GAS', 'DIESEL', 'DISCO'];
  const [entity, setEntity] = useState();

  const tableData = [
    {
      estate: 'The Palms',
      solar: { val: 'Inactive' },
      gas: { val: 0, time: '3 hours ago' },
      diesel: { val: '500kWh' },
      disco: { val: 0, time: '3 hours ago' }
    },
    {
      estate: 'Oniru',
      solar: { val: '500kWh' },
      gas: { val: '500kWh' },
      diesel: { val: 0, time: '3 hours ago' },
      disco: { val: '500kWh' }
    },
    {
      estate: 'Ibeju',
      solar: { val: 0, time: '3 hours ago' },
      gas: { val: '500kWh' },
      diesel: { val: '500kWh' },
      disco: { val: 0, time: '3 hours ago' }
    },
    {
      estate: 'Unity',
      solar: { val: 0, time: '3 hours ago' },
      gas: { val: '500kWh' },
      diesel: { val: 'Inactive' },
      disco: { val: 0, time: '3 hours ago' }
    }
  ];

  const toggleGasIndicator = dataId => {
    setEntity(dataId);
    setGasModal(!gasModal);
  };

  const toggleDieselIndicator = dataId => {
    setEntity(dataId);
    setDieselModal(!dieselModal);
  };

  const setClass = data => {
    return data.time
      ? 'text-danger'
      : data.val === 'Inactive'
      ? 'text-muted'
      : 'text-success';
  };

  const powerListings = () => {
    return tableData.map((data, i) => {
      return (
        <tr key={i}>
          <td> {data.estate} </td>
          <td className={setClass(data.solar)}>
            {data.solar.val}
            <br />
            {data.solar.time && (
              <small className="text-muted"> {data.solar.time} </small>
            )}
          </td>
          <td
            className={`cursor-pointer ${setClass(data.gas)}`}
            onClick={() => toggleGasIndicator(i)}
          >
            {data.gas.val}
            <br />
            {data.gas.time && (
              <small className="text-muted"> {data.gas.time} </small>
            )}
          </td>

          <td
            className={`cursor-pointer ${setClass(data.diesel)}`}
            onClick={() => toggleDieselIndicator(i)}
          >
            {data.diesel.val}
            <br />
            {data.diesel.time && (
              <small className="text-muted"> {data.diesel.time} </small>
            )}
          </td>

          <td className={setClass(data.disco)}>
            {data.disco.val}
            <br />
            {data.disco.time && (
              <small className="text-muted"> {data.disco.time} </small>
            )}
          </td>
        </tr>
      );
    });
  };

  const dataSource = tableData ? powerListings() : {};

  return (
    <>
      <div className="realtime-staff">
        <section className="square-tables">
          <table className="table table-borderless mb-0 noborder--bottom">
            <thead>
              <tr>
                <th colSpan={headers.length}>
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h3 className="medium-heading">
                        <span> All sources for today </span>
                      </h3>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>

          <Table
            title={'All sources for today'}
            columns={headers}
            dataSource={dataSource}
            className="noborder--top"
          />
          <UseModal
            toggle={toggleGasIndicator}
            entityId={entity}
            modal={gasModal}
            component={ViewGasData}
            backdrop={true}
          />
          <UseModal
            toggle={toggleDieselIndicator}
            entityId={entity}
            modal={dieselModal}
            component={ViewDieselData}
            backdrop={true}
          />
        </section>
      </div>
    </>
  );
};
