import { apiConstants } from 'store/constants';

export const beginApiCall = () => {
  return {
    type: apiConstants.BEGIN_API_CALL
  };
};

export const endApiCall = () => {
  return {
    type: apiConstants.END_API_CALL
  };
};
