import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

export const Confirm = ({ modal, toggle, actionHandler, entity }) => {
  const closeConfirm = event => {
    toggle(event);
  };

  const continueAction = event => {
    event.preventDefault();
    actionHandler();
  };

  return (
    <Modal className="confirm" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody>
        <div className="p-4 text-center">
          <h5>Delete {entity}</h5>
          <p>This action cannot be reversed. Do you want to continue?</p>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <button
          type="button"
          className="button button-primary"
          onClick={closeConfirm}
        >
          Cancel
        </button>

        <button
          type="button"
          className="button button-secondary ms-2"
          onClick={continueAction}
        >
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};

Confirm.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  actionHandler: PropTypes.func.isRequired,
  entity: PropTypes.string
};
