import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import classnames from 'classnames';

export const TabLink = ({ title, tabId, path, activeTab, toggle }) => {
  // const tabPath = usePagination
  //   ? `${path}?tab=${tabId}&page=1&limit=10`
  //   : `${path}?tab=${tabId}`;

  return (
    <NavItem>
      <Link
        className={` ${classnames({ active: activeTab === tabId })} nav-link`}
        onClick={() => {
          toggle(tabId);
        }}
        to={`${path}?tab=${tabId}`}
      >
        {title}
      </Link>
    </NavItem>
  );
};

TabLink.propTypes = {
  title: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired
};
