import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Confirm, RolesPermissions, SaveRole, UseModal } from 'components';
import Check from '../permission/Check';
import { removeDuplicates } from 'utility';

export const RolesDetails = (props) => {
  const [modal, setModal] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const toggle = () => setModal(!modal);

  const toggleConfirm = (event) => {
    setConfirm(!confirm);
    const selectedRole = event.currentTarget.dataset
      ? event.currentTarget.dataset.roleid
      : '';
    setRoleId(selectedRole);
  };

  const { deleteHandler, role } = props;

  const roleDetails = role.length > 0 ? role[0] : [];

  const handleDelete = () => {
    deleteHandler(roleId, 10);
  };

  return (
    <>
      <div className="roles__details">
        <div className="row">
          <div className="col-sm-6 col-md-8">
            <h6>{roleDetails.title}</h6>
          </div>
          <div className="col-sm-6 col-md-4">
            <div className="controls">
              <Check permission="delete-role">
                <div
                  className={
                    roleDetails.isCustom && roleDetails.usersAssigned === 0
                      ? 'delete-control float-end'
                      : 'no-controls'
                  }
                >
                  <label htmlFor="delete-role">Delete</label>
                  <input
                    type="button"
                    name="delete-role"
                    id="delete-role"
                    data-roleid={roleDetails.id}
                    onClick={toggleConfirm}
                    disabled={!roleDetails.isCustom}
                  />
                </div>
              </Check>
              <Check permission="edit-role">
                <div className="edit-control float-end">
                  <label htmlFor="edit-role">Edit</label>
                  <input
                    type="button"
                    name="edit-role"
                    id="edit-role"
                    data-roleid={roleDetails.id}
                    onClick={toggle}
                  />
                </div>
              </Check>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="roles__role-details">
              <p>
                {' '}
                <i className="iconmoon icon-user"></i> Team Members with this
                role({roleDetails.usersAssigned})
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <div className="pt-5 pb-5 px-5">
              <RolesPermissions
                permissions={removeDuplicates(roleDetails.permissions)}
              />
            </div>
          </div>
        </div>
      </div>
      <UseModal
        toggle={toggle}
        modal={modal}
        entity={roleDetails}
        isEdit={true}
        component={SaveRole}
      />
      <Confirm
        toggle={toggleConfirm}
        entity={roleDetails.title}
        modal={confirm}
        actionHandler={handleDelete}
      />
    </>
  );
};

RolesDetails.propTypes = {
  props: PropTypes.object,
  deleteHandler: PropTypes.func,
  editHandler: PropTypes.func,
  role: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
