import * as React from 'react';

import PhoneInput from 'react-phone-number-input';

const PhoneNumberInput = React.forwardRef(({ ...props }, ref) => {
  return (
    <PhoneInput ref={ref} countries={['NG']} defaultCountry="NG" {...props} />
  );
});
PhoneNumberInput.displayName = 'PhoneInput';

export { PhoneNumberInput };
