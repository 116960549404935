import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import auth from './authReducer';
import apiCalls from './apiReducer';
import profile from './profileReducer';
import users from './userReducer';
import roles from './roleReducer';
import estates from './estateReducer';
import permissions from './permissionReducer';
import locations from './locationReducer';
import residents from './residentReducer';
import payments from './paymentReducer';
import subscription from './subscriptionsReducer';
import tickets from './ticketReducer';
import approvals from './approvalsReducer';
import notifications from './notificationsReducer';
import units from './unitReducer';
import paystack from './paystackReducer';
import discos from './discoReducer';
import exports from './exportReducer';

const authPersistConfig = {
  key: 'auth',
  storage,
  stateReconciler: autoMergeLevel2
};

const profilePersistConfig = {
  key: 'profile',
  storage,
  stateReconciler: autoMergeLevel2
};
//
const rolesPersistConfig = {
  key: 'roles',
  storage,
  stateReconciler: autoMergeLevel2
};
//
const notifsPersistConfig = {
  key: 'notifications',
  storage,
  stateReconciler: autoMergeLevel2
};

const locationsPersistConfig = {
  key: 'locations',
  storage,
  stateReconciler: autoMergeLevel2
};

const rootReducer = {
  auth: persistReducer(authPersistConfig, auth),
  profile: persistReducer(profilePersistConfig, profile),
  roles: persistReducer(rolesPersistConfig, roles),
  notifications: persistReducer(notifsPersistConfig, notifications),
  locations: persistReducer(locationsPersistConfig, locations),
  apiCalls,
  users,
  estates,
  permissions,
  discos,
  residents,
  payments,
  subscription,
  paystack,
  tickets,
  approvals,
  units,
  exports
};

export default rootReducer;
