import React from 'react';
import PropTypes from 'prop-types';

export const TicketViewSummary = ({ ticket }) => {
  const { category, title, ticketId, meterName, meterNo, email } = ticket;

  return (
    <div className="ticket__content border-bottom">
      <div className="row">
        <div className="col-md-8 border-right">
          <div className="content__area">
            <div className="row">
              <div className="col-md-7">
                <div className="row ticket--details mb-3">
                  <div className="header col-md-5">TICKET ID:</div>
                  <div className="text-capitalize col-md-7">{ticketId}</div>
                </div>

                <div className="row ticket--details mb-3">
                  <div className="header col-md-5">COMPLAINT TYPE:</div>
                  <div className="text-capitalize col-md-7">{category}</div>
                </div>

                <div className="row ticket--details">
                  <div className="header col-md-5">TICKET TITLE:</div>
                  <div className="text-capitalize col-md-7">{title}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="content__area">
            <div className="row ticket--details  mb-3">
              <div className="header col-md-5">METER:</div>
              <div className="text-capitalize col-md-7">
                {meterName || meterNo}
              </div>
            </div>
            <div className="row ticket--details">
              <div className="header col-md-5">EMAIL ADDRESS:</div>
              <div className="text-capitalize col-md-7">{email}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TicketViewSummary.propTypes = {
  ticket: PropTypes.object.isRequired
};
