const parseName = (fullname) => {
  const names = fullname.split(' ');
  let isValid;
  names.forEach((name) => {
    isValid = name.length > 0;
  });

  return isValid;
};

export const validateLogin = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email address should be provided';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  if (!values.password) {
    errors.password = 'Please enter your password';
  }

  return errors;
};

export const validateCity = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = ' is required';
  }

  return errors;
};

export const validateFields = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = ' is required';
  }

  if (!values.street) {
    errors.street = ' is required';
  }

  if (values.discoId === 0 || values.discoId === '') {
    errors.discoId = ' not selected';
  }

  if (!values.dosCharge) {
    errors.dosCharge = ' is required';
  }

  if (!values.dosTariff) {
    errors.dosTariff = ' is required';
  }

  if (!values.enaroTariff) {
    errors.enaroTariff = ' is required';
  }

  if (!values.estateTariff) {
    errors.estateTariff = ' is required';
  }

  if (!values.manager) {
    errors.manager = ' is required';
  }

  if (Number.isNaN(values.cityId)) {
    errors.cityId = ' has not been selected ';
  }

  if (values.businessUnitId === 0 || values.businessUnitId === '') {
    errors.businessUnitId = ' not selected ';
  }

  if (!values.email) {
    errors.email = ' should be provided';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = ' is invalid';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = ' is required';
  } else if (values.phoneNumber.length < 14 || values.phoneNumber.length > 14) {
    errors.phoneNumber = 'needs to be between 10 and 11 characters';
  }

  return errors;
};

export const validateTicket = (values) => {
  const errors = {};

  if (!values.meterNo) {
    errors.meterNo = ' is required';
  }

  if (!values.title > 0) {
    errors.title = ' has not been entered ';
  }

  if (!values.body > 0) {
    errors.body = ' has not been entered ';
  }

  return errors;
};

export const validateRole = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Please enter role name';
  }

  if (!values.description) {
    errors.description = 'Please enter a description';
  }

  if (values.permission) {
    errors.permission = 'Please select permission';
  }

  return errors;
};

export const checkStaff = (fieldName, value) => {
  const error = {};
  const status = {};

  switch (fieldName) {
    case 'fullname': {
      status.fullname = parseName(value);
      error.fullname = parseName(value) ? '' : ' is too short';
      break;
    }
    case 'email': {
      const emailCheck =
        /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(summitech)\.ng$/;
      status.email = emailCheck.test(value);
      error.email = status.email ? '' : ' is invalid';
      break;
    }
    case 'phone': {
      const phoneCheck = /^\D?(\d{3})\D?\D?(\d{4})\D?(\d{4})$/;
      status.phone = phoneCheck.test(value);
      error.phone = status.phone ? '' : ' is incomplete ';
      break;
    }
    case 'role': {
      status.role = value > 0;
      error.role = status.role ? '' : ' has not been selected ';
      break;
    }

    default:
      break;
  }

  return { error, status };
};

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const checkForm = (fieldName, value) => {
  const error = {};
  const status = {};

  switch (fieldName) {
    case 'email': {
      status.email = emailRegex.test(value);
      error.email = status.email ? '' : ' Please enter a valid email';
      break;
    }
    case 'password': {
      const passwordCheck = /^[\w\W]{6,10}$/;
      status.password = passwordCheck.test(value);
      error.password = status.password
        ? ''
        : ' lenght should be between 6 & 10 characters';
      break;
    }
    default:
      break;
  }

  return { error, status };
};

export const checkPasswordChange = (fieldName, value) => {
  const error = {};
  const status = {};

  switch (fieldName) {
    case 'currentPassword': {
      status.currentPassword = value.length > 0;
      error.currentPassword = status.currentPassword
        ? ''
        : 'enter your password';
      break;
    }
    case 'newPassword': {
      const passwordCheck = /\w{6,10}$/;
      status.newPassword = passwordCheck.test(value);
      error.newPassword = status.newPassword
        ? ''
        : ' lenght is between 6 & 10 characters';
      break;
    }
    case 'confirmNewPassword': {
      status.confirmNewPassword = value.length > 0;
      error.confirmNewPassword = status.confirmNewPassword
        ? ''
        : 'not entered ';
      break;
    }
    default:
      break;
  }

  return { error, status };
};

export const validateUser = (values, check) => {
  const errors = {};
  if (!values.name) {
    errors.name = ' is required';
  }

  if (!values.email) {
    errors.email = ' address should be provided';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = ' address is invalid';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = ' is required';
  } else if (values.phoneNumber.length < 14 || values.phoneNumber.length > 14) {
    errors.phoneNumber = 'needs to be 10 characters';
  }

  if (values.userType === 'customer') {
    if (check === 'reassign') {
      if (!values.newMeterNo) {
        errors.newMeterNo = ' is required';
      }
    } else if (!values.meterNo) {
      errors.meterNo = ' is required';
    }
  }

  if (values.userType === 'staff') {
    if (!values.roleId) {
      errors.roleId = ' is required';
    }
  }

  return errors;
};

export const validateDisco = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = ' is required';
  }

  if (!values.email) {
    errors.email = ' address should be provided';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = ' address is invalid';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = ' is required';
  } else if (values.phoneNumber.length < 14 || values.phoneNumber.length > 14) {
    errors.phoneNumber = ' needs to be between 10 and 11 characters';
  }

  if (!values.staffIds) {
    errors.staffIds = ' is required';
  }

  if (!values.address) {
    errors.address = ' is required';
  }

  return errors;
};

export const validateMeter = (values) => {
  const errors = {};
  if (!values.meterName) {
    errors.meterName = ' is required';
  }

  return errors;
};

export const validateComment = (values) => {
  const errors = {};
  if (!values.message) {
    errors.message = ' is required';
  }

  return errors;
};

export const validateUnits = (values, recur) => {
  const errors = {};

  if (!values.meterno) {
    errors.meterno = ' is required';
  }

  if (recur && !values.plan) {
    errors.plan = ' is required';
  }

  if (!values.amount) {
    errors.amount = ' is required';
  }

  return errors;
};
