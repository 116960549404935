import { locationConstants } from '../constants';
import initialState from './initialState';

const locationReducer = (state = initialState.locations, action) => {
  switch (action.type) {
    case locationConstants.GET_STATE_FAILURE:
      return { ...state };
    case locationConstants.GET_STATE_SUCCESS:
      return { ...state, states: action.payload.data };
    case locationConstants.GET_CITY_FAILURE:
      return { ...state };
    case locationConstants.GET_CITY_SUCCESS:
      return { ...state, cities: action.payload.data };
    case locationConstants.ADD_CITY_FAILURE:
      return { ...state };
    case locationConstants.ADD_CITY_SUCCESS:
      return {
        ...state,
        states: state.states.map(location => {
          const places =
            location.id !== action.payload.data.stateId
              ? location
              : {
                  ...location,
                  cities: location.cities.concat(action.payload.data)
                };

          return places;
        })
      };
    case locationConstants.EDIT_CITY_FAILURE:
      return { ...state };
    case locationConstants.EDIT_CITY_SUCCESS:
      return {
        ...state,
        states: state.states.map(location => {
          const places =
            location.id !== action.payload.city.stateId
              ? location
              : {
                  ...location,
                  cities: location.cities.map(city => {
                    const newCity =
                      city.id !== action.payload.cityId
                        ? city
                        : { ...city, name: action.payload.city.name };
                    return newCity;
                  })
                };
          return places;
        })
      };
    case locationConstants.DELETE_CITY_FAILURE:
      return { ...state };
    case locationConstants.DELETE_CITY_SUCCESS:
      return {
        ...state,
        states: state.states.map(location => {
          const places =
            location.id !== action.payload.stateId
              ? location
              : {
                  ...location,
                  cities: location.cities.filter(city => {
                    return city.id !== action.payload.cityId;
                  })
                };
          return places;
        })
      };
    default:
      return state;
  }
};

export default locationReducer;
