import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

export const UsePrompt = ({ modal, toggle, stayLoggedIn, logout }) => {
  const close = () => {
    toggle(false);
  };
  return (
    <Modal className="prompt" isOpen={modal} toggle={close} backdrop="static">
      <ModalBody>
        <div className="px-4 pt-3 text-center">
          <h5>You have been idle for a while</h5>
          <p>You will be logged out soon</p>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <button
          type="button"
          className="button button-primary"
          onClick={stayLoggedIn}
        >
          Stay Logged In
        </button>

        <button
          type="button"
          className="button button-secondary ms-2"
          onClick={logout}
        >
          Logout
        </button>
      </ModalFooter>
    </Modal>
  );
};

UsePrompt.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  stayLoggedIn: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};
