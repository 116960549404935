import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriptionByMeterNo } from 'store/actions';
import {
  formatCurrency,
  KoboToNaira,
  NairaToKobo,
  validateUnits
} from 'utility';
import Payment from 'utility/Payment';
import CreatePaymentPlanPrompt from './CreatePaymentPlanPrompt';
import { Subscriptions } from './Subscriptions';
import { paymentApi, useInitializeCustomerPaymentMutation } from 'features/api';
import { Spinner } from 'reactstrap';
import { NumericFormat } from 'react-number-format';

export const BuyUnitsCustomer = ({ close }) => {
  const initialValues = {
    meterno: '',
    amount: 0,
    plan: '',
    paymentType: 'oneTime'
  };

  const {
    fetchMeterEstate,
    closeForm,
    confirmResidentTariff,
    checkPaymentValues,
    payWithPaystack,
    submitForm
  } = Payment;

  const createdPlan = useSelector((state) => state.paystack.plan);
  const user = useSelector((state) => state.profile);

  const [formValues, setformValues] = useState(initialValues);
  const [isRecurring, setisRecurring] = useState(false);
  const [unit, setUnit] = useState(0);
  const [modal, setModal] = useState(false);

  const [disableAmount, setDisableAmount] = useState(true);
  const [estateId, setEstateId] = useState();
  const [tariff, setTariff] = useState(0);

  const [planId, setPlanId] = useState('');

  const [initializeCustomerPayment, { isLoading }] =
    useInitializeCustomerPaymentMutation();

  // console.log(isSubmittingg, 'result');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalTriggered, setIsModalTriggered] = useState(false);
  const [errors, setErrors] = useState({});

  const { meterno, amount, plan } = formValues;

  const [getBreakdown, { data }] =
    paymentApi.endpoints.getBreakdown.useLazyQuery();

  const getBreakdownDetails = (event) => {
    event.preventDefault();

    setTimeout(() => {
      if (amount) {
        getBreakdown({
          amount: NairaToKobo(amount),
          includeProcessorFee: true
        });
      }
    }, 2000);
  };

  const { vat = 0, total = 0, processorFee = 0 } = data || {};

  const resetForm = () => {
    setTariff(0);
    setformValues(initialValues);
    setUnit(0);
    setDisableAmount(true);
  };

  const dispatch = useDispatch();

  const dispatchSubscription = useCallback(() => {
    dispatch(fetchSubscriptionByMeterNo(meterno));
  }, [dispatch, meterno]);

  useEffect(() => {
    if (meterno) {
      dispatchSubscription();
    }
  }, [meterno]);

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  useEffect(() => {
    if (isModalTriggered && isRecurring && plan.length > 0) {
      toggleModal();
    }

    return () => {
      setIsModalTriggered(false);
    };
  }, [isRecurring, plan, toggleModal, isModalTriggered]);

  useEffect(() => {
    if (createdPlan) {
      setPlanId(createdPlan.data.plan_code);
    }
  }, [createdPlan]);

  const handleTariff = (value) => {
    const resident = fetchMeterEstate(user, value);
    confirmResidentTariff(resident, value);
    setDisableAmount(!resident.isTariffApproved);
    setEstateId(resident.id);
    setTariff(resident.estateTariff);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setformValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    const parameters = {
      name,
      value,
      tariff,
      user,
      handleTariff,
      resetForm,
      setUnit,
      setIsModalTriggered
    };
    checkPaymentValues(parameters);
  };

  const handleAmountChange = (event) => {
    const { value, floatValue } = event;
    setformValues((prevValues) => ({
      ...prevValues,
      amount: value
    }));

    const parameters = {
      name: 'amount',
      value: floatValue,
      tariff,
      user,
      handleTariff,
      resetForm,
      setUnit,
      setIsModalTriggered
    };
    checkPaymentValues(parameters);
  };

  const initializePayment = useCallback(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const { id, email } = user;
      const { amount, meterno, paymentType, plan } = formValues;

      const paymentData = {
        email,
        amount: NairaToKobo(amount),
        customerId: id,
        estateId,
        meterNo: meterno,
        type: paymentType
      };
      if (isRecurring) {
        paymentData.plan = { name: plan, interval: plan.toLowerCase() };
      }
      return initializeCustomerPayment(paymentData);
    }
  }, [
    errors,
    isSubmitting,
    payWithPaystack,
    user,
    formValues,
    unit,
    estateId,
    isRecurring,
    planId
  ]);

  useEffect(() => {
    initializePayment();

    return () => {
      setIsSubmitting(false);
    };
  }, [initializePayment]);

  const handlePaymentType = (event) => {
    handleChange(event);
    const { value } = event.target;
    value === 'oneTime' ? setisRecurring(false) : setisRecurring(true);
  };

  const { subscription } = useSelector((state) => state);

  useEffect(() => {
    if (subscription && subscription.planId) {
      formValues.paymentType = 'oneTime';
      setisRecurring(false);
    }
  }, [isRecurring, subscription, formValues]);

  const displayPaymentTypes = () =>
    subscription && subscription.planId ? (
      <>
        <Subscriptions
          getSubscription={dispatchSubscription}
          subscription={subscription}
        />
      </>
    ) : (
      <div className="form-row col-md-8">
        <label>One time</label>
        <input
          type="radio"
          name="paymentType"
          value="oneTime"
          className="mx-2 mt-1"
          defaultChecked
          onClick={handlePaymentType}
        />
        <label>Recurring</label>
        <input
          type="radio"
          name="paymentType"
          value="recurring"
          className="mx-2 mt-1"
          onClick={handlePaymentType}
          disabled={formValues.amount < 100 || formValues.meterno === ''}
        />
      </div>
    );

  const selectPlanInterval = () =>
    isRecurring && (
      <div className="form-group">
        <label>Frequency</label>
        <span className="form-error"> {errors.plan} </span>
        <select
          name="plan"
          className="form-control"
          value={plan}
          onChange={handleChange}
        >
          <option value=""> Select Plan</option>
          <option value="Hourly">Hourly</option>
          <option value="Daily">Daily</option>
          <option value="Weekly">Weekly</option>
          <option value="Monthly">Monthly</option>
        </select>
      </div>
    );

  const { meters } = user;
  return (
    <form className="form">
      <div className="form__header">
        <h4 className="heading header-title">Buy Units</h4>
        <p className="paragraph header-text">
          Please enter the required information.
        </p>
      </div>
      <div className="form__content row gap-3">
        <div className="form-group">
          <label>Meter Number</label>
          <span className="form-error"> {errors.meterno} </span>
          <select
            name="meterno"
            value={meterno}
            className="form-control"
            onChange={handleChange}
          >
            <option value="" defaultValue>
              Choose meter
            </option>
            {meters &&
              meters.map(
                (meter) =>
                  meter.isMeterActive && (
                    <option key={meter.meterNo} value={meter.meterNo}>
                      {meter.meterName || meter.meterNo}
                    </option>
                  )
              )}
          </select>
        </div>
        <div className="form-group">
          <label>
            Amount
            <span className="form-error"> {errors.amount} </span>
          </label>
          {/* Using type=number on firefox does not display */}
          <NumericFormat
            className="form-control"
            value={amount}
            required
            prefix="₦"
            thousandSeparator=","
            onBlur={getBreakdownDetails}
            onValueChange={handleAmountChange}
            disabled={disableAmount}
          />
        </div>

        <div className="form-group">
          <label>Units</label>
          <input
            type="text"
            name="unit"
            value={unit.toFixed(2) || 0}
            className="form-control"
            required
            readOnly
            onChange={handleChange}
          />
          <small>
            {!!vat && `${formatCurrency(KoboToNaira(vat))} Vat`}{' '}
            {!!processorFee &&
              `, ${formatCurrency(KoboToNaira(processorFee))} in Processing Fee`}{' '}
            {!!total && `and a Total of ${formatCurrency(KoboToNaira(total))}`}{' '}
          </small>
        </div>
        {displayPaymentTypes()}
        {selectPlanInterval()}
      </div>
      <div className="form__footer">
        <div className="form__footer-buttons d-flex justify-content-end">
          <div
            className="btn-group"
            role="group"
            aria-label="Button group example"
          >
            <button
              type="button"
              className="button button-primary button--outline"
              onClick={(event) => closeForm(close, event, resetForm)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button button-primary button-save ms-2"
              onClick={(event) =>
                submitForm(
                  user.userType,
                  event,
                  setErrors,
                  validateUnits,
                  formValues,
                  setIsSubmitting
                )
              }
              disabled={(isRecurring && !planId.length > 0) || amount < 100}
            >
              {isLoading && <Spinner className="me-3" color="light" />} Buy
              Units
            </button>
          </div>
        </div>
      </div>
      <CreatePaymentPlanPrompt
        modal={modal}
        toggle={toggleModal}
        amount={amount}
        meterNo={meterno}
        user={user}
        estateId={estateId}
        paymentType={formValues.paymentType}
        isRecurring={isRecurring}
        plan={plan}
      />
    </form>
  );
};

BuyUnitsCustomer.propTypes = {
  close: PropTypes.func.isRequired,
  meters: PropTypes.array,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
