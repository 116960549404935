import React from 'react';
import { formatUnit } from 'utility';
import PropTypes from 'prop-types';

export const DashboardSummary = ({ ...props }) => {
  const { showDiscos, data } = props;
  return (
    <>
      <div className="row justify-content-end">
        {showDiscos && (
          <>
            <div className="col-md-3 ">
              <div className="overview__summary">
                <span> Disco Tariff: </span>
                <b> {`${(data && formatUnit(data.dosTariff)) || 0} `} </b>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="overview__summary">
                <span> DUOS: </span>
                <b> {`${(data && formatUnit(data.duos)) || 0} `} </b>
              </div>
            </div>
          </>
        )}

        <div className="col-md-3 ">
          <div className="overview__summary">1 Jan - 31 Jan, 2020</div>
        </div>
      </div>
    </>
  );
};

DashboardSummary.propTypes = {
  showDiscos: PropTypes.bool.isRequired,
  data: PropTypes.object,
  path: PropTypes.string
};
