import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Menu = (props) => <div className="switcher__menu" {...props} />;
const Blanket = (props) => <div className="switcher__blanket" {...props} />;

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div className="switcher__dropdown float-end">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

export const Switcher = ({ ...props }) => {
  const { options, entityHandler } = props;
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(props.currentOption || options[0]);
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!isOpen);

  useEffect(() => {
    options ? setLoading(false) : setLoading(true);
  }, [options]);

  useEffect(() => {
    entityHandler(options[0]);
    setSelected(options[0]);
  }, [options.length]);

  const handleChange = (option) => {
    toggle();
    setSelected(option);
    entityHandler(option);
  };

  return (
    <>
      <Dropdown
        className="w100 float-end"
        isOpen={isOpen}
        onClose={toggle}
        target={
          <button
            className="button button-switcher button-transparent button-small text-capitalize text-left"
            onClick={toggle}
          >
            {`Switch ${props.entityType}`}
          </button>
        }
      >
        <Select
          autoFocus
          className="switcher"
          classNamePrefix="switcher"
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          menuIsOpen
          onChange={handleChange}
          options={options}
          defaultValue={selected}
          value={selected}
          placeholder="Search..."
          name="entity"
          tabSelectsValue={false}
          isLoading={loading}
          isSearchable
          required
        />
      </Dropdown>
    </>
  );
};

Switcher.propTypes = {
  entityHandler: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  currentOption: PropTypes.object
};

Dropdown.propTypes = {
  children: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
