import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UseModal, SaveStaff } from 'components';

export const Onboarding = () => {
  const name = useSelector(state => state.profile.name);

  const [modal, setModal] = useState(false);
  const [isEdit] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <>
      <div className="onboarding text-center">
        <h3 className="heading onboarding__title">
          Welcome to Enaro Energy, {name}.
        </h3>
        <p className="paragraph onboarding__text">
          Let us get started by setting up the required Information for your
          experience{' '}
        </p>
      </div>
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-md-4 col-sm-12">
          <div className="onboarding__content text-center">
            <div className="onboarding__content-icon d-flex justify-content-center align-items-center">
              <i className="iconmoon icon-team"></i>
            </div>
            <h3>Team Members</h3>
            <p>Invite team members to use application and assign role.</p>
            <button
              type="button"
              className="col-6 button button-primary__outline button-caps"
              onClick={toggle}
            >
              Start
            </button>
          </div>
        </div>
      </div>

      <UseModal
        toggle={toggle}
        modal={modal}
        isEdit={isEdit}
        component={SaveStaff}
        backdrop="static"
      />
    </>
  );
};
