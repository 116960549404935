import { exportConstants } from 'store/constants';
import { urlFormatter } from 'utility';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

export const generatePaymentFilterSuccess = payload => ({
  type: exportConstants.EXPORT_PAYMENT_SUCCESS,
  payload
});

export const generatePaymentFilter = (user, filterOptions) => dispatch => {
  const exportPayment = `${uri}/payments/export?token=${localStorage.token}`;
  const url = urlFormatter(exportPayment, filterOptions);

  dispatch(generatePaymentFilterSuccess({ url, option: 'payment' }));
};

export const generateTicketFilterSuccess = payload => ({
  type: exportConstants.EXPORT_TICKETS_SUCCESS,
  payload
});

export const generateTicketFilter = (user, filterOptions) => dispatch => {
  const exportPayment = `${uri}/tickets/export?token=${localStorage.token}&category=${user.category}`;
  const url = urlFormatter(exportPayment, filterOptions);

  dispatch(
    generateTicketFilterSuccess({
      url,
      user,
      option: `ticket-${user.category}`
    })
  );
};
