import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import UseSockets from 'hooks/UseSockets';
import * as AppRoutes from 'routes/index';
import { Dashboard } from 'routes/index';
import { AuthRoute } from 'routes/AuthRoute';
import { DashboardLayout } from 'layouts/DashboardLayout';
import UseIdle from 'hooks/UseIdle';
import config from '../permission/config';
import { useGetRoleQuery } from 'features/api';

const PrivateRoute = () => {
  UseSockets();

  const { roleId, userType } = useSelector((state) => state.profile);
  const { loggedIn } = useSelector((state) => state.auth);

  const [routes, setRoutes] = useState([]);
  const { data } = useGetRoleQuery(roleId);
  const userRole = data || {};

  useEffect(() => {
    const allowedRoutes = [];

    if (loggedIn && userRole !== undefined) {
      if (userRole?.permissions !== undefined) {
        userRole.permissions.forEach((permission) => {
          if (config[permission.title]) {
            allowedRoutes.push(config[permission.title]);
          }
        });
      }
    }

    if (allowedRoutes.length !== 0) {
      const ticketUrl = userType === 'customer' ? 'complaint' : 'ticket';

      allowedRoutes.push(
        {
          component: 'TicketView',
          url: `/${ticketUrl}/:id`,
          nav: false
        },
        {
          component: 'PrivacyPolicy',
          url: '/privacypolicy',
          nav: false
        },
        {
          component: 'NotFound',
          url: '*',
          nav: false
        }
      );
    }

    setRoutes(allowedRoutes);
  }, [loggedIn, userRole?.id, roleId, userType]);

  return (
    <>
      <UseIdle />
      <Routes>
        {loggedIn &&
          routes &&
          routes.map(({ component, url }) => {
            const Component = AppRoutes[component];
            return (
              <Route
                key={url}
                path={url}
                element={
                  <AuthRoute layout={DashboardLayout} component={Component} />
                }
              />
            );
          })}

        {!loggedIn && (
          <Route
            path="*"
            element={
              <AuthRoute layout={DashboardLayout} component={Dashboard} />
            }
          />
        )}
      </Routes>
    </>
  );
};

PrivateRoute.propTypes = {};

export default PrivateRoute;
