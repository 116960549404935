import { residentsConstants } from '../constants';
import initialState from './initialState';

const residentReducer = (state = initialState.residents, action) => {
  switch (action.type) {
    case residentsConstants.GET_RESIDENTS_FAILURE:
      return { ...state };
    case residentsConstants.GET_RESIDENTS_SUCCESS:
      return action.payload;
    case residentsConstants.UPLOAD_RESIDENT_FAILURE:
      return { ...state };
    case residentsConstants.UPLOAD_RESIDENT_SUCCESS:
      return { ...state };
    case residentsConstants.RESIDENT_STATUS_FAILURE:
      return { ...state };
    case residentsConstants.RESIDENT_STATUS_SUCCESS:
      return { ...state };
    case residentsConstants.REMOVE_RESIDENTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default residentReducer;
