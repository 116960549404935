import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'features/baseQuery';
import { toast } from 'sonner';
import environment from 'environment';

const {
  api: { uri }
} = environment;

const formatEstateData = (estate) => ({
  cityId: estate.cityId,
  discoId: estate.discoId,
  dosCharge: estate.dosCharge,
  dosTariff: estate.dosTariff,
  enaroTariff: estate.enaroTariff,
  name: estate.name,
  street: estate.street,
  businessUnitId: estate.businessUnitId,
  contactPerson: {
    name: estate.manager,
    email: estate.email,
    phoneNumber: estate.phoneNumber
  },
  estateTariff: estate.estateTariff
});

export const exportEstates = `${uri}/estates/export`;

export const estatesApi = createApi({
  reducerPath: 'estatesApi',
  baseQuery,
  tagTypes: ['Estate'],
  endpoints: (builder) => ({
    getEstate: builder.query({
      query: (estateId) => `/estates/${estateId}`,
      transformResponse: (response) => response.data,
      providesTags: ['Estate']
    }),
    getEstates: builder.query({
      query: (filter = null) =>
        filter ? `/estates?name=${filter}` : '/estates',
      transformResponse: (response) => response.data,
      providesTags: ['Estate']
    }),
    createEstate: builder.mutation({
      query: (estate) => ({
        url: '/estates',
        method: 'POST',
        body: formatEstateData(estate)
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ['Estate']
    }),
    editEstate: builder.mutation({
      query: ({ estate, estateId }) => ({
        url: `/estates/${estateId}`,
        method: 'PATCH',
        body: formatEstateData(estate)
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ['Estate']
    }),
    deactivateEstate: builder.mutation({
      query: ({ estateId, status }) => ({
        url: `/estates/${status ? 'activate' : 'deactivate'}/${estateId}`,
        method: 'PATCH'
      }),
      // eslint-disable-next-line no-unused-vars
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ['Estate']
    }),
    uploadEstates: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append('sheet', file);
        return {
          url: '/estates/import',
          method: 'POST',
          body: formData
        };
      },
      invalidatesTags: ['Estate'],
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      }
    }),
    getEstatesRevenue: builder.query({
      query: () => '/estates/overview/revenue',
      transformResponse: (response) => response.data
    })
  })
});

export const {
  useGetEstateQuery,
  useGetEstatesQuery,
  useCreateEstateMutation,
  useEditEstateMutation,
  useDeactivateEstateMutation,
  useUploadEstatesMutation,
  useGetEstatesRevenueQuery
} = estatesApi;
