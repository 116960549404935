import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingContent, Table } from 'components';
import { Link, useLocation } from 'react-router-dom';
import { getDiscoEstates } from 'store/actions';

const Realtime = () => {
  const dispatch = useDispatch();
  const { estates } = useSelector((state) => state.discos);
  const inProgress = useSelector((store) => store.apiCalls);

  const { discoId } = useSelector((state) => state.profile);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (discoId) {
      dispatch(getDiscoEstates(discoId));
    }
  }, [dispatch, discoId]);

  const headers = ['ESTATE', 'DISCO'];

  const tableData =
    estates &&
    estates.map((estate) => ({
      ...estate,
      disco: { val: Math.floor(650 * Math.random()) }
    }));

  const powerListings = () =>
    tableData.map((data, i) => (
      <tr key={i}>
        <td className={`cursor-pointer`}>
          <Link to={{ pathname: `${path}/estate`, state: { data } }}>
            {' '}
            {data.name}{' '}
          </Link>
        </td>
        <td className={data.disco.time ? 'text-danger' : 'text-success'}>
          {data.disco.val} kWh
        </td>
      </tr>
    ));

  const dataSource = tableData ? powerListings() : [];

  return (
    <>
      <div className="realtime-disco">
        <section className="square-tables">
          <table className="table table-borderless mb-0 noborder--bottom">
            <thead>
              <tr>
                <th colSpan={headers.length}>
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h3 className="medium-heading">
                        <span> Overview </span>
                      </h3>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>

          {!inProgress ? (
            <Table
              title={'Overview'}
              columns={headers}
              dataSource={dataSource}
              className="noborder--top"
            />
          ) : (
            <LoadingContent />
          )}
        </section>
      </div>
    </>
  );
};

Realtime.propTypes = {};

const DiscoRealtime = Realtime;

export { DiscoRealtime };
