import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'features/baseQuery';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery,
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => '/notifications',
      transformResponse: (response) => response.data,
      providesTags: ['Notification']
    }),
    readNotification: builder.mutation({
      query: (notificationId) => ({
        url: `/notifications/${notificationId}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Notification']
    })
  })
});

export const { useGetNotificationsQuery, useReadNotificationMutation } =
  notificationsApi;
