import React, { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { LoadingContent } from 'components';
import { dateFormater, formatCurrency, KoboToNaira } from 'utility';
import { paymentApi } from 'features/api';
import { ReactComponent as Logo } from 'assets/svg/enaroEnergy.svg';

const SelectedTransaction = forwardRef(({ selected, close }, ref) => {
  const { breakdown } = selected || {};

  const parsedBreakdown = breakdown ? JSON.parse(breakdown) : {};

  if (!selected) {
    return null;
  }

  return (
    <div ref={ref}>
      <div className="modal-view__header">
        <Logo style={{ height: `30px`, width: `110px` }} />
        <h4 className="heading header-title">Payment Details</h4>
        <p className="paragraph header-text">
          Payment made on {selected && dateFormater(selected.createdAt, 'time')}
        </p>
        <button
          className="button button-transparent button-nopadding modal-view__close"
          data-selectedid="0"
          onClick={close}
        >
          &times;
        </button>
      </div>
      <div className="modal-view__body">
        <div className="content__area">
          <div className="modal-view__absolute">
            <span
              className={`bubble__status  bubble--${
                selected.status && selected.status.split(' ').join('-')
              }`}
            >
              {selected.status && selected.status}
            </span>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">TRANSACTION ID:</div>
            <div className="detail col-md-8 col-sm-8">
              {selected.transactionId && selected.transactionId}
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">TOKEN NO:</div>
            <div className="detail col-md-8 col-sm-8">
              {(selected.utilityToken && selected.utilityToken) || 'NA'}
            </div>
          </div>
          <div className="row modal-view--details mb-3">
            <div className="header col-md-4 col-sm-4">ESTATE:</div>
            <div className="detail col-md-8 col-sm-8">
              {selected.estate && selected.estate}
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">NAME:</div>
            <div className="detail col-md-8 col-sm-8">
              {selected.name && selected.name}
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">METER NUMBER:</div>
            <div className="detail col-md-8 col-sm-8">
              {selected.meterNo && selected.meterNo}
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">SOURCE:</div>
            <div className="text-capitalize detail col-md-8 col-sm-8">
              {selected.source && selected.source}
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">PAYMENT METHOD:</div>
            <div className="text-capitalize detail col-md-8 col-sm-8">
              {selected.channel && selected.channel}
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">AMOUNT / UNITS:</div>
            <div className="detail col-md-8 col-sm-8">
              {parsedBreakdown.baseAmount &&
                `${formatCurrency(KoboToNaira(parsedBreakdown.baseAmount))} / ${
                  selected.unitsBought
                } units`}{' '}
              <br />
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">VAT:</div>
            <div className="detail col-md-8 col-sm-8">
              {parsedBreakdown.vat &&
                formatCurrency(KoboToNaira(parsedBreakdown.vat))}
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">CHARGES:</div>
            <div className="detail col-md-8 col-sm-8">
              {parsedBreakdown.processorFee &&
                formatCurrency(KoboToNaira(parsedBreakdown.processorFee))}
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">TOTAL:</div>
            <div className="detail col-md-8 col-sm-8">
              {parsedBreakdown.total &&
                formatCurrency(KoboToNaira(parsedBreakdown.total))}
            </div>
          </div>
          <div className="row modal-view--details  mb-3">
            <div className="header col-md-4 col-sm-4">UNIT STATUS:</div>
            <div className="detail col-md-8 col-sm-8">
              {(selected.paymentStatus &&
                selected.paymentStatus &&
                selected.paymentStatus) ||
                'NA'}
            </div>
          </div>
          {selected && selected.createdBy && (
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">Created By:</div>
              <div className="detail col-md-8 col-sm-8">
                {selected.createdBy}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

SelectedTransaction.displayName = 'SelectedTransaction';

export const ViewTransaction = ({ entityId, close }) => {
  const [getPayment, { data }] = paymentApi.endpoints.getPayment.useLazyQuery();

  const selected = data || {};

  useEffect(() => {
    if (entityId) {
      getPayment(entityId);
    }
  }, [entityId]);

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const displaySelected = () => {
    return (
      <>
        <SelectedTransaction selected={selected} close={close} ref={printRef} />
        <div className="modal-view__footer d-flex justify-content-end">
          <div className="content__area">
            <div className="btn-group ">
              <button
                type="button"
                onClick={handlePrint}
                className="button button-primary button-small"
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return entityId ? displaySelected() : <LoadingContent />;
};

SelectedTransaction.propTypes = {
  close: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

ViewTransaction.propTypes = {
  close: PropTypes.func.isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
