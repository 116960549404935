import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import DeactivateUser from './DeactivateUser';

export const Prompt = ({
  modal,
  toggle,
  deactivateHandler,
  status,
  entity,
  inProgress
}) => {
  const closePrompt = (event) => {
    toggle(event);
  };
  const deactivateEntity = (event) => {
    event.preventDefault();
    deactivateHandler({ isDeactivated: !entity.deactivated }, entity);
    toggle(event);
  };

  return (
    <Modal className="prompt" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody>
        <DeactivateUser status={status} name={entity.name} />
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <button
          type="button"
          className="button button-primary"
          onClick={closePrompt}
        >
          Cancel
        </button>

        <button
          type="button"
          className="button button-secondary ms-2"
          onClick={deactivateEntity}
          disabled={inProgress}
        >
          {inProgress && <Spinner className="me-3" color="light" />} Yes,{' '}
          {status ? 'Re-activate' : 'Deactivate'}
        </button>
      </ModalFooter>
    </Modal>
  );
};

Prompt.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  deactivateHandler: PropTypes.func.isRequired,
  entity: PropTypes.object,
  status: PropTypes.bool,
  inProgress: PropTypes.bool,
  user: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
