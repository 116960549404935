import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'features/baseQuery';
import { toast } from 'sonner';

export const unitsApi = createApi({
  reducerPath: 'unitsApi',
  baseQuery,
  tagTypes: ['Units'],
  endpoints: (builder) => ({
    addUnit: builder.mutation({
      query: (unitDetails) => ({
        url: '/business-units',
        method: 'POST',
        body: unitDetails
      }),
      // eslint-disable-next-line no-unused-vars
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const response = await queryFulfilled;
          toast.success(`Success : ${response.data.message}`);
        } catch (err) {
          toast.error(`Error : ${err.error.data.message}`);
        }
      },
      invalidatesTags: ['Units']
    }),
    getUnits: builder.query({
      query: () => '/business-units',
      transformResponse: (response) => response.data,
      providesTags: ['Units']
    })
  })
});

export const { useAddUnitMutation, useGetUnitsQuery } = unitsApi;
