import { paymentConstants } from '../constants';
import initialState from './initialState';

const paymentReducer = (state = initialState.payments, action) => {
  switch (action.type) {
    case paymentConstants.GET_PAYMENTS_FAILURE:
      return { ...state };
    case paymentConstants.GET_PAYMENTS_SUCCESS:
      return { ...state, ...action.payload };

    case paymentConstants.GET_PAYMENT_FAILURE:
      return { ...state };
    case paymentConstants.GET_PAYMENT_SUCCESS:
      return { ...state, selected: action.payload.data.data };

    case paymentConstants.ADD_PAYMENT_SUCCESS:
      return { ...state, payment: action.payload };
    case paymentConstants.ADD_PAYMENT_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default paymentReducer;
