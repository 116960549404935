import React from 'react';
import PropTypes from 'prop-types';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';

export const FilterPopover = ({ entity, children }) => {
  return (
    <>
      <button
        className="button button-primary button-small text-capitalize"
        id={entity}
      >
        <i className="iconmoon icon-filter me-1"></i>Filter
      </button>
      <UncontrolledPopover
        className="filter"
        trigger="legacy"
        placement="bottom"
        target={entity}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              fallbackPlacements: ['bottom']
            }
          }
        ]}
      >
        <PopoverBody>{children}</PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

FilterPopover.propTypes = {
  children: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired
};
