import axios from 'axios';
import { logConstants } from 'store/constants';
import { toast } from 'sonner';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const getsLogSuccess = (payload) => ({
  type: logConstants.GET_ALL_LOG_SUCCESS,
  payload
});

export const getLogsFailure = (error) => ({
  type: logConstants.GET_ALL_LOG_FAILURE,
  payload: error
});

export const getLogs = () => (dispatch) => {
  axios
    .get(`/approvals/`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getsLogSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getLogsFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const getLogSuccess = (payload) => ({
  type: logConstants.GET_LOG_SUCCESS,
  payload
});

export const getLogFailure = (error) => ({
  type: logConstants.GET_LOG_FAILURE,
  payload: error
});

export const getLog = (logId) => (dispatch) => {
  axios
    .get(`/approvals/${logId}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getLogSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getLogFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const approvalResponseSuccess = (payload) => ({
  type: logConstants.APPROVAL_RESPONSE_SUCCESS,
  payload
});

export const approvalResponseFailure = (error) => ({
  type: logConstants.APPROVAL_RESPONSE_FAILURE,
  payload: error
});

export const approvalResponse = (logId, logResponse, comment) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .patch(`/approvals/${logId}?action=${logResponse}`, comment)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(approvalResponseSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(approvalResponseFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const removeLogSuccess = (payload) => ({
  type: logConstants.REMOVE_LOG_SUCCESS,
  payload
});

export const removeLog = () => (dispatch) => {
  dispatch(removeLogSuccess({}));
};
