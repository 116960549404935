import { logConstants } from '../constants';
import initialState from './initialState';

const approvalsReducer = (state = initialState.approvals, action) => {
  switch (action.type) {
    case logConstants.GET_ALL_LOG_FAILURE:
      return { ...state };
    case logConstants.GET_ALL_LOG_SUCCESS:
      return { ...state, logs: action.payload.data };
    case logConstants.GET_LOG_FAILURE:
      return { ...state };
    case logConstants.GET_LOG_SUCCESS:
      return { ...state, log: action.payload.data };
    case logConstants.APPROVAL_RESPONSE_FAILURE:
      return { ...state };
    case logConstants.APPROVAL_RESPONSE_SUCCESS:
      return { ...state };
    case logConstants.REMOVE_LOG_SUCCESS:
      return Object.fromEntries(
        Object.entries(state).filter(([key]) => {
          return key !== 'log';
        })
      );
    default:
      return state;
  }
};

export default approvalsReducer;
