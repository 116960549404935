import axios from 'axios';
import { ticketConstants } from 'store/constants';
import { urlFormatter } from 'utility';
import { toast } from 'sonner';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const addTicketSuccess = (payload) => ({
  type: ticketConstants.ADD_TICKET_SUCCESS,
  payload,
  category: payload.category
});

export const addTicketFailure = (error) => ({
  type: ticketConstants.ADD_TICKET_FAILURE,
  payload: error
});

export const addTicket = (ticket) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .post(`/tickets`, ticket)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(addTicketSuccess(response.data));
        dispatch(endApiCall());
        const { origin, pathname } = window.location;
        window.location.replace(`${origin}${pathname}?tab=${ticket.category}`);
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(addTicketFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const getTicketsSuccess = (payload, category) => ({
  type: ticketConstants.GET_TICKETS_SUCCESS,
  payload,
  category
});

export const getTicketsFailure = (error) => ({
  type: ticketConstants.GET_TICKETS_FAILURE,
  payload: error
});

export const getTickets =
  (
    { category, userType, userId },
    // page = 1,
    // limit = 10,
    filterOptions
  ) =>
  (dispatch) => {
    // const endpoint =
    //   userType === 'customer'
    //     ? `/tickets/user/${userId}?page=${page}&limit=${limit}`
    //     : `/tickets?page=${page}&limit=${limit}`;

    const endpoint =
      userType === 'customer' ? `/tickets/user/${userId}?` : `/tickets?`;

    const url = urlFormatter(endpoint, filterOptions, category);

    axios
      .get(url)
      .then((response) => {
        if (response.data.status) {
          dispatch(getTicketsSuccess(response.data, category || 'all'));
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(`Error : ${error.response.data.message}`);
          dispatch(getTicketsFailure(error.response.data));
        } else {
          throw error;
        }
      });
  };

export const getTicketSuccess = (payload, current) => ({
  type: ticketConstants.GET_TICKET_SUCCESS,
  payload,
  current
});

export const getTicketFailure = (error) => ({
  type: ticketConstants.GET_TICKET_FAILURE,
  payload: error
});

export const getTicket = (ticketId) => (dispatch) => {
  axios
    .get(`/tickets/${ticketId}`)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(getTicketSuccess(response.data, 'selected'));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getTicketFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const updateTicketSuccess = (payload) => ({
  type: ticketConstants.UPDATE_TICKET_SUCCESS,
  payload
});

export const updateTicketFailure = (error) => ({
  type: ticketConstants.UPDATE_TICKET_FAILURE,
  payload: error
});

export const updateTicket = (ticketId, action) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .patch(`/tickets/${ticketId}/status`, { status: action.toLowerCase() })
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(updateTicketSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(updateTicketFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const addCommentSuccess = (payload) => ({
  type: ticketConstants.ADD_TICKET_COMMENT_SUCCESS,
  payload
});

export const addCommentFailure = (error) => ({
  type: ticketConstants.ADD_TICKET_COMMENT_FAILURE,
  payload: error
});

export const addComment = (ticketId, comment) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .post(`/tickets/${ticketId}/comments`, { comment: comment.message })
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(addCommentSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(addCommentFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const removeTicketSuccess = (payload, current) => ({
  type: ticketConstants.REMOVE_LOG_SUCCESS,
  payload,
  current
});

export const removeTicket = () => (dispatch) => {
  dispatch(removeTicketSuccess({}, 'selected'));
};
