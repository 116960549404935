import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { approvalResponse, getLog, removeLog } from 'store/actions';
import { Spinner } from 'reactstrap';
import { LoadingContent } from 'components';
import { dateFormater } from 'utility';
import Check from '../permission/Check';

export const TariffApproval = ({ entityId, entity, close }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [tarifResponse, setTarifResponse] = useState('');
  const log = useSelector((state) => state.approvals.log) || {};
  const inProgress = useSelector((state) => state.apiCalls);
  const closeModal = (event) => {
    close(event);
    dispatch(removeLog());
  };

  useEffect(() => {
    dispatch(getLog(entityId));
  }, [dispatch, entityId]);

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setMessage((prevComment) => ({
      ...prevComment,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    if (Object.values(event.currentTarget.dataset).length > 0) {
      const { response } = event.currentTarget.dataset;
      if (response) {
        setTarifResponse(response);
        dispatch(approvalResponse(entityId, response, message, closeModal));
      }
    }
  };

  const displayComment =
    entity === 'message' || (log && log.approvedOrDeclinedBy !== null);

  const displayLog = () => {
    return (
      <>
        <div className="modal-view__header">
          <h4 className="heading header-title">
            {entity === 'message' ? 'Reviewed Tariff' : 'Review Tariff'}
          </h4>
          <p className="paragraph header-text">
            Sent on {log && dateFormater(log.createdAt)}
          </p>
          <button
            className="button button-transparent button-nopadding modal-view__close"
            data-logid="0"
            onClick={closeModal}
          >
            &times;
          </button>
        </div>
        <div className="modal-view__body">
          <div className="content__area">
            <div className="modal-view__absolute">
              <span
                className={`bubble__status  bubble--${
                  log && log.status.split(' ').join('-')
                }`}
              >
                {log && log.status}
              </span>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">ESTATE:</div>
              <div className="detail col-md-8 col-sm-8">
                {log && log.estate.name}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">BUSINESS UNIT:</div>
              <div className="detail col-md-8 col-sm-8">
                {(log && log?.estate?.business_unit?.name) || 'NA'}
              </div>
            </div>
            <div className="row modal-view--details mb-3">
              <div className="header col-md-4 col-sm-4">DISCO NAME:</div>
              <div className="detail col-md-8 col-sm-8">
                {(log && log?.discoName) || 'NA'}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">DISCO CHARGE:</div>
              <div className="detail col-md-8 col-sm-8">
                {(log && log?.dosCharge) || 'NA'}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">DOS TARIFF:</div>
              <div className="detail col-md-8 col-sm-8">
                {(log && log?.dosTariff) || 'NA'}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">ENARO TARIFF:</div>
              <div className="detail col-md-8 col-sm-8">
                {(log && log.enaroTariff) || 'NA'}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">ESTATE TARIFF:</div>
              <div className="detail col-md-8 col-sm-8">
                {(log && log.requestedAmount) || 'NA'}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">REQUESTED BY:</div>
              <div className="detail col-md-8 col-sm-8">
                {(log && log?.user?.name) || 'NA'}
              </div>
            </div>
            {log && log.approverOrDecliner && (
              <div className="row modal-view--details  mb-3">
                <div className="header col-md-4 col-sm-4">REVIEWED BY:</div>
                <div className="detail col-md-8 col-sm-8">
                  {log.approverOrDecliner.name || 'Not yet reviewed'}
                </div>
              </div>
            )}

            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">DATE:</div>
              <div className="detail col-md-8 col-sm-8">
                {dateFormater(log.createdAt, 'time')}
              </div>
            </div>
            {entity && displayComment && (
              <div className="row modal-view--details  mb-3">
                <div className="header col-md-4 col-sm-4">COMMENT:</div>
                <div className="detail col-md-8 col-sm-8">
                  {log && log.comment}
                </div>
              </div>
            )}
          </div>
          <Check permission="approve-tariff">
            {entity &&
              entity === 'tariff' &&
              log.approvedOrDeclinedBy === null && (
                <form className="form">
                  <div className="form__content">
                    <div className="form-group">
                      <label>
                        Comment <small>(Optional)</small>
                      </label>
                      <textarea
                        name="comment"
                        value={message.comment}
                        className="form-control"
                        onChange={handleChange}
                        maxLength="180"
                        rows="4"
                        required
                      />
                    </div>
                  </div>
                  <div className="form__footer">
                    <div className="form__footer-buttons d-flex justify-content-end">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Button group example"
                      >
                        <button
                          type="button"
                          className="button button-primary button--outline"
                          data-response="decline"
                          onClick={handleSubmit}
                          disabled={inProgress}
                        >
                          {inProgress && tarifResponse === 'decline' && (
                            <Spinner className="me-3" color="light" />
                          )}{' '}
                          Decline
                        </button>

                        <button
                          type="button"
                          className="button button-primary button-save ms-2"
                          data-response="accept"
                          onClick={handleSubmit}
                          disabled={inProgress}
                        >
                          {inProgress && tarifResponse === 'accept' && (
                            <Spinner className="me-3" color="light" />
                          )}{' '}
                          Approve
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
          </Check>
        </div>
      </>
    );
  };

  return log.id ? displayLog() : <LoadingContent />;
};

TariffApproval.propTypes = {
  close: PropTypes.func.isRequired,
  entity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
