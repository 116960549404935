import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { addTicket } from 'store/actions';
import { validateTicket } from 'utility';
import { usersApi } from 'features/api';
import { useSecondRunEffect } from 'hooks/useSecondRunEffect';

export const SaveTicket = ({ ...props }) => {
  const initialTicket = {
    title: '',
    meterNo: '',
    body: '',
    category: 'payment'
  };

  const { close, isEdit } = props;

  const dispatch = useDispatch();
  const userType = useSelector((state) => state.profile.userType || {});
  const meters = useSelector((state) => state.profile.meters);
  const inProgress = useSelector((state) => state.apiCalls);

  const [ticket, setTicket] = useState(initialTicket);
  const [errors, setErrors] = useState({});
  const [residentDetails, setResidentDetails] = useState({});

  const [trigger, result] = usersApi.endpoints.getUserByMeterId.useLazyQuery();

  const userItem = {};
  useSecondRunEffect(() => {
    if (result?.data !== undefined) {
      setResidentDetails(result?.data);
    }
  }, [result?.data, result.isFetching]);

  const resident = residentDetails?.id
    ? {
        estate: residentDetails.estate.name,
        name: residentDetails.user.name
      }
    : {
        estate: '',
        name: ''
      };

  const saveTicket = (ticket) => {
    if (Object.keys(errors).length === 0) {
      dispatch(addTicket(ticket, close));
    }
  };

  useEffect(() => {
    if (userType === 'customer') {
      setTicket((prevTicket) => ({
        ...prevTicket,
        meterNo: meters?.[0]?.meterNo
      }));
    }
  }, [meters, userType]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validateTicket(ticket));

    const type = userType === 'customer' ? 'online' : 'offline';

    saveTicket({ ...ticket, type });
  };

  const handleChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setTicket((prevTicket) => ({
      ...prevTicket,
      [name]: value
    }));
  };

  const handleCancel = (event) => {
    setTicket(initialTicket);

    close(event);
  };

  const handleKeyUp = (event) => {
    event.preventDefault();
    const { meterNo } = ticket;

    setTimeout(() => {
      if (meterNo) {
        trigger(meterNo);
      } else if (meterNo.length === 0) {
        setResidentDetails({});
      }
    }, 2000);
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h4 className="heading header-title">
            Add {userType === 'customer' ? 'Complaint' : 'Ticket'}
          </h4>
          <p className="paragraph header-text">
            please enter the required information.
          </p>
        </div>
        <div className="form__content row gap-3">
          <div className="form-group">
            <label>
              {userType === 'customer' ? 'Complaint' : 'Ticket'} Type
            </label>
            <select
              name="category"
              value={ticket.category}
              className="form-control"
              onChange={handleChange}
            >
              <option value="payment">Payment</option>
              <option value="technical">Technical</option>
              <option value="others">Others</option>
            </select>
          </div>

          {userType === 'customer' && meters && (
            <div className="form-group">
              <label>
                Meter No <span className="form-error">{errors.meterNo}</span>
              </label>
              <select
                name="meterNo"
                value={ticket.meterNo}
                className="form-control"
                onChange={handleChange}
              >
                {meters &&
                  meters.map((meter) => {
                    return (
                      meter.isMeterActive && (
                        <option key={meter.meterNo} value={meter.meterNo}>
                          {meter.meterName || meter.meterNo}
                        </option>
                      )
                    );
                  })}
              </select>
            </div>
          )}

          {userType === 'staff' && (
            <>
              <div className="form-group">
                <label>
                  Meter No <span className="form-error">{errors.meterNo}</span>
                </label>
                <input
                  type="text"
                  name="meterNo"
                  value={ticket.meterNo}
                  className="form-control"
                  onBlur={handleKeyUp}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Name of Estate{' '}
                      <span className="form-error">{errors.estate}</span>
                    </label>
                    <input
                      type="text"
                      name="estate"
                      value={resident.estate}
                      className="form-control"
                      required
                      readOnly
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label>
                      Resident{' '}
                      <span className="form-error">{errors.resident}</span>
                    </label>
                    <input
                      type="text"
                      name="resident"
                      value={resident.name}
                      className="form-control"
                      required
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="form-group">
            <label>
              Title <span className="form-error">{errors.title}</span>
            </label>
            <input
              type="text"
              name="title"
              value={ticket.title}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>
              Complaint <span className="form-error">{errors.body}</span>
            </label>
            <textarea
              type="text"
              name="body"
              value={ticket.body}
              className="form-control"
              onChange={handleChange}
              maxLength="180"
              rows="4"
              required
            />
          </div>
        </div>

        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group"
              role="group"
              aria-label="Button group example"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="button button-save ms-2"
                onClick={handleSubmit}
                disabled={
                  (userType === 'staff' && residentDetails === undefined) ||
                  inProgress
                }
              >
                {inProgress && <Spinner className="me-3" color="light" />}{' '}
                {isEdit ? 'Edit' : 'Save'}{' '}
                {userType === 'customer' ? 'Complaint' : 'Ticket'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

SaveTicket.propTypes = {
  close: PropTypes.func.isRequired,
  getUserByMeterId: PropTypes.func,
  isEdit: PropTypes.bool.isRequired
};
