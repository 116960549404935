import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getPermissionTitle } from 'utility';
import { useGetRoleQuery } from 'features/api';

const userDetails = {};

const Check = (props) => {
  const { roleId } = useSelector((state) => state.profile);
  const { data } = useGetRoleQuery(roleId);
  const userRole = data || {};

  const userPermissions = userRole
    ? getPermissionTitle(userRole.permissions)
    : [];
  userDetails.permissions = userPermissions;

  let couldShow = false;

  switch (typeof props.permission) {
    case 'object':
      couldShow = userPermissions.some((permission) => {
        return props.permission.includes(permission);
      });
      break;
    case 'string':
      couldShow = userPermissions.includes(props.permission);
      break;
    default:
      break;
  }

  return couldShow ? props.children : null;
};

export const consume = (permission) => {
  const { permissions = [] } = userDetails;
  const found = permissions.find((perm) => perm === permission);
  return found === permission;
};

Check.propTypes = {
  permission: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default Check;
