import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Comment, SaveComment, UseModal } from 'components';
import { dateFormater } from 'utility';
import Check from '../permission/Check';

export const TicketViewDetails = ({ ticket }) => {
  const {
    body,
    comments,
    resolvedBy,
    resolvedAt,
    createdBy,
    createdAt,
    startedBy,
    startedAt,
    status
  } = ticket;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <div className="ticket__content">
      <div className="row">
        <div className="col-md-8 border-right">
          <div className="content__area">
            <div className="row ticket--details">
              <div className="header col-md-2 text-top">TICKET DETAILS:</div>
              <div className="col-md-10"> {body}</div>
            </div>
          </div>
          <Check permission={['create-ticket', 'create-complaint']}>
            {status && !['pending', 'closed'].includes(status) && (
              <div className="content__area">
                <div className="row ticket--details">
                  <div className="header col-md-2 text-top mt-3">COMMENTS:</div>
                  <div className="col-md-10">
                    <div className="ticket-details d-flex justify-content-between">
                      <p className="mt-lg-3">
                        {comments && comments.length} Comments
                      </p>{' '}
                      {status !== 'closed' && (
                        <button
                          className="button button-primary button-small button-lowcase mb-2"
                          onClick={toggle}
                        >
                          <i className="iconmoon icon-add"></i>
                          <span>Post a Comment</span>
                        </button>
                      )}
                    </div>
                    <div className="ticket--comments">
                      {comments &&
                        comments.map((comment) => {
                          return <Comment key={comment.id} item={comment} />;
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Check>
        </div>
        <div className="col-md-4">
          <div className="content__area">
            {createdBy && (
              <div className="row ticket--details  mb-3">
                <div className="header col-md-5">CREATED BY:</div>
                <div className="text-capitalize col-md-7">
                  {createdBy}{' '}
                  <span className="badge badge-pill badge-secondary p-1">
                    {dateFormater(createdAt, 'time')}
                  </span>
                </div>
              </div>
            )}
            {startedBy && startedBy !== 'pending' && (
              <div className="row ticket--details  mb-3">
                <div className="header col-md-5">STARTED BY:</div>
                <div className="text-capitalize col-md-7">
                  {startedBy}{' '}
                  <span className="badge badge-pill badge-primary p-1">
                    {dateFormater(startedAt, 'time')}
                  </span>
                </div>
              </div>
            )}
            {resolvedBy && resolvedBy !== 'pending' && (
              <div className="row ticket--details  mb-3">
                <div className="header col-md-5">RESOLVED BY:</div>
                <div className="text-capitalize col-md-7">
                  {resolvedBy}{' '}
                  <span className="badge badge-pill badge-success p-1">
                    {dateFormater(resolvedAt, 'time')}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <UseModal
        toggle={toggle}
        modal={modal}
        entity={ticket}
        component={SaveComment}
        backdrop="static"
      />
    </div>
  );
};

TicketViewDetails.propTypes = {
  ticket: PropTypes.object.isRequired
};
