import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from 'assets/svg/enaroEnergy-white.svg';
import enaroLogo from 'assets/images/enaroEnergy-logo.png';
import { Footer } from 'components';
import { parseMenuTitle } from 'utility';
import UseRoutes from '../hooks/UseRoutes';

const UserLayout = ({ children }) => {
  const { userType } = useSelector((state) => state.profile);
  const { routes } = UseRoutes();
  const location = useLocation();
  const path = location.pathname;
  const [visible, SetVisisble] = useState(false);

  useEffect(() => {
    SetVisisble(false);
  }, [path]);

  const toggleMenu = () => SetVisisble(!visible);

  return (
    <main className="wrapper">
      <div className="menuToggle">
        <div className="container-fluid">
          <div className="component d-flex justify-content-between">
            <div className="image-container">
              <img className="logo" src={enaroLogo} alt="Enaro Energy Logo" />
            </div>
            <button className="button button-primary" onClick={toggleMenu}>
              <i className={`iconmoon icon-dots`}></i> MENU
            </button>
          </div>
        </div>
      </div>
      <div className={visible ? `sidebar-reveal` : `sidebar`}>
        <div className={visible ? `` : `sidebar-wrapper`}>
          <div className="logo">
            <Logo className="" />
          </div>
          <nav className="nav flex-column">
            {routes
              .filter((route) => {
                return route.nav === true;
              })
              .map(({ url, title, icon, order }) => {
                return (
                  <NavLink key={order} to={`/app${url}`} className="nav-link">
                    <i className={`iconmoon ${icon}`}></i>
                    {parseMenuTitle(title, userType)}
                  </NavLink>
                );
              })}
          </nav>
        </div>
      </div>
      <div className="main-panel">
        <div className="content">{children}</div>
        <footer className="footer">
          <div className="container-fluid">
            <Footer />
          </div>
        </footer>
      </div>
    </main>
  );
};

UserLayout.propTypes = {
  children: PropTypes.node,
  logoutUser: PropTypes.func,
  userId: PropTypes.number
};

const DashboardLayout = UserLayout;

export { DashboardLayout };
