import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getLog, removeLog } from 'store/actions';
import { LoadingContent } from 'components';
import { dateFormater } from 'utility';

export const ViewApproval = ({ entityId, close }) => {
  const dispatch = useDispatch();
  const { log } = useSelector((state) => state.approvals);

  useEffect(() => {
    dispatch(getLog(entityId));
  }, [dispatch, entityId]);

  const closeModal = (event) => {
    close(event);
    dispatch(removeLog());
  };

  const displayLog = () => {
    return (
      <>
        <div className="modal-view__header">
          <h4 className="heading header-title">Approval</h4>
          <p className="paragraph header-text">
            Sent on {log && dateFormater(log.createdAt)}
          </p>
          <button
            className="button button-transparent button-nopadding modal-view__close"
            data-logid="0"
            onClick={closeModal}
          >
            &times;
          </button>
        </div>
        <div className="modal-view__body">
          <div className="content__area">
            <div className="modal-view__absolute">
              <span
                className={`bubble__status  bubble--${
                  log.status && log.status.split(' ').join('-')
                }`}
              >
                {log.status && log.status}
              </span>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">ESTATE:</div>
              <div className="detail col-md-8 col-sm-8">
                {log && log.estate.name}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">BUSINESS UNIT:</div>
              <div className="detail col-md-8 col-sm-8">
                {log && log?.estate?.business_unit?.name}
              </div>
            </div>
            <div className="row modal-view--details mb-3">
              <div className="header col-md-4 col-sm-4">DISCO NAME:</div>
              <div className="detail col-md-8 col-sm-8">
                {log && log.discoName}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">DISCO CHARGE:</div>
              <div className="detail col-md-8 col-sm-8">
                {log && log.dosCharge}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">DUOS TARIFF:</div>
              <div className="detail col-md-8 col-sm-8">
                {log && log.dosTariff}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">ENARO TARIFF:</div>
              <div className="detail col-md-8 col-sm-8">
                {log && log.enaroTariff}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">ESTATE TARIFF:</div>
              <div className="detail col-md-8 col-sm-8">
                {log && log.requestedAmount}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">REQUESTED BY:</div>
              <div className="detail col-md-8 col-sm-8">
                {log && log.user.name}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">REVIEWED BY:</div>
              <div className="detail col-md-8 col-sm-8">
                {(log &&
                  log.approverOrDecliner &&
                  log.approverOrDecliner.name) ||
                  'NA'}
              </div>
            </div>
            <div className="row modal-view--details  mb-3">
              <div className="header col-md-4 col-sm-4">COMMENT:</div>
              <div className="detail col-md-8 col-sm-8">
                {(log && log.comment) || 'No Comments'}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return log?.id ? displayLog() : <LoadingContent />;
};

ViewApproval.propTypes = {
  close: PropTypes.func.isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
