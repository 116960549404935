import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingContent, UseModal, StaffList } from 'components';
import Check from '../permission/Check';

export const DiscoListing = ({ discos, toggleEdit, togglePrompt }) => {
  const [modal, setModal] = useState(false);
  const [staffs, setStaff] = useState();
  const [discoName, setDiscoName] = useState();
  const toggle = event => {
    if (Object.values(event.currentTarget.dataset).length > 0) {
      const { entity, name } = event.currentTarget.dataset;

      setStaff(entity);
      setDiscoName(name);
      setModal(!modal);

      return;
    }

    setModal(!modal);
  };

  const renderTableData = () => {
    const userListing = discos.map(disco => {
      const {
        id,
        name,
        address,
        phoneNumber,
        discoStaff,
        email,
        isDeactivated
      } = disco;

      return (
        <tr className={isDeactivated ? 'disabled' : 'enabled'} key={id}>
          <td>{name}</td>
          <td>{address}</td>
          <td>{email}</td>
          <td>{phoneNumber}</td>
          <td>
            <button
              className="button button-transparent button-icon text-capitalize underline"
              title="Display Staff List"
              onClick={toggle}
              data-entity={JSON.stringify(discoStaff)}
              data-name={name}
            >
              {discoStaff.length}
            </button>
          </td>
          <td>
            <Check permission={['create-disco']}>
              <button
                className="button button-transparent button-icon text-capitalize underline"
                data-entityid={id}
                data-deactivated={isDeactivated}
                data-name={name}
                onClick={togglePrompt}
              >
                {isDeactivated ? 'Activate' : 'Deactivate'}
              </button>
            </Check>
          </td>
          <td>
            <Check permission="edit-disco">
              <button
                className="button button-transparent button-icon"
                data-entityid={id}
                onClick={toggleEdit}
                disabled={isDeactivated}
                title="Edit"
              >
                <i className="iconmoon icon-edit"></i>
              </button>
            </Check>
          </td>
        </tr>
      );
    });

    return userListing;
  };

  return (
    <div className="section table-responsive">
      <table className="table table-borderless">
        <thead className="table-header">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Address</th>
            <th scope="col">Email Address</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Staff Count</th>

            <th scope="col" colSpan="2">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="table-content">
          {discos && discos.length > 0 ? (
            renderTableData()
          ) : (
            <tr>
              <td colSpan="7">
                {discos === undefined ? <LoadingContent /> : 'No Discos'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <UseModal
        toggle={toggle}
        modal={modal}
        entity={{ staffs, discoName }}
        component={StaffList}
        backdrop="static"
      />
    </div>
  );
};

DiscoListing.propTypes = {
  toggleEdit: PropTypes.func.isRequired,
  togglePrompt: PropTypes.func.isRequired,
  discos: PropTypes.array
  // roleList: PropTypes.object
};
