import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TariffApproval, UseModal, ViewTransaction } from 'components';
import { getLog, notificationRead } from 'store/actions';
import { timeDifference } from 'utility';
import { paymentApi } from 'features/api';

export const NotificationItem = ({ items }) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [notificationId, setNotificationID] = useState(0);
  const [notificationType, setNotificationType] = useState('tariff');
  const [isReady, setIsReady] = useState(false);
  const [getPayment] = paymentApi.endpoints.getPayment.useLazyQuery();
  const toggle = () => setModal(!modal);

  const getEntity = (event) => {
    if (Object.values(event.currentTarget.dataset).length > 0) {
      const { entityid, entity, notificationid, notificationtype } =
        event.currentTarget.dataset;
      if (entityid > 0 && entity) {
        setNotificationID(entityid);
        setNotificationType(notificationtype);
        setIsReady(true);
        dispatch(notificationRead(notificationid));
      }
    }
  };

  const triggerRequest = useCallback(() => {
    if (notificationId !== 0 && isReady) {
      switch (notificationType) {
        case 'payment':
          getPayment(notificationId);
          break;
        case 'tariff':
          dispatch(getLog(notificationId));
          break;
        case 'message':
          dispatch(getLog(notificationId));
          break;
        default:
          break;
      }
      setModal(!modal);
    }
  }, [dispatch, notificationType, notificationId, isReady, modal]);

  useEffect(() => {
    triggerRequest();

    return () => {
      setIsReady(false);
    };
  }, [triggerRequest]);

  return (
    <>
      {items && items.length > 0 ? (
        items.map((notification) => {
          const { data } = notification;
          const { 0: entity, 1: entityID } = Object.entries(
            JSON.parse(data)
          )[0];

          const display = (
            <button
              className="button button-transparent button-nopadding text-capitalize"
              data-entity={entity}
              data-entityid={entityID}
              data-notificationid={notification.id}
              data-notificationtype={notification.type}
              onClick={getEntity}
            >
              {notification.title}
            </button>
          );

          return (
            <div
              className="notification-item d-flex flex-row"
              key={notification.id}
            >
              <div className="notification-status">
                <i
                  className={` ${classnames({
                    'icon-isread': notification.isRead
                  })}  iconmoon icon-blue-dot`}
                ></i>
              </div>
              <div className="notification-details">
                <div className="notification-title">{display}</div>
                <small className="notification-duration">
                  {`${timeDifference(notification.createdAt)} mins ago`}
                </small>
                <small className="notification-duration float-end me-4 text-capitalize">
                  {notification.type}
                </small>
              </div>
            </div>
          );
        })
      ) : (
        <div className="notification-item d-flex flex-row">
          <div className="notification-status">
            <i className="iconmoon icon-blue-dot icon-isread"></i>
          </div>
          <div className="notification-details">
            <div className="notification-title text-center">
              You have no notifications
            </div>
          </div>
        </div>
      )}

      <UseModal
        toggle={toggle}
        modal={modal}
        entity={notificationType}
        entityId={notificationId}
        component={
          notificationType === 'payment' ? ViewTransaction : TariffApproval
        }
        backdrop="static"
      />
    </>
  );
};

NotificationItem.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
