import React from 'react';
import PropTypes from 'prop-types';

export const Paginator = ({
  items,
  limit,
  paginate,
  currentPage,
  rowChangeHandler
}) => {
  const pageNumbers = [];

  if (items) {
    for (let i = 1; i <= Math.ceil(items.length / limit); i++) {
      pageNumbers.push(i);
    }
  }

  const rowCountChange = (event) => {
    const { value } = event.target;
    rowChangeHandler(value);
    paginate(pageNumbers[0]);
  };

  const nextPage = (event) => {
    event.preventDefault();
    paginate(currentPage + 1);
  };

  const previousPage = (event) => {
    event.preventDefault();
    paginate(currentPage - 1);
  };

  return (
    <div className="section ">
      <div className="pagination d-flex justify-content-between">
        <div className="pagination__controls-selectbox">
          <label className="me-2 mt-1">Rows per page</label>
          <select onChange={rowCountChange}>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="25">25</option>
          </select>
        </div>
        <div className="pagination__controls">
          <label className="me-2">{`showing page ${currentPage} of ${pageNumbers.length}`}</label>
          <button
            className="button button-small button-enaro pagination__navigation"
            title="Prrevious page"
            disabled={currentPage === pageNumbers[0]}
            onClick={previousPage}
          >
            <i className="iconmoon icon-angle-right"></i>
          </button>
          <button
            className="button button-small button-enaro pagination__navigation"
            title="Next page"
            disabled={currentPage === pageNumbers[pageNumbers.length - 1]}
            onClick={nextPage}
          >
            <i className="iconmoon icon-angle-left"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

Paginator.propTypes = {
  items: PropTypes.array,
  limit: PropTypes.number,
  currentPage: PropTypes.number,
  paginate: PropTypes.func,
  rowChangeHandler: PropTypes.func,
  filtered: PropTypes.bool
};
