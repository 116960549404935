import { notifConstants } from '../constants';
import initialState from './initialState';

const notificationsReducer = (state = initialState.notifications, action) => {
  switch (action.type) {
    case notifConstants.GET_ALL_NOTIFS_FAILURE:
      return { ...state };
    case notifConstants.GET_ALL_NOTIFS_SUCCESS:
      return { ...state, items: action.payload.data };
    case notifConstants.GET_NOTIFICATION:
      return {
        ...state,
        [action.category]: [...state.new.concat(action.payload)]
      };
    case notifConstants.ADD_NOTIFS_SUCCESS:
      return {
        ...state,
        socket: [...state.socket.concat(action.payload)]
      };
    case notifConstants.NOTIFS_READ_SUCCESS:
      return {
        ...state,
        socket: [
          ...state.socket.map(notification => {
            const notificationItem =
              notification.id === action.entityId
                ? { ...notification, isRead: true }
                : notification;

            return notificationItem;
          })
        ]
      };
    default:
      return state;
  }
};

export default notificationsReducer;
