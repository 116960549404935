import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const EstateCard = ({ estate, path }) => {
  return (
    <div className="overview__estate--card">
      <div className="overview__estate--card-title">
        <Link title="Click to view" to={`${path}/estate`} state={estate}>
          {estate.name}
        </Link>
      </div>
      <div className="overview__estate--card-details">
        <small className="overview__estate--card-subtitle">Location</small>
        <br />
        <small className="overview__estate--card-street text-nowrap text-truncate">
          {estate.street}
        </small>
        {/* <p className="overview__estate--card-subtitle"></p> */}
      </div>
    </div>
  );
};

EstateCard.propTypes = {
  estate: PropTypes.object,
  path: PropTypes.string
};
