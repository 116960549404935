import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'features/baseQuery';

export const permissionsApi = createApi({
  reducerPath: 'permissionsApi',
  baseQuery,
  tagTypes: ['Permissions'],
  endpoints: builder => ({
    getPermission: builder.query({
      query: () => '/permissions',
      providesTags: ['Permissions']
    })
  })
});

export const { useGetPermissionQuery } = permissionsApi;
