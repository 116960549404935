import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

export const SaveEstateModal = ({
  modal,
  toggle,
  isEdit,
  data,
  estate,
  component: Component
}) => {
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalBody>
          <Component
            close={toggle}
            data={data}
            estateId={estate}
            isEdit={isEdit}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

SaveEstateModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  estate: PropTypes.number.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};
