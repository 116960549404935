import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader } from 'components';

export function AuthRoute({ component: Component, layout: Layout, ...rest }) {
  const { loggedIn } = useSelector(state => state.auth);

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <Suspense fallback={<Loader show />}>
      <Layout>
        <Component {...rest} />
      </Layout>
    </Suspense>
  );
}

AuthRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType.isRequired
};
