import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => (
  <div className="d-flex justify-content-between">
    <p className="paragraph footer__title">
      Enaro Energy
      <span className="copyright">
        &copy; Copyright {new Date().getFullYear()}{' '}
      </span>
    </p>
    <p>
      <Link to="privacypolicy">Privacy Policy</Link>
    </p>
  </div>
);
