import { estateConstants } from '../constants';
import initialState from './initialState';

const estateReducer = (state = initialState.estates, action) => {
  switch (action.type) {
    case estateConstants.GET_ESTATE_FAILURE:
      return { ...state };
    case estateConstants.GET_ESTATE_SUCCESS:
      return { ...state, item: action.payload.data };
    case estateConstants.GET_ESTATES_FAILURE:
      return { ...state };
    case estateConstants.GET_ESTATES_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case estateConstants.ADD_ESTATE_FAILURE:
      return { ...state };
    case estateConstants.ADD_ESTATE_SUCCESS:
      return { ...state };
    case estateConstants.DEACTIVATE_ESTATE_FAILURE:
      return { ...state };
    case estateConstants.DEACTIVATE_ESTATE_SUCCESS:
      return { ...state };

    case estateConstants.GET_ESTATES_REVENUE_SUCCESS:
      return { ...state, revenue: action.payload.data };
    case estateConstants.GET_ESTATES_REVENUE_FAILURE:
      return { ...state };
    default:
      return state;
  }
};

export default estateReducer;
