import React from 'react';

export const OverviewEnergyBuckets = () => {
  return (
    <>
      <div className="bucket mb-sm-2 m-md-0">
        <p className="bucket__title">Solar</p>
        <p className="bucket__figure">100kWh</p>
      </div>

      <div className="bucket mb-sm-2 m-md-0">
        <p className="bucket__title">Disco</p>
        <p className="bucket__figure">100kWh</p>
      </div>

      <div className="bucket mb-sm-2 m-md-0">
        <p className="bucket__title">Gas</p>
        <p className="bucket__figure">100kWh</p>
      </div>

      <div className="bucket mb-sm-2 m-md-0">
        <p className="bucket__title">Diesel</p>
        <p className="bucket__figure">100kWh</p>
      </div>
    </>
  );
};
