import axios from 'axios';
import { unitsConstants } from 'store/constants';
import { toast } from 'sonner';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const addUnitSuccess = (unit) => ({
  type: unitsConstants.ADD_UNITS_SUCCESS,
  payload: unit
});

export const addUnitFailure = (error) => ({
  type: unitsConstants.ADD_UNITS_FAILURE,
  payload: error
});

export const addUnit = (unitDetails, closeModal) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .post('/business-units', unitDetails)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(addUnitSuccess(response.data));
        dispatch(endApiCall());
        closeModal();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(addUnitFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const getUintsSuccess = (units) => ({
  type: unitsConstants.GET_ALL_UNITS_SUCCESS,
  payload: units
});

export const getUintsFailure = (error) => ({
  type: unitsConstants.GET_ALL_UNITS_FAILURE,
  payload: error
});

export const getUints = () => (dispatch) => {
  axios
    .get(`/business-units`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getUintsSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(getUintsFailure(error.response.data));
      } else {
        throw error;
      }
    });
};
