import axios from 'axios';
import { notifConstants } from 'store/constants';
// import { beginApiCall } from './apiActions';
import { toast } from 'sonner';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const getsNotifsSuccess = (payload) => ({
  type: notifConstants.GET_ALL_NOTIFS_SUCCESS,
  payload
});

export const getNotifsFailure = (error) => ({
  type: notifConstants.GET_ALL_NOTIFS_FAILURE,
  payload: error
});

export const getNotifications = () => (dispatch) => {
  const url = '/notifications';
  axios
    .get(url)
    .then((response) => {
      if (response.data.status) {
        dispatch(getsNotifsSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getNotifsFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const addNotificationSuccess = (payload, permission) => ({
  type: notifConstants.ADD_NOTIFS_SUCCESS,
  payload,
  permission
});

export const addNotification = (notification, permission) => (dispatch) => {
  dispatch(addNotificationSuccess(notification, permission));
};

export const notificationReadSuccess = (entityId) => ({
  type: notifConstants.NOTIFS_READ_SUCCESS,
  entityId
});

export const notificationRead = (notificationId) => (dispatch) => {
  dispatch(notificationReadSuccess(notificationId));
};
