import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown
} from 'reactstrap';
import PropTypes from 'prop-types';
import { logoutUser } from 'store/actions';
import { BadgeNotification } from 'components';
import Check from '../permission/Check';

export const PageHeader = ({ ...props }) => {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.profile);

  const checkDisplay = (passedProps) => {
    return passedProps.back ? (
      <div className="content__title text-capitalize">{passedProps.back}</div>
    ) : (
      <div className="content__title text-capitalize">
        <h3 className="medium-heading">{passedProps.title || name}</h3>
        {/* <small>{passedProps.copy}</small> */}
      </div>
    );
  };

  const logOut = (event) => {
    event.preventDefault();
    dispatch(logoutUser());
  };

  return (
    <div className="section">
      <div className="container-fluid">
        <div className="row">
          <div className="col col-md-7">{checkDisplay(props)}</div>
          <div className="col col-md-5">
            {/* search, badge notification, */}

            <div className="content__header">
              <Navbar color="light" light expand="md" className="float-end">
                <Nav className="me-auto" navbar>
                  <UncontrolledDropdown size="sm" nav inNavbar>
                    <DropdownToggle nav caret>
                      <small>{name}</small>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={logOut}>
                        <small>Log Out</small>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Navbar>

              <Check
                permission={[
                  'staff-payment-notification',
                  'tariff-notification',
                  'customer-payment-notification'
                ]}
              >
                <BadgeNotification className="col-md-3" />
              </Check>

              <div className="content__switcher col-md-6 float-end">
                {props.switcher}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  badge: PropTypes.object,
  switcher: PropTypes.object
};
