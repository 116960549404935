import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { cancelSubscription } from 'store/actions';

const CancelSubscription = ({
  modal,
  toggle,
  amount,
  frequency,
  subscriptionId,
  meterNo
}) => {
  const closePrompt = (event) => {
    toggle(event);
  };

  return (
    <Modal className="prompt" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody>
        <div className="p-4 text-center">
          <h5>Cancel {frequency} Subscription</h5>
          <p>
            You will no longer be charged ₦ {amount} {frequency}. Do you wish to
            continue?
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <button
          type="button"
          className="button button-primary"
          onClick={closePrompt}
        >
          Cancel
        </button>

        <button
          type="button"
          className="button button-secondary ms-2"
          onClick={() => cancelSubscription(subscriptionId, meterNo, toggle)}
        >
          Continue
        </button>
      </ModalFooter>
    </Modal>
  );
};

CancelSubscription.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  meterNo: PropTypes.string.isRequired,
  subscriptionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  frequency: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};
export default CancelSubscription;
