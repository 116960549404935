import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from 'store/actions';
import { getNameSpaces } from 'utility';
import { sockets } from 'webSocket';
import nameSpaces from '../webSocket/nameSpaces';
import { useGetRoleQuery } from 'features/api';
import { toast } from 'sonner';

const UseSockets = () => {
  const dispatch = useDispatch();

  const { loggedIn } = useSelector((state) => state.auth);
  const { roleId } = useSelector((state) => state.profile);
  const { data } = useGetRoleQuery(roleId);
  const userRole = data || {};

  useEffect(() => {
    if (loggedIn && userRole?.id && roleId) {
      const nsps = getNameSpaces(nameSpaces, userRole);

      const userSockets = sockets.filter((namespace) => {
        return nsps.includes(namespace.nsp.split('/')[1]);
      });
      userSockets.forEach((Socket) => {
        Socket.on(nameSpaces[Socket.nsp.split('/')[1]].message, (data) => {
          const { notification } = data;
          toast.success('added');
          dispatch(addNotification(notification));
        });
      });
    }

    return () => {
      // cleanup
    };
  }, [loggedIn, userRole?.id, roleId, dispatch]);
};

export default UseSockets;
