import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle
} from 'reactstrap';

export const MeterCard = ({ meter, openModal }) => {
  const {
    meterNo,
    meterName,
    isMeterActive,
    estate: { payments }
  } = meter;

  return (
    <>
      <Card className="meter-card">
        <CardHeader className="meter-card__header">
          <div className="float-start">
            {`${meterName}`} : {`${meterNo}`}
          </div>
          <div className="meter-card__button--edit">
            <button
              className="button button-transparent button-icon float-end"
              data-meterno={meterNo}
              data-payment={JSON.stringify(payments)}
              onClick={openModal}
              // disabled={isDeactivated}
              title="Edit"
            >
              <i className="iconmoon icon-edit"></i>
            </button>
          </div>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-md-8">
              <CardTitle className="meter-card__text">
                Amount of Units Available
              </CardTitle>
              <CardText className="meter-card__unit">50</CardText>
            </div>
            <div className="col-md-4">
              <div className="meter-card__status float-end">
                <small
                  className={
                    isMeterActive
                      ? 'meter-card__status-active'
                      : 'meter-card__status-inactive'
                  }
                >
                  {isMeterActive ? 'Active  ' : 'InActive  '}&#8226;
                </small>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter className="meter-card__footer">
          <p className="mb-0">
            <small>
              <span className="meter-card__footer--title">
                Last recharging date:
              </span>{' '}
              November, 28th 2019
            </small>
          </p>
        </CardFooter>
      </Card>
    </>
  );
};

MeterCard.propTypes = {
  meter: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  meterNo: PropTypes.string,
  meterName: PropTypes.string
};
