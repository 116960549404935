import { apiConstants } from '../constants';
import initialState from './initialState';

const apiReducer = (state = initialState.apiCalls, action) => {
  if (action.type === apiConstants.BEGIN_API_CALL) {
    return true;
  }

  if (action.type === apiConstants.END_API_CALL) {
    return false;
  }

  return state;
};

export default apiReducer;
