import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { dateFormater, statusParser } from 'utility';

export const TicketViewHeader = ({ ticket, updateHandler }) => {
  const { id, createdAt, category, estateName, type, status } = ticket;
  const dispatch = useDispatch();

  const { userType } = useSelector((state) => state.profile);
  const inProgress = useSelector((state) => state.apiCalls);

  const [stage, setStage] = useState(false);

  const changeStatus = (event) => {
    setStage(true);
    event.preventDefault();
    if (event.currentTarget.dataset) {
      const { action } = event.currentTarget.dataset;
      dispatch(updateHandler(id, action, category));
    }
    // setStage(false);
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-12 ">
          <div className="ticket--details clearfix">
            <div className="ticket--heading float-start">
              <span className="section-heading">
                <b>{estateName}</b>
              </span>
              <br />
              <span>
                <span className={`ticket__source--${type}`}>{type} </span>ticket
                created on {dateFormater(createdAt, 'time')}
              </span>
            </div>
            <p className="float-end mt-3">
              Ticket Status:{' '}
              <span
                className={`bubble__status bubble--${status
                  .split(' ')
                  .join('-')}`}
              >
                {status}
              </span>
            </p>
          </div>
          <div className="ticket--details clearfix">
            {statusParser(status, userType) &&
              statusParser(status, userType).map((ticketStatus, index) => {
                return (
                  <button
                    key={index}
                    type="button"
                    className="button button-primary button-small float-start mt-3"
                    onClick={changeStatus}
                    data-action={ticketStatus.value}
                    disabled={inProgress}
                  >
                    {inProgress && stage && (
                      <Spinner className="me-3" color="light" />
                    )}{' '}
                    {`${ticketStatus.title} ${
                      userType === 'customer' ? 'complaint' : 'ticket'
                    }`}
                  </button>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

TicketViewHeader.propTypes = {
  updateHandler: PropTypes.func.isRequired,
  ticket: PropTypes.object.isRequired
};
