import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useInitializeCustomerPaymentMutation } from 'features/api';

const CreatePaymentPlanPrompt = ({
  modal,
  toggle,
  amount,
  meterNo,
  isRecurring,
  plan,
  user,
  estateId,
  paymentType
}) => {
  const [inProgress, setInProgress] = useState(false);

  const planCreated = useSelector((state) => state.paystack.plan);

  const closePrompt = useCallback(
    (event) => {
      toggle(event);
      setInProgress(false);
    },
    [toggle]
  );

  const [initializeCustomerPayment, { isLoading, isSuccess }] =
    useInitializeCustomerPaymentMutation();

  const createPaymentPlan = () => {
    if (isRecurring && plan.length > 0) {
      const planData = {
        amount: amount * 100,
        email: user.email,
        customerId: user.id,
        estateId,
        type: paymentType,
        meterNo,
        plan: { name: plan, interval: plan.toLowerCase() }
      };
      setInProgress(true);
      initializeCustomerPayment(planData);
    }
  };

  useEffect(() => {
    if (inProgress && planCreated) {
      closePrompt();
    }
  }, [planCreated, closePrompt, isSuccess, inProgress]);

  return (
    <Modal className="prompt" isOpen={modal} toggle={toggle} backdrop="static">
      <ModalBody>
        <div className="p-4 text-center">
          <h5>Create {plan} Subscription</h5>
          <p>
            You are about to create a {plan} plan. You will be charged ₦{' '}
            {amount} {plan} for Meter {meterNo}. Do you wish to continue?
          </p>
          <small>Subscription can be cancelled at any time.</small>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <button
          type="button"
          className="button button-primary"
          onClick={closePrompt}
        >
          Cancel
        </button>
        {isLoading ? (
          <Spinner />
        ) : (
          <button
            type="button"
            className="button button-secondary ms-2"
            onClick={createPaymentPlan}
          >
            Continue
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

CreatePaymentPlanPrompt.propTypes = {
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isRecurring: PropTypes.bool.isRequired,
  meterNo: PropTypes.string.isRequired,
  plan: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  estateId: PropTypes.string
};
export default CreatePaymentPlanPrompt;
