import React, { lazy } from 'react';
import { Toaster } from 'sonner';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import PrivateRoute from 'routes/PrivateRoute';
import NoAuthRoute from 'routes/NoAuthRoute';
import AuthLayout from 'layouts/AuthLayout';

const Scroll = (props) => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return props.children;
};

Scroll.propTypes = {
  location: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const ScrollToTop = Scroll;

const Login = lazy(() => import('pages/Login'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const SetPassword = lazy(() => import('pages/SetPassword'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Toaster
          toastOptions={{
            className: 'p-3',
            duration: 4000
          }}
          closeButton={true}
          richColors
          position="top-right"
        />
        <ScrollToTop>
          <Routes>
            <Route path="/">
              <Route index element={<Navigate to="/login" />} />
              <Route
                path="login"
                element={<NoAuthRoute layout={AuthLayout} component={Login} />}
              />

              <Route
                path="forgot-password"
                element={
                  <NoAuthRoute layout={AuthLayout} component={ForgotPassword} />
                }
              />

              <Route
                path="reset-password/:token"
                element={
                  <NoAuthRoute layout={AuthLayout} component={ResetPassword} />
                }
              />

              <Route
                path="set-password/:token"
                element={
                  <NoAuthRoute layout={AuthLayout} component={SetPassword} />
                }
              />

              <Route
                path="privacypolicy"
                element={
                  <NoAuthRoute layout={AuthLayout} component={PrivacyPolicy} />
                }
              />
            </Route>
            <Route path="app/*" element={<PrivateRoute />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
};

export default App;
