import { subscriptionConstants } from '../constants';
import initialState from './initialState';

const subscriptionReducer = (state = initialState.subscription, action) => {
  switch (action.type) {
    case subscriptionConstants.FETCH_SUBSCRIPTION_BY_METER_SUCCESS:
      return action.payload.data;
    case subscriptionConstants.FETCH_SUBSCRIPTION_BY_METER_FAILURE:
      return { ...state };

    case subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS_SUCCESS:
      return { ...state, subscription: action.payload };
    case subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS_FAILURE:
      return { ...state };

    case subscriptionConstants.RESET_SUBSCRIPTIONS_SUCCESS:
      return initialState.subscription;
    default:
      return state;
  }
};
export default subscriptionReducer;
