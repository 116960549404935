import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, TabContent } from 'reactstrap';
import { TabDetails, TabLink } from 'components';
import Check from '../permission/Check';

export const Tabs = ({ defaultTab, path, ...props }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const getTab = async () => {
      try {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const tab = params.get('tab') || defaultTab;

        setActiveTab(tab);
      } catch (error) {
        // TODO Display error properly
      }
    };

    getTab();
  }, [defaultTab]);

  return (
    <div className="section">
      <Nav tabs pills>
        {props.tabs.map((tab) => {
          const { tabId, title, permission } = tab;
          return (
            <Check permission={permission} key={tabId}>
              <TabLink
                key={tabId}
                title={title}
                tabId={tabId}
                category={tabId}
                path={path}
                toggle={toggle}
                activeTab={activeTab}
              />
            </Check>
          );
        })}
      </Nav>
      <div className="container-fluid mt-4">
        <TabContent activeTab={activeTab}>
          {props.tabs.map((tab) => {
            const { tabId, component } = tab;
            return (
              <TabDetails
                key={tabId}
                tabId={tabId}
                component={component}
                {...props}
              />
            );
          })}
        </TabContent>
      </div>
    </div>
  );
};

Tabs.propTypes = {
  defaultTab: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  tabs: PropTypes.array
};
