import axios from 'axios';
import { toast } from 'sonner';
import { subscriptionConstants } from '../constants';

const fetchSubscriptionByMeterSuccess = (payload) => ({
  type: subscriptionConstants.FETCH_SUBSCRIPTION_BY_METER_SUCCESS,
  payload
});

const fetchSubscriptionByMeterFailure = (error) => ({
  type: subscriptionConstants.FETCH_SUBSCRIPTION_BY_METER_FAILURE,
  payload: error
});

const updateSubscriptionStatusSuccess = (payload) => ({
  type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS_SUCCESS,
  payload
});
const updateSubscriptionStatusFailure = (error) => ({
  type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS_FAILURE,
  payload: error
});
const resetSubscriptionsSuccess = (payload) => ({
  type: subscriptionConstants.RESET_SUBSCRIPTIONS_SUCCESS,
  payload
});

export const fetchSubscriptionByMeterNo = (meterNo) => (dispatch) => {
  axios
    .get(`/subscriptions?meterNo=${meterNo}`)
    .then((response) => {
      if (response.status) {
        dispatch(fetchSubscriptionByMeterSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.message) {
        toast.error(`Error: ${error.message}`);
        dispatch(fetchSubscriptionByMeterFailure(error.response));
      } else {
        throw error;
      }
    });
};

export const updateSubscription = (id, meterNo) => {
  axios
    .patch(`/subscriptions/${id}`, {
      isActive: false,
      meterNo
    })
    .then((response) => {
      if (response.status) {
        updateSubscriptionStatusSuccess(response.data);
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.message) {
        toast.error(error.message);
        updateSubscriptionStatusFailure(error.response);
      } else {
        throw error;
      }
    });
};

export const resetSubscriptions = () => (dispatch) => {
  dispatch(resetSubscriptionsSuccess({}));
};
