import { authConstants } from '../constants';
import initialState from './initialState';

const authReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        status: false
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        email: action.payload.data.email,
        id: action.payload.data.id,
        name: action.payload.data.name,
        token: action.payload.data.token,
        loggedIn: true,
        permissions: action.payload.data.permissions
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        token: ''
      };
    case authConstants.RESET_REQUEST_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message
      };
    case authConstants.RESET_REQUEST_FAILURE:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message
      };
    case authConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        token: ''
      };
    case authConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state
      };
    case authConstants.SET_PASSWORD_SUCCESS:
      return {
        ...state
      };
    case authConstants.SET_PASSWORD_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default authReducer;
