import React from 'react';
import PropTypes from 'prop-types';
import { formatUnit } from 'utility';
// import { Link } from 'react-router-dom';

export const StaffBuckets = ({
  discoName = 'NA',
  tariff,
  businessUnit,
  residentTotal = 0
}) => {
  return (
    <div className="row dashboard-buckets">
      <div className="col-md-3">
        <div className="bucket mb-2">
          <p className="bucket__title">Disco Name</p>
          <p className="bucket__figure">{discoName}</p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="bucket mb-2">
          <p className="bucket__title">Estate Tariff</p>
          <p className="bucket__figure">
            {(tariff && formatUnit(tariff)) || 'Not approved'}
          </p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="bucket mb-2">
          <p className="bucket__title">Undertaken</p>
          <p className="bucket__figure">
            {(businessUnit && businessUnit.name) || 'NA'}
          </p>
        </div>
      </div>
      <div className="col-md-3">
        <div className="bucket mb-2">
          <p className="bucket__title">Resident Count</p>
          <p className="bucket__figure">{residentTotal}</p>
        </div>
      </div>
    </div>
  );
};

StaffBuckets.propTypes = {
  discoName: PropTypes.string,
  tariff: PropTypes.number,
  businessUnit: PropTypes.object,
  residentTotal: PropTypes.number
};
