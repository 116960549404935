import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

const api = createApi({
  reducerPath: 'locationApi',
  baseQuery: fetchBaseQuery({ baseUrl: uri }),
  tagTypes: ['Location'],
  endpoints: builder => ({
    getCities: builder.query({
      query: () => '/cities',
      transformResponse: response => {
        if (response.status) {
          return response;
        }
        throw new Error('Failed to fetch cities');
      },
      providesTags: ['Cities']
    }),
    getStates: builder.query({
      query: () => '/states',
      transformResponse: response => {
        if (response.status) {
          return response;
        }
        throw new Error('Failed to fetch states');
      },
      providesTags: ['States']
    }),
    addCity: builder.mutation({
      query: city => ({
        url: '/cities',
        method: 'POST',
        body: { cityName: city.name, stateId: city.stateId }
      }),
      invalidatesTags: ['Cities']
    }),
    editCity: builder.mutation({
      query: ({ city, cityId }) => ({
        url: `/cities/${cityId}`,
        method: 'PATCH',
        body: { cityName: city.name }
      }),
      invalidatesTags: ['Cities']
    }),
    deleteCity: builder.mutation({
      query: ({ cityId }) => ({
        url: `/cities/${cityId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Cities']
    })
  })
});

export const {
  useGetCitiesQuery,
  useGetStatesQuery,
  useAddCityMutation,
  useEditCityMutation,
  useDeleteCityMutation
} = api;
