import { unitsConstants } from '../constants';
import initialState from './initialState';

const unitReducer = (state = initialState.units, action) => {
  switch (action.type) {
    case unitsConstants.GET_ALL_UNITS_FAILURE:
      return [...state];
    case unitsConstants.GET_ALL_UNITS_SUCCESS:
      return action.payload.data;
    case unitsConstants.ADD_UNITS_FAILURE:
      return [...state];
    case unitsConstants.ADD_UNITS_SUCCESS:
      return [...state, action.payload.data];
    default:
      return state;
  }
};

export default unitReducer;
