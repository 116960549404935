export default {
  'tariff-notification': {
    message: 'approvals'
  },
  'staff-payment-notification': {
    message: 'payments'
  },
  'customer-payment-notification': {
    message: 'customer payment'
  }
};
