import axios from 'axios';
import { discoConstants } from 'store/constants';
import { getOptionsId } from 'utility';
import { toast } from 'sonner';
import { beginApiCall, endApiCall } from './apiActions';
import environment from '../../environment';

const {
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

export const getDiscoSuccess = (cities) => ({
  type: discoConstants.GET_DISCO_SUCCESS,
  payload: cities
});

export const getDiscoFailure = (error) => ({
  type: discoConstants.GET_DISCO_FAILURE,
  payload: error
});

export const getDisco = (discoId) => (dispatch) => {
  axios
    .get(`/discos/${discoId}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getDiscoSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(getDiscoFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const getDiscosSuccess = (states) => ({
  type: discoConstants.GET_DISCOS_SUCCESS,
  payload: states
});

export const getDiscosFailure = (error) => ({
  type: discoConstants.GET_DISCOS_FAILURE,
  payload: error
});

export const getDiscos = () => (dispatch) => {
  axios
    .get(`/discos`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getDiscosSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(getDiscosFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const addDiscoSuccess = (payload) => ({
  type: discoConstants.ADD_DISCO_SUCCESS,
  payload
});

export const addDiscoFailure = (error) => ({
  type: discoConstants.ADD_DISCO_FAILURE,
  payload: error
});

export const addDisco = (disco, closeModal) => (dispatch) => {
  dispatch(beginApiCall());

  const discoDetails = {
    ...disco,
    staffIds: getOptionsId(disco.staffIds)
  };

  axios
    .post(`/discos/`, discoDetails)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(addDiscoSuccess(response.data));
        dispatch(endApiCall());
        closeModal();
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(addDiscoFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const editDiscoSuccess = (payload) => ({
  type: discoConstants.EDIT_DISCO_SUCCESS,
  payload
});

export const editDiscoFailure = (error) => ({
  type: discoConstants.EDIT_DISCO_FAILURE,
  payload: error
});

export const editDisco = (editOptions, discoId) => (dispatch) => {
  const { discoStaff, isDeactivated, staffIds, ...options } = editOptions;

  const discoDetails = {
    ...options,
    assignStaff: getOptionsId(options.assignStaff),
    unassignStaff: getOptionsId(options.unassignStaff)
  };

  dispatch(beginApiCall());
  axios
    .patch(`/discos/${discoId}`, discoDetails)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(editDiscoSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editDiscoFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const deactivateDisco = (action, entity) => (dispatch) => {
  dispatch(beginApiCall());

  const event = action.isDeactivated ? `deactivate` : `activate`;

  axios
    .post(`/discos/${entity.entityId}/action`, { action: event })
    .then((response) => {
      if (response.data.status) {
        if (Object.keys(action)[0] === 'isDeactivated') {
          const status = action.isDeactivated ? 'deactivated' : 'activated';
          toast.success(
            `Success : ${entity.name}  has been ${status} successfully`
          );
        }

        dispatch(editDiscoSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(editDiscoFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};

export const getDiscoEstatesSuccess = (roles) => ({
  type: discoConstants.GET_ESTATES_SUCCESS,
  payload: roles
});

export const getDiscoEstatesFailure = (error) => ({
  type: discoConstants.GET_ESTATES_FAILURE,
  payload: error
});

export const getDiscoEstates = (discoId) => (dispatch) => {
  axios
    .get(`/discos/${discoId}/estates`)
    .then((response) => {
      if (response.data.status) {
        dispatch(getDiscoEstatesSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(getDiscoEstatesFailure(error.response.data));
      } else {
        throw error;
      }
    });
};

export const deleteDiscoSuccess = (payload) => ({
  type: discoConstants.DELETE_DISCO_SUCCESS,
  payload
});

export const deleteDiscoFailure = (error) => ({
  type: discoConstants.DELETE_DISCO_FAILURE,
  payload: error
});

export const deleteDisco = (discoId) => (dispatch) => {
  dispatch(beginApiCall());
  axios
    .delete(`/discos/${discoId}`)
    .then((response) => {
      if (response.data.status) {
        toast.success(`Success : ${response.data.message}`);
        dispatch(deleteDiscoSuccess(response.data));
        dispatch(endApiCall());
        window.location.reload();
      }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(`Error : ${error.response.data.message}`);
        dispatch(deleteDiscoFailure(error.response.data));
        dispatch(endApiCall());
      } else {
        throw error;
      }
    });
};
