import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { validateUser } from 'utility';
import { useReassignResidentMutation } from 'features/api';

export const ResidentReassign = ({ ...props }) => {
  const dispatch = useDispatch();
  const initialResident = {
    name: '',
    email: '',
    phoneNumber: '',
    userType: 'customer',
    meterNo: '',
    newMeterNo: '',
    oldMeterNo: '',
    estateId: ''
  };

  const { close, entityId, entity } = props;
  const { userId, meter } = entity;

  const residentEdit = useSelector((state) => {
    if (userId !== 0 && !Number.isNaN(userId)) {
      const residentInfo = state.residents.residents.find(
        (user) => user.id === userId && user.meterNo === meter
      );

      return {
        ...initialResident,
        ...residentInfo,
        oldMeterNo: residentInfo.meterNo,
        estateId: entityId
      };
    }
  });

  const residentDetails = userId === 0 ? initialResident : residentEdit;

  const [resident, setResident] = useState(residentDetails || initialResident);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reassignResident, { isLoading: isCreating, isSuccess }] =
    useReassignResidentMutation();
  const saveResident = useCallback(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      reassignResident(resident);
    }
  }, [errors, dispatch, isSubmitting, resident]);

  useEffect(() => {
    saveResident();
    return () => {
      setIsSubmitting(false);
    };
  }, [saveResident]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validateUser(resident, 'reassign'));
    setIsSubmitting(true);
  };

  const handleChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;

    setResident((prevResident) => ({
      ...prevResident,
      [name]: value,
      estateId: entityId
    }));
  };

  const handleCancel = (event) => {
    setResident(initialResident);
    close(event);
  };

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h4 className="heading header-title">Reassign Meter</h4>
        </div>
        <div className="form__content row gap-3">
          <div className="form-group">
            <label>
              Name <span className="form-error">{errors.name}</span>
            </label>
            <input
              type="text"
              name="name"
              value={resident.name}
              className="form-control"
              onChange={handleChange}
              required
              disabled
            />
          </div>
          <div className="form-group">
            <label>
              Email <span className="form-error">{errors.email}</span>
            </label>
            <input
              type="email"
              name="email"
              value={resident.email}
              className="form-control"
              onChange={handleChange}
              required
              disabled
              // disabled={isEdit}
            />
          </div>

          <div className="form-group">
            <label>
              Phone Number{' '}
              <span className="form-error">{errors.phoneNumber}</span>
            </label>
            <input
              type="text"
              name="phoneNumber"
              value={resident.phoneNumber}
              className="form-control"
              onChange={handleChange}
              required
              disabled
            />
          </div>
          <div className="form-group">
            <label>Old Meter Number</label>
            <input
              type="text"
              name={'meterNo'}
              value={resident.oldMeterNo}
              className="form-control"
              onChange={handleChange}
              required
              disabled
            />
          </div>
          <div className="form-group">
            <label>
              New Meter Number{' '}
              <span className="form-error">{errors.newMeterNo}</span>
            </label>
            <input
              type="text"
              name={'newMeterNo'}
              value={resident.newMeterNo}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group "
              role="group"
              aria-label="Button group example"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                type="button"
                className="button button-save ms-2"
                onClick={handleSubmit}
                disabled={isCreating}
              >
                {isCreating && <Spinner className="me-3" color="light" />}{' '}
                Reassign
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

ResidentReassign.propTypes = {
  close: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  entity: PropTypes.object.isRequired
};
