import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { validateUnits } from 'utility/validate';
import Payment from 'utility/Payment';
import { toast } from 'sonner';
import { paymentApi, useAddPaymentMutation, usersApi } from 'features/api';
import { BuyUnitsPrompt } from './BuyUnitsPrompt';
import { useSecondRunEffect } from 'hooks/useSecondRunEffect';
import { formatCurrency, KoboToNaira, NairaToKobo } from 'utility';
import { NumericFormat } from 'react-number-format';

const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

export const BuyUnitsStaff = ({ close }) => {
  const initialValues = {
    meterno: '',
    amount: 0,
    paymentType: 'oneTime'
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [tariff, setTariff] = useState();
  const [unit, setUnit] = useState(0);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modal, setModal] = useState(false);
  const [residentDetails, setResidentDetails] = useState({});

  const [paymentParams, setPaymentParams] = useState({ formValues });
  const [trigger, result] = usersApi.endpoints.getUserByMeterId.useLazyQuery();
  const [addPayment, { isSuccess, isLoading }] = useAddPaymentMutation();
  // eslint-disable-next-line no-unused-vars

  useSecondRunEffect(() => {
    if (result.data !== undefined) {
      setResidentDetails(result.data);
    }
  }, [result.data, result.isFetching]);

  const resetForm = () => {
    setTariff();
    setFormValues(initialValues);
    setUnit(0);
  };

  const { userType } = useSelector((state) => state.profile);

  const resident = residentDetails?.id
    ? {
        estate: residentDetails.estate.name,
        name: residentDetails.user.name
      }
    : {
        estate: '',
        name: ''
      };

  const { meterno, amount } = formValues;

  const [getBreakdown, { data }] =
    paymentApi.endpoints.getBreakdown.useLazyQuery();

  const getBreakdownDetails = (event) => {
    event.preventDefault();

    setTimeout(() => {
      if (amount) {
        getBreakdown({ amount: NairaToKobo(amount) });
      }
    }, 2000);
  };

  const { vat = 0, total = 0 } = data || {};

  const toggleModal = useCallback(() => {
    setModal(!modal);
  }, [modal]);

  useEffect(() => {
    if (isSuccess) {
      toggleModal();
      close();
    }
  }, [isSuccess]);

  const findMeter = (event) => {
    event.preventDefault();

    setTimeout(() => {
      if (meterno) {
        trigger(meterno);
      } else if (meterno.length === 0) {
        setResidentDetails({});
      }
    }, 2000);
  };

  const handleChange = (event) => {
    const { name, value } = event.currentTarget;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    if (name === 'meterno' && value === '') {
      resetForm();
    }
  };

  const handleAmountChange = (event) => {
    const { formattedValue, value, floatValue } = event;
    setFormValues((prevValues) => ({
      ...prevValues,
      amount: floatValue,
      formattedValue
    }));
    if (residentDetails) {
      setUnit((floatValue || 0) / tariff);
    }
    if (name === 'meterno' && value === '') {
      resetForm();
    }
  };

  const { closeForm, submitForm } = Payment;

  useEffect(() => {
    if (residentDetails?.id && residentDetails.estate.name !== '') {
      setTariff(residentDetails.estate.estateTariff);
    }
    if (residentDetails?.id && residentDetails.estate.name !== '') {
      if (!residentDetails.estate.isTariffApproved) {
        // resetMeter();
        toast.info(
          `Payments cannot be made on meter ${meterno}. Tariff for this ${residentDetails.estate.name} has not been approved`
        );
      }
    }
  }, [residentDetails?.id, meterno]);

  const compileData = useCallback(() => {
    if (residentDetails?.id) {
      const {
        customerId,
        estate: { id: estateId }
      } = residentDetails || {};
      const parameters = {
        customerId,
        meterNo: formValues.meterno,
        type: formValues.paymentType,
        estateId,
        channel: 'cash',
        amount: NairaToKobo(formValues.amount),
        source: 'offline'
      };
      setPaymentParams(parameters);
    }
  }, [residentDetails, unit, formValues]);

  useEffect(() => {
    compileData();
  }, [compileData]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      toggleModal();
    }

    return () => {
      setIsSubmitting(false);
    };
  }, [isSubmitting, errors, toggleModal]);

  return (
    <>
      <form className="form">
        <div className="form__header">
          <h4 className="heading header-title">Buy Units</h4>
          <p className="paragraph header-text">
            Please enter the required information.
          </p>
        </div>
        <div className="form__content row gap-3">
          <div className="col-12">
            <label>Meter Number</label>
            <span className="form-error"> {errors.meterno} </span>
            <input
              type="text"
              name="meterno"
              value={meterno}
              onChange={handleChange}
              onBlur={findMeter}
              className="form-control"
              required
            />
          </div>
          <div className="col-12">
            <label>Name of Estate</label>
            <div className="position-relative">
              <input
                type="text"
                name="estate"
                value={resident.estate}
                className="form-control"
                required
                readOnly
                onChange={handleChange}
              />
              {result?.isFetching && (
                <div
                  className="spinner-border position-absolute"
                  style={{ top: `25%`, right: `10px` }}
                  role="status"
                />
              )}
            </div>
          </div>
          <div className="col-12">
            <label>Name of Resident</label>
            <div className="position-relative">
              <input
                type="text"
                name="resident"
                value={resident.name}
                className="form-control"
                required
                readOnly
                onChange={handleChange}
              />
              {result?.isFetching && (
                <div
                  className="spinner-border position-absolute"
                  style={{ top: `25%`, right: `10px` }}
                  role="status"
                />
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <label>
                  Amount
                  <span className="form-error"> {errors.amount} </span>
                </label>
                <NumericFormat
                  className="form-control"
                  value={amount}
                  required
                  prefix="₦"
                  thousandSeparator=","
                  onBlur={getBreakdownDetails}
                  onValueChange={handleAmountChange}
                  disabled={
                    tariff === undefined ||
                    !residentDetails.estate.isTariffApproved
                  }
                />
              </div>

              <div className="col-md-6">
                <label>Units</label>
                <input
                  type="text"
                  name="unit"
                  value={unit.toFixed(2)}
                  className="form-control"
                  required
                  readOnly
                  onChange={handleChange}
                />
                <small>
                  {!!vat && `${formatCurrency(KoboToNaira(vat))} Vat`}{' '}
                  {!!total && `${formatCurrency(KoboToNaira(total))} in Total`}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="form__footer">
          <div className="form__footer-buttons d-flex justify-content-end">
            <div
              className="btn-group"
              role="group"
              aria-label="Button group example"
            >
              <button
                type="button"
                className="button button-primary button--outline"
                onClick={(event) => closeForm(close, event, resetForm)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="button button-primary button-save ms-2"
                onClick={(event) =>
                  submitForm(
                    userType,
                    event,
                    setErrors,
                    validateUnits,
                    formValues,
                    setIsSubmitting
                  )
                }
              >
                Buy Units
              </button>
            </div>
          </div>
        </div>
      </form>
      <BuyUnitsPrompt
        modal={modal}
        toggle={toggleModal}
        addPayment={addPayment}
        paymentParams={paymentParams}
        name={resident.name}
        isSubmiting={isLoading}
        amount={formValues.amount}
        meterNo={formValues.meterno}
      />
    </>
  );
};

BuyUnitsStaff.propTypes = {
  errors: PropTypes.object,
  getData: PropTypes.func,
  getUserByMeterId: PropTypes.func,
  close: PropTypes.func.isRequired,
  resident: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
