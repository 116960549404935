import axios from 'axios';
import { toast } from 'sonner';
import environment from '../../environment';
import { paystackConstants } from '../constants';

const {
  payment: { seckey },
  api: { uri }
} = environment;

axios.defaults.baseURL = uri;
axios.defaults.headers.common.Authorization = localStorage.token;

const paystack = axios.create({
  baseURL: 'https://api.paystack.co',
  headers: { Authorization: `Bearer ${seckey}` }
});

const getPlansSuccess = (payload) => ({
  type: paystackConstants.GET_PAYMENT_PLANS_SUCCESS,
  payload
});
const getPlansFailure = (error) => ({
  type: paystackConstants.GET_PAYMENTS_PLANS_FAILURE,
  payload: error
});

const createPlanSuccess = (payload) => ({
  type: paystackConstants.CREATE_PLAN_SUCCESS,
  payload
});
const createPlanFailure = (error) => ({
  type: paystackConstants.CREATE_PLAN_FAILURE,
  payload: error
});

const fetchPlanByCustomerSuccess = (payload) => ({
  type: paystackConstants.FETCH_PLAN_BY_CUSTOMER_SUCCESS,
  payload
});

const fetchPlanByCustomerFailure = (error) => ({
  type: paystackConstants.FETCH_PLAN_BY_CUSTOMER_FAILURE,
  payload: error
});

const resetPlansSuccess = (payload) => ({
  type: paystackConstants.RESET_PLANS_SUCCESS,
  payload
});

export const createPlan = (plan) => (dispatch) => {
  paystack
    .post('/plan', plan)
    .then((response) => {
      if (response.status) {
        dispatch(createPlanSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.message) {
        toast.error(error.message);
        dispatch(createPlanFailure(error));
      }
    });
};

export const fetchPlans = () => (dispatch) => {
  paystack
    .get('/plan')
    .then((response) => {
      if (response.status) {
        dispatch(getPlansSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.message) {
        dispatch(getPlansFailure(error.message));
      } else {
        throw error;
      }
    });
};

export const initializePayment = (payment) => {
  paystack
    .post('/transaction/initialize', payment)
    .then((response) => {
      if (response.status) {
        toast.success('Please wait to input card details.');
        window.location = response.data.data.authorization_url;
      }
    })
    .catch((error) => {
      if (error.message) {
        toast.error(`Error:  ${error.message}`);
      } else {
        throw error;
      }
    });
};

export const fetchPlanByCustomer = (planCode) => (dispatch) => {
  paystack
    .get(`https://api.paystack.co/plan/${planCode}`)
    .then((response) => {
      if (response.status) {
        dispatch(fetchPlanByCustomerSuccess(response.data));
      }
    })
    .catch((error) => {
      if (error.message) {
        toast.error(`${error.message} : Unable to fetch subscriptions`);
        dispatch(fetchPlanByCustomerFailure(error.response));
      } else {
        throw error;
      }
    });
};

export const cancelSubscription = (subscriptionId, meterNo, close) => {
  axios
    .post(`/subscriptions/${subscriptionId}/cancel`)
    .then((response) => {
      if (response.status) {
        toast.success(`Success: ${response.data.message}`);
        close?.();
      }
    })
    .catch((error) => {
      if (error.message) {
        toast.error(error.message);
      } else {
        throw error;
      }
    });
};

export const resetPlans = () => (dispatch) => {
  dispatch(resetPlansSuccess({}));
};
