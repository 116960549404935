import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'features/baseQuery';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery,
  tagTypes: ['Profile'],
  endpoints: builder => ({
    getMeters: builder.query({
      query: userId => `/resident/${userId}/meters`,
      providesTags: ['Profile']
    }),
    editAlias: builder.mutation({
      query: meterDetails => {
        const { meterName, meterNo, userId } = meterDetails;
        return {
          url: `/resident/${userId}/meters/?meterNo=${meterNo}`,
          method: 'PATCH',
          body: { meterName }
        };
      },
      invalidatesTags: ['Profile']
    })
  })
});

export const { useGetMetersQuery, useEditAliasMutation } = profileApi;
