import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { addOneDay, dateFormater } from 'utility';
import 'react-datepicker/dist/react-datepicker.css';
import { generateTicketFilter } from 'store/actions';

export const TicketsFilter = ({ category, filterHandler, filterSet }) => {
  const dispatch = useDispatch();
  const filterDefaults = {
    estate: '',
    meterNo: '',
    status: ''
  };

  const { id, userType, meters } = useSelector((state) => state.profile);

  const [option, setOption] = useState(filterDefaults);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const filterOptions = useCallback(() => {
    if (isSubmitting) {
      const filterDetails = {
        ...option,
        startDate: dateFormater(startDate, 'reverse'),
        endDate: dateFormater(addOneDay(endDate), 'reverse')
      };
      dispatch(
        generateTicketFilter({ category, userType, userId: id }, filterDetails)
      );
      filterHandler({
        category,
        userType,
        userId: id,
        filterOptions: filterDetails
      });
    }
  }, [
    dispatch,
    option,
    isSubmitting,
    startDate,
    endDate,
    id,
    category,
    userType,
    filterHandler
  ]);

  useEffect(() => {
    filterOptions();
    return () => {
      setIsSubmitting(false);
    };
  }, [filterOptions]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    filterSet('filtered');
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setOption((prevOption) => ({
      ...prevOption,
      [name]: value
    }));
  };

  const handleCancel = () => {
    filterSet('reset');
    setOption(filterDefaults);
    setStartDate();
    setEndDate();
    setIsSubmitting(true);
  };

  return (
    <form className="form row gap-3">
      {userType === 'staff' && (
        <>
          <div className="form-group">
            <label>Estate</label>
            <input
              type="text"
              name="estate"
              value={option.estate}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Meter Number</label>
            <input
              type="text"
              name="meterNo"
              value={option.meterNo}
              className="form-control"
              onChange={handleChange}
              required
            />
          </div>
        </>
      )}

      {userType === 'customer' && meters && (
        <div className="form-group">
          <label>Meter No</label>
          <select
            name="meterNo"
            value={option.meterNo}
            className="form-control"
            onChange={handleChange}
          >
            <option value="" defaultValue>
              Choose meter
            </option>
            {meters &&
              meters.map(
                (meter) =>
                  meter.isMeterActive && (
                    <option key={meter.meterNo} value={meter.meterNo}>
                      {meter.meterName || meter.meterNo}
                    </option>
                  )
              )}
          </select>
        </div>
      )}
      <div className="form-group">
        <label>Status</label>
        <select
          name="status"
          value={option.status}
          className="form-control"
          onChange={handleChange}
        >
          <option value="">Select Status</option>
          <option value="pending">Pending</option>
          <option value="in progress">In progress</option>
          <option value="resolved">Resolved</option>
          <option value="closed">Closed</option>
        </select>
      </div>
      <div className="col-12">
        <div className="row">
          <label className="col-md-12 mt-2">Date Range</label>
          <div className="form-group col-md-6">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className="form-group col-md-6">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="button button-primary button--outline button-small"
          onClick={handleCancel}
        >
          Reset
        </button>

        <button
          type="button"
          className="button button-primary button-save button-small"
          onClick={handleSubmit}
        >
          Filter
        </button>
      </div>
    </form>
  );
};

TicketsFilter.propTypes = {
  filterHandler: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  filterSet: PropTypes.func.isRequired
};
