import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';

export const ImportFile = ({ ...props }) => {
  const { fileHandler, uploadHandler, data } = props;
  const [file, setFile] = useState(null);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleFile = (event) => {
    const upload = event.target.files[0];
    const partials = upload.name.split('.');
    const fileExt = partials[partials.length - 1];
    if (upload) {
      if (upload.type !== 'text/csv' && fileExt !== 'csv') {
        toggle();
        return;
      }

      setFile(upload);
    }
  };

  useEffect(() => {
    fileHandler(file, uploadHandler, data);
  }, [file]);

  return (
    <>
      <div className="import  mx-1">
        <label
          htmlFor="import"
          className="import button button-primary button-small text-capitalize m-0"
        >
          <i className="iconmoon icon-import"></i> Import
        </label>
        <input type="file" name="import" id="import" onChange={handleFile} />
      </div>

      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader toggle={toggle}>
          File format is wrong, Use CSV only
        </ModalHeader>
      </Modal>
    </>
  );
};

ImportFile.propTypes = {
  fileHandler: PropTypes.func.isRequired,
  uploadHandler: PropTypes.func.isRequired,
  data: PropTypes.number
};
