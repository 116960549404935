import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const PowerGraph = () => {
  const categories = () => {
    const list = new Array(10);
    for (let i = 0; i < 10; i++) {
      list[i] = i + 1;
    }

    return list;
  };

  const options = {
    chart: {
      type: 'areaspline'
    },
    title: {
      text: ''
    },
    xAxis: {
      categories: categories()
    },
    yAxis: {
      title: {
        text: 'Power'
      }
    },
    tooltip: {
      shared: true,
      valueSuffix: ' units'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5
      }
    },
    series: [
      {
        data: [3, 4, 8, 5, 4, 1, 1, 3, 4, 3],
        name: 'Consumption'
      }
    ]
  };

  return (
    <div className="graph">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
