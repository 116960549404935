import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';

export const ToggleButton = ({ id, checked, toggleChecked }) => {
  return (
    <Switch
      id={id}
      onChange={toggleChecked}
      checked={checked}
      uncheckedIcon={false}
      // checkedIcon={false}
      onColor="#ff2ddd"
      onHandleColor="#fff"
      offColor="#a4a6b3"
      offHandleColor="#fff"
      activeBoxShadow="0 0 2px 3px #ff2ddd"
      height={20}
      width={45}
      handleDiameter={15}
    ></Switch>
  );
};
ToggleButton.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  toggleChecked: PropTypes.func
};
